<template>
  <div class="filter">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <h5 class="card-title">Filters</h5>
          </div>
          <div class="col-3">
            <button
              v-if="shouldShowClearButton()"
              id="clear-filter-btn"
              class="btn btn-primary"
              @click="clearFilter"
            >
              Clear Filter
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="btn-group">
              <button
                id="status-dropdown"
                :class="filterDropdownClass(statusFiltersAppliedCount())"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Status
                <span :class="filterBadgeClass(statusFiltersAppliedCount())">{{
                  statusFiltersAppliedCount()
                }}</span>
              </button>
              <div class="dropdown-menu">
                <Badge
                  v-for="key in $store.getters.statusFilters"
                  :id="`status-${key}`"
                  :key="`filter-badge-${key}`"
                  :text="snakeCaseToTitle(key)"
                  :query-key="'status'"
                  :negative-query-key="'negative_status'"
                  :query-value="key"
                  :is-active="isFilterActive('status', key)"
                  :is-negative="isFilterActive('negative_status', key)"
                  :add-to-filter="addToFilter"
                  :remove-from-filter="removeFromFilter"
                  :query-action="'queryOrders'"
                ></Badge>
              </div>
            </div>
            <div class="btn-group">
              <button
                id="flags-dropdown"
                :class="filterDropdownClass(flagsFiltersAppliedCount())"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Flags
                <span :class="filterBadgeClass(flagsFiltersAppliedCount())">{{
                  flagsFiltersAppliedCount()
                }}</span>
              </button>
              <div class="dropdown-menu">
                <Badge
                  v-for="value in $store.getters.flagFilters"
                  :id="`flag-${value}`"
                  :key="`filter-badge-${value}`"
                  :text="snakeCaseToTitle(value)"
                  :query-key="'flag_attributes_true'"
                  :negative-query-key="'flag_attributes_false'"
                  :query-value="value"
                  :is-active="isFilterActive('flag_attributes_true', value)"
                  :is-negative="isFilterActive('flag_attributes_false', value)"
                  :add-to-filter="addToFilter"
                  :remove-from-filter="removeFromFilter"
                  :query-action="'queryOrders'"
                ></Badge>
              </div>
            </div>
            <div class="btn-group">
              <button
                id="tags-dropdown"
                :class="filterDropdownClass(tagsFiltersAppliedCount())"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Tags
                <span :class="filterBadgeClass(tagsFiltersAppliedCount())">{{
                  tagsFiltersAppliedCount()
                }}</span>
              </button>
              <div class="dropdown-menu">
                <Badge
                  v-for="key in $store.getters.tagFilters"
                  :id="`tag-${key}`"
                  :key="`filter-badge-${key}`"
                  :text="key"
                  :query-key="'tags'"
                  :negative-query-key="'negative_tags'"
                  :query-value="key"
                  :is-active="isFilterActive('tags', key)"
                  :is-negative="isFilterActive('negative_tags', key)"
                  :add-to-filter="addToFilter"
                  :remove-from-filter="removeFromFilter"
                  :query-action="'queryOrders'"
                ></Badge>
              </div>
            </div>
            <div class="btn-group">
              <button
                id="country-dropdown"
                :class="filterDropdownClass(countriesFiltersAppliedCount())"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Country
                <span
                  :class="filterBadgeClass(countriesFiltersAppliedCount())"
                  >{{ countriesFiltersAppliedCount() }}</span
                >
              </button>
              <div class="dropdown-menu">
                <Badge
                  v-for="(value, key) in $store.getters.countryFilters"
                  :id="`country-${key}`"
                  :key="`filter-badge-${key}`"
                  :text="value"
                  :query-key="'countries'"
                  :negative-query-key="'negative_countries'"
                  :query-value="key"
                  :is-active="isFilterActive('countries', key)"
                  :is-negative="isFilterActive('negative_countries', key)"
                  :add-to-filter="addToFilter"
                  :remove-from-filter="removeFromFilter"
                  :query-action="'queryOrders'"
                ></Badge>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="filter-title">Special Cases</div>
            <div class="btn-group">
              <RadioButtonBadge
                v-for="value in $store.getters.relationFilters"
                :id="`order-relation-${value}`"
                :key="`filter-badge-${value}`"
                :text="snakeCaseToTitle(value)"
                :query-key="'order-relation'"
                :query-value="value"
                :is-active="isFilterActive('order_relation', value)"
                :clear-order-relation-filter="clearOrderRelationFilter"
                :set-order-relation-filter="setOrderRelationFilter"
                :query-action="'queryOrders'"
              ></RadioButtonBadge>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-6">
            <label for="created-after-date-input">Created after</label>
            <input
              id="created-after-date-input"
              v-model="createdAfter"
              class="form-control"
              type="date"
            />
          </div>
          <div class="col-md-3 col-6">
            <label for="created-before-date-input">Created before</label>
            <input
              id="created-before-date-input"
              v-model="createdBefore"
              class="form-control"
              type="date"
            />
          </div>
          <div class="col-md-3 col-6">
            <label for="min-total-input">Minimum total</label>
            <input
              id="min-total-input"
              v-model.number="minTotal"
              class="form-control"
              type="number"
              min="0"
            />
          </div>
          <div class="col-md-3 col-6">
            <label for="max-total-input">Maximum total</label>
            <input
              id="max-total-input"
              v-model.number="maxTotal"
              class="form-control"
              type="number"
              min="0"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label for="property-id">Property ID</label>
            <input
              id="property-id"
              v-model="propertyId"
              class="form-control"
              placeholder="ID"
              type="text"
              @keyup.enter="search"
            />
          </div>
          <div class="col-6">
            <label for="user-email-search">User ID</label>
            <input
              id="user-search"
              v-model="userSearch"
              class="form-control"
              placeholder="ID"
              type="text"
              @keyup.enter="search"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label>Search</label>
              <input
                id="search-box"
                v-model="searchTerm"
                class="form-control"
                type="text"
                @keyup.enter="search"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button id="get-orders-btn" class="btn btn-primary" @click="search">
              Search
            </button>
            <Loader
              v-if="$store.getters.isLoading"
              :width="'35px'"
              :height="'35px'"
            ></Loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Badge from "./badge.vue";
import RadioButtonBadge from "./radio_button_badge.vue";
import Loader from "./loader.vue";
import { isEqual, capitalize } from "lodash";

export default {
  components: {
    Badge,
    Loader,
    RadioButtonBadge,
  },
  props: [
    "removeFromFilter",
    "addToFilter",
    "defaultSearch",
    "defaultProperty",
    "clearOrderRelationFilter",
    "setOrderRelationFilter",
  ],
  data() {
    return {
      searchTerm: this.defaultSearch,
      debouncing: false,
      searching: false,
      last_debounced: 0,
      userSearch: "",
      orderSearch: "",
      propertyId: this.defaultProperty ?? "",
    };
  },
  computed: {
    createdAfter: {
      get() {
        return this.$store.getters.filter.created_after;
      },
      set(value) {
        this.$store.commit("setCreatedAfterFilter", value);
      },
    },
    createdBefore: {
      get() {
        return this.$store.getters.filter.created_before;
      },
      set(value) {
        this.$store.commit("setCreatedBeforeFilter", value);
      },
    },
    minTotal: {
      get() {
        return this.$store.getters.filter.minimum_total;
      },
      set(value) {
        this.$store.commit("setMinimumTotal", value);
      },
    },
    maxTotal: {
      get() {
        return this.$store.getters.filter.maximum_total;
      },
      set(value) {
        this.$store.commit("setMaximumTotal", value);
      },
    },
  },
  methods: {
    isFilterActive(key, value) {
      const filter = this.$store.getters.filter;
      return filter[key] && filter[key].includes(value);
    },
    appliedFilterCount(key) {
      const filter = this.$store.getters.filter;
      return (filter[key] && filter[key].length) || 0;
    },
    statusFiltersAppliedCount() {
      return (
        this.appliedFilterCount("status") +
        this.appliedFilterCount("negative_status")
      );
    },
    flagsFiltersAppliedCount() {
      return (
        this.appliedFilterCount("flag_attributes_true") +
        this.appliedFilterCount("flag_attributes_false")
      );
    },
    tagsFiltersAppliedCount() {
      return (
        this.appliedFilterCount("tags") +
        this.appliedFilterCount("negative_tags")
      );
    },
    countriesFiltersAppliedCount() {
      return (
        this.appliedFilterCount("countries") +
        this.appliedFilterCount("negative_countries")
      );
    },
    filterDropdownClass(filterCount) {
      const appliedFilters = filterCount > 0;

      return [
        "filter-btn",
        "btn",
        appliedFilters ? "btn-info" : "btn-outline-info",
        "dropdown-toggle",
      ];
    },
    filterBadgeClass(filterCount) {
      const appliedFilters = filterCount > 0;
      return ["badge", appliedFilters ? "badge-light" : "badge-info"];
    },
    search(_event) {
      this.last_debounced = Date.now();
      if (!this.debouncing) {
        this.debouncing = true;
        setTimeout(this.debounce_search, 300);
      }
    },
    debounce_search() {
      const updated_diff = Date.now() - this.last_debounced;
      this.debouncing = false;

      if (updated_diff >= 300 && !this.$store.getters.isSearching) {
        const filter = this.$store.getters.filter;
        filter["search"] = this.searchTerm.trim();
        filter["order_search"] = this.orderSearch.trim();
        filter["user_search"] = this.userSearch.trim();
        filter["property_id"] = this.propertyId.trim();
        this.$store.dispatch("updateFilter", filter);
      } else {
        this.search();
      }
    },
    shouldShowClearButton() {
      return !isEqual(
        this.$store.getters.filter,
        this.$store.getters.defaultFilter,
      );
    },
    clearFilter() {
      this.searchTerm = "";
      this.orderSearch = "";
      this.propertyId = "";
      this.userSearch = "";
      this.$store.dispatch("updateFilter", this.$store.getters.defaultFilter);
    },
    snakeCaseToTitle(attribute) {
      return attribute
        .split("_")
        .map(function (word) {
          return capitalize(word);
        })
        .join(" ");
    },
  },
};
</script>
