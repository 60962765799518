import { pieApiFetch, checkResponseOk } from "shared/fetch_decorators";
import { cloneDeep } from "lodash";

export const initFilters = async ({ commit }, filters) => {
  try {
    commit("updateFilters", filters);
  } catch (error) {
    console.log(error);
  } finally {
    commit("finishLoading");
  }
};

export const queryOrders = async ({ commit, state }) => {
  const params = cloneDeep(state.filter);
  params["page"] = state.page;
  params["order_by_attribute"] = state.order_by.attribute;
  params["order_by_direction"] = state.order_by.direction;
  params["tz"] = state.time_zone;

  try {
    commit("startSearching");
    commit("startLoading");
    const response = await pieApiFetch("/admin/orders/query", { body: params });
    await checkResponseOk(response);
    const data = await response.json();
    commit("updateOrders", data.orders);
    commit("updatePages", data.pages);
    commit("updateTotal", data.total);
    commit("updateFrom", data.from);
    commit("updateTo", data.to);
  } catch (error) {
    console.log(error);
  } finally {
    commit("finishSearching");
    commit("finishLoading");
  }
};

export const changePage = ({ dispatch, commit, state }, newPage) => {
  commit("updatePage", newPage);
  dispatch("queryOrders", state.filter);
};

export const updateFilter = ({ commit, dispatch, state }, filter) => {
  commit("updateFilter", cloneDeep(filter));
  if (state.page !== 1) {
    commit("updatePage", 1);
  }
  dispatch("queryOrders");
};

export const updateWithoutQuery = ({ commit }, filter) => {
  commit("updateFilter", cloneDeep(filter));
};

export const updateOrderBy = ({ commit, dispatch }, orderBy) => {
  commit("updateOrderBy", orderBy);
  dispatch("queryOrders");
};

export const loadFilter = ({ commit }) => {
  commit("loadFilter");
};
