import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

import { EmailField } from "components/email_field";
import { initOrderFormFields } from "shared/order_form_total_fields";
import { FakeTelephoneNumberField } from "components/fake_telephone_number_field";
import { TelephoneNumberField } from "components/telephone_number_field";
import { initPosIntegrationForm } from "merchant/new_order_integration_form";

export default class extends Controller {
  connect() {
    let taxFieldUpdatesEnabled = true;

    const submittedCreateOrderWithoutContact = function (event) {
      return (
        event.originalEvent.submitter.id ==
        "create-order-without-contact-button"
      );
    };

    const isContactInfoRequired = function () {
      return !!document.getElementById("order_send_to_email");
    };

    // On submission of the new order form validate the contact information and
    // add any error messages if something is missing.
    $("#new-order-form").on("submit", function (event) {
      if (!isContactInfoRequired()) {
        return true;
      }

      const sendToEmailField = new EmailField("order_send_to_email");
      // The SMS field does not exist on some POS integration pages, in which
      // case it behaves as though it is empty and valid.

      if (submittedCreateOrderWithoutContact(event)) {
        sendToEmailField.erase();
        smsField.erase();
        return true;
      }

      // If both fields are blank then we mark email as invalid because that is
      // what we want to require by default.
      if (sendToEmailField.isEmpty() && smsField.isEmpty()) {
        sendToEmailField.markAsInvalid();
        return false;
      }

      if (!sendToEmailField.isValid()) {
        sendToEmailField.markAsInvalid();
      }

      if (!smsField.isValid()) {
        smsField.markAsInvalid();
      }

      if (!sendToEmailField.isValid() || !smsField.isValid()) {
        return false;
      }

      smsField.updateForFormSubmission();
      return true;
    });

    const smsField = document.getElementById("order_send_to_sms")
      ? new TelephoneNumberField("order_send_to_sms")
      : new FakeTelephoneNumberField();

    const disableTaxFieldUpdates = function () {
      taxFieldUpdatesEnabled = false;
    };

    const fetchTaxFieldUpdates = function () {
      return taxFieldUpdatesEnabled;
    };

    initOrderFormFields(fetchTaxFieldUpdates);

    initPosIntegrationForm(disableTaxFieldUpdates).then(
      document.body.classList.add("event-listeners-attached"),
    );
  }
}
