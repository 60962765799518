import { Controller } from "@hotwired/stimulus";
import { Chart, DoughnutController, ArcElement } from "chart.js";

// These are needed for webpack tree shaking for doughnut charts
// @see https://www.chartjs.org/docs/4.4.0/getting-started/integration.html
Chart.register(DoughnutController, ArcElement);

export default class extends Controller {
  connect() {
    const el = document.getElementById("competitionsTracker");

    const data = {
      datasets: [
        {
          label: "Progress",
          data: [
            el.dataset.competitionCreated,
            el.dataset.competitionRemaining,
          ],
          backgroundColor: ["rgb(0, 115, 249)", "rgb(230, 233, 236)"],
          hoverOffset: 4,
        },
      ],
    };
    const centerText = {
      id: "centerText",
      afterDatasetsDraw(chart, _args, _options) {
        const {
          ctx,
          chartArea: { top, width, height },
        } = chart;

        ctx.textAlign = "center";

        ctx.fillStyle = "black";
        ctx.font = "normal 3.5rem OpenSans"; // .display-4
        ctx.fillText(
          el.dataset.competitionCreated,
          width / 2,
          height / 2 + top - 25,
        );

        ctx.font = "normal 1.25rem OpenSans"; // h5
        ctx.fillStyle = "#6c757d"; // .text-muted
        ctx.fillText(
          "Goal: " + el.dataset.competitionTarget,
          width / 2,
          height / 2 + top + 25,
        );
      },
    };
    const config = {
      type: "doughnut",
      data: data,
      options: {
        cutout: "90%",
        borderWidth: 0,
      },
      plugins: [centerText],
    };

    new Chart(document.getElementById("competitionsTracker"), config);
  }
}
