import $ from "jquery";

const call = function (path, value, method) {
  return $.ajax(path, {
    data: value,
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    dataType: "json",
    method: method,
  });
};

/**
 * @typedef PayoutEstimates
 * @type {object}
 * @property {object} payout_estimates Map of currency code to estimate
 *   formatted in the tourist's locale. E.g. "$5.34"
 * @property {number} raw_estimate The amount of money to be paid to the tourist
 *   in the order currency as a float.
 */

/**
 * @param {number} orderTotal
 * @param {number} taxTotal
 * @return {Promise<PayoutEstimates>}
 */
function getPayoutEstimates(orderTotal, taxTotal) {
  return call(
    "/orders/payout_estimates",
    {
      order: {
        tax_total: taxTotal,
        total: orderTotal,
      },
    },
    "GET",
  );
}
export { getPayoutEstimates };
