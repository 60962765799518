import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "type",
    "title",
    "longDescription",
    "shortDescription",
    "code",
    "discountAmount",
    "minimumSpend",
  ];

  initialize() {
    this.offerType = {
      GIFT: "Offers::Gift",
      DISCOUNT: "Offers::Discount",
      EVENT: "Offers::Event",
    };
  }

  connect() {
    this.changeInputsByOfferType();
  }

  changeInputsByOfferType() {
    switch (this.typeTarget.value) {
      case this.offerType.GIFT:
        this.showOnlyInput(this.giftInputs());

        break;
      case this.offerType.DISCOUNT:
        this.showOnlyInput(this.discountInputs());

        break;
      case this.offerType.EVENT:
        this.dispatch("renderVjwScanner");
        this.showOnlyInput(this.eventInputs());
        break;

      default:
        this.showOnlyInput([]);
    }
  }

  discountInputs() {
    return [
      this.longDescriptionTarget,
      this.shortDescriptionTarget,
      this.codeTarget,
      this.discountAmountTarget,
      this.minimumSpendTarget,
    ];
  }

  giftInputs() {
    return [
      this.titleTarget,
      this.longDescriptionTarget,
      this.shortDescriptionTarget,
      this.codeTarget,
    ];
  }

  eventInputs() {
    return [
      this.titleTarget,
      this.longDescriptionTarget,
      this.shortDescriptionTarget,
    ];
  }

  allInputs() {
    return [
      this.titleTarget,
      this.longDescriptionTarget,
      this.shortDescriptionTarget,
      this.codeTarget,
      this.discountAmountTarget,
      this.minimumSpendTarget,
    ];
  }
  showOnlyInput(inputs) {
    this.allInputs().forEach((input) => {
      inputs.includes(input) ? this.showInput(input) : this.hideInput(input);
    });
  }

  hideInput(target) {
    target.style.display = "none";
  }

  showInput(target) {
    target.style.display = "block";
  }
}
