<template>
  <button type="button" :class="buttonClass()" @click="clickHandler">
    {{ text }} <span :class="badgeClass()">{{ number }}</span>
  </button>
</template>
<script>
/*
 * RadioButtonBadge is used in the OrderFilter to set
 * a base order group for searching. It's used like
 * a radio button where only one selection is active
 * in a given set of filters. This helps simplify
 * the query that's generated from the rest of the order
 * filtering.
 */
export default {
  props: [
    "text",
    "number",
    "queryKey",
    "queryValue",
    "isActive",
    "clearOrderRelationFilter",
    "setOrderRelationFilter",
    "queryAction",
  ],
  methods: {
    clickHandler() {
      if (this.isActive) {
        this.clearOrderRelationFilter();
        this.$store.dispatch(this.queryAction);
        return;
      }
      this.clearOrderRelationFilter();
      this.setOrderRelationFilter(this.queryValue);
      this.$store.dispatch(this.queryAction);
      return;
    },
    buttonClass() {
      return [
        "filter-btn",
        "btn",
        this.isActive ? "btn-info" : "",
        !this.isActive ? "btn-outline-info" : "",
      ];
    },
    badgeClass() {
      return ["badge", this.isActive ? "badge-light" : "badge-info"];
    },
  },
};
</script>

<style scoped>
.filter-btn {
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
