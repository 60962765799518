import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "documentTypeSelector",
    "passportScanner",
    "vjwScanner",
    "documentImage",
    "dateOfExpiration",
  ];

  /*
   * Check references in JpNta::Trip and proofs/document_type.rb
   */

  connect() {
    this.documentTypes = {
      PASSPORT: "1",
      LANDING_PERMIT_WITH_PASSPORT: "2",
      VISIT_JAPAN_WEB_QR_CODE: "7",
    };
    this.changeInputsByDocumentType();
  }

  passportInputs() {
    return [
      this.passportScannerTarget,
      this.documentImageTarget,
      this.dateOfExpirationTarget,
    ];
  }

  vjwQrCodeInputs() {
    return [this.vjwScannerTarget];
  }

  onlyImageInput() {
    return [this.documentImageTarget];
  }

  allInputs() {
    return [
      this.passportScannerTarget,
      this.vjwScannerTarget,
      this.documentImageTarget,
      this.dateOfExpirationTarget,
    ];
  }

  changeInputsByDocumentType() {
    switch (this.documentTypeSelectorTarget.value) {
      case this.documentTypes.PASSPORT:
        this.showOnlyInput(this.passportInputs());

        break;
      case this.documentTypes.LANDING_PERMIT_WITH_PASSPORT:
        this.showOnlyInput(this.passportInputs());

        break;
      case this.documentTypes.VISIT_JAPAN_WEB_QR_CODE:
        this.dispatch("renderVjwScanner");
        this.showOnlyInput(this.vjwQrCodeInputs());
        break;

      default:
        this.showOnlyInput(this.onlyImageInput());
    }
  }

  showOnlyInput(inputs) {
    this.allInputs().forEach((input) => {
      inputs.includes(input) ? this.showInput(input) : this.hideInput(input);
    });
  }

  hideInput(target) {
    target.style.display = "none";
  }

  showInput(target) {
    target.style.display = "block";
  }
}
