<template>
  <div class="filter">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <h5 class="card-title">Filter</h5>
          </div>
          <div class="col-3">
            <button
              v-if="shouldShowClearButton()"
              class="btn btn-primary"
              @click="clearFilter"
            >
              Clear Filter
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="filter-title">Resource</div>
            <Badge
              v-for="(value, key) in resourceCounts"
              :key="'filter-badge-' + key"
              :text="value.name"
              :number="value.count"
              :query-key="'resource'"
              :negative-query-key="'negative_resource'"
              :query-value="key"
              :is-active="isResourceActive(key)"
              :is-negative="false"
              :add-to-filter="changeResource"
              :remove-from-filter="changeResource"
              :query-action="'query'"
            ></Badge>
          </div>
        </div>
        <div class="row">
          <div
            v-for="filterType in filterTypes"
            :key="filterType.queryKey"
            class="col-12"
          >
            <div v-if="filterType.counts !== undefined" class="filter-title">
              {{ filterType.title }}
            </div>
            <Badge
              v-for="(value, key) in filterType.counts"
              :key="'filter-badge-' + key"
              :text="value.name"
              :number="value.count"
              :query-key="filterType.id"
              :negative-query-key="`negative_${filterType.id}`"
              :query-value="key"
              :is-active="isFilterActive(filterType.id, key)"
              :is-negative="isFilterActive(`negative_${filterType.id}`, key)"
              :add-to-filter="addToFilter"
              :remove-from-filter="removeFromFilter"
              :query-action="'query'"
            ></Badge>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <label>Search</label>
              <input
                v-model="searchTerm"
                class="form-control"
                type="text"
                @input="search"
                @keyup.enter="search"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary" @click="search">
              Find {{ currentResourceTypeTitle }}
            </button>
            <Loader
              v-if="isLoading(current_resource)"
              :width="'35px'"
              :height="'35px'"
            ></Loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Badge from "../../components/badge.vue";
import Loader from "../../components/loader.vue";
import { map, debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    Badge,
    Loader,
  },
  props: ["removeFromFilter", "addToFilter", "defaultSearch", "changeResource"],
  data() {
    return {
      searchTerm: this.defaultSearch,
    };
  },
  computed: {
    filterTypes() {
      return [
        {
          title: "In Country",
          id: "countries",
          counts: this.$store.getters.countryCounts,
        },
        {
          title: "With Issues",
          id: "issues",
          counts: this.$store.getters.issueCounts,
        },
      ];
    },
    ...mapGetters([
      "current_resource",
      "currentResourceTypeTitle",
      "isLoading",
      "resourceCounts",
    ]),
  },
  watch: {
    defaultSearch: function (val, oldVal) {
      if (oldVal != val) {
        this.searchTerm = val;
      }
    },
  },
  methods: {
    isFilterActive(key, value) {
      const filter = this.$store.getters.filter(
        this.$store.getters.current_resource,
      );
      return filter[key] && filter[key].includes(value);
    },
    isResourceActive(key, _value) {
      return this.$store.getters.current_resource == key;
    },
    search: debounce(
      function () {
        const filter = this.$store.getters.filter(
          this.$store.getters.current_resource,
        );
        filter["search"] = this.searchTerm?.trim() || "";
        this.$store.dispatch("updateFilterAndFetch", {
          filter: filter,
          resource: this.$store.getters.current_resource,
        });
      },
      200,
      { trailing: true },
    ),
    shouldShowClearButton() {
      const filter = this.$store.getters.filter(
        this.$store.getters.current_resource,
      );
      const keys = Object.keys(filter);
      const showButton = map(keys, (key) => {
        return filter[key].length > 0;
      });
      return showButton.indexOf(true) > -1;
    },
    clearFilter() {
      this.searchTerm = "";
      this.$store.dispatch("updateFilterAndFetch", {
        filter: this.$store.getters.defaultFilter,
        resource: this.$store.getters.current_resource,
      });
    },
  },
};
</script>
