import DatatableController from "controllers/datatable_controller";

export default class extends DatatableController {
  config() {
    return {
      serverSide: true,
      searchDelay: 700,
      ajax: {
        url: "/admin/users/query",
        type: "GET",
        dataSrc: "users",
        beforeSend: function (request) {
          request.setRequestHeader("Accept", "application/json");
        },
      },
      language: {
        info: "Showing items _START_ to _END_",
      },
      pagingType: "simple",
      columns: [
        {
          data: "email",
          orderable: false,
        },
        {
          data: "nationality",
          orderable: false,
        },
        {
          data: "address_country",
          orderable: false,
        },
        {
          data: "order_count",
          orderable: false,
        },
        {
          data: "updated_at",
          orderable: false,
          render: (data, type, _row, _meta) =>
            type === "display" ? moment(data).fromNow() : data,
        },
        {
          data: "created_at",
          orderable: false,
          render: function (data, type, _row, _meta) {
            if (type === "display") {
              return moment(data).fromNow();
            }
            return data;
          },
        },
        {
          data: "",
          orderable: false,
          render: (_data, _type, row, _meta) =>
            `<a href="/admin/users/${row.id}">Show</a>`,
        },
      ],
    };
  }
}
