import $ from "jquery";
import { debounce } from "lodash";
import { getPayoutEstimates } from "shared/orders_service";

class ExpectedRefund {
  constructor() {
    this.refundCallNumber = 0;
    this.expectedRefund = $("#expected-refund");
    this.percentageSaved = $("#expected-savings-percentage");
    this.expectedRefundContainer = $("#expected-refund-container");
  }

  update(orderTotal, taxTotal) {
    this.orderTotal = orderTotal;
    this.taxTotal = taxTotal;
    this.debouncedUpdate();
  }

  debouncedUpdate = debounce(
    async () => {
      const currentRefundCall = ++this.refundCallNumber;
      try {
        const result = await getPayoutEstimates(this.orderTotal, this.taxTotal);
        if (this.refundCallNumber !== currentRefundCall) {
          return;
        }
        const percentage = parseFloat(
          (result.raw_estimate / this.orderTotal) * 100,
        ).toFixed(2);
        this.percentageSaved.html(percentage + "% savings");
        this.expectedRefund.html(
          $.map(result.payout_estimates, this.buildExpectedRefundRow).join(""),
        );
        this.expectedRefundContainer.addClass("expected-refund__show");
      } catch (e) {
        console.error(e);
        this.expectedRefundContainer.removeClass("expected-refund__show");
      }
    },
    500,
    { trailing: true },
  );

  buildExpectedRefundRow(value, currency) {
    return `
      <tr class="text-success">
        <td>${currency}</td>
        <td class="expected-refund__amount">${value}</td>
      </tr>`;
  }
}
export { ExpectedRefund };
