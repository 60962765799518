import { Controller } from "@hotwired/stimulus";

import "jszip"; // for export
import "pdfmake"; // for export
import DataTable from "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-datetime"; // required for searchbuilder
import "datatables.net-searchbuilder-bs4";

/**
 * A simple Stimulus wrapper for a basic DataTable.
 * NOTE: The extra "table" target is required because DataTables does things to
 * the element which would cause multiple connects.
 *
 * If you need to configure the table, subclass this and override config.
 *
 * Usage:
 *
 * NOTE: The controller must be on a stable parent so that DataTables is free
 * to mangle the table element as it pleases.
 *
 * <div data-controller="datatable">
 *   <table data-datatable-target="table">
 *   </table>
 * </div>
 */
export default class extends Controller {
  static targets = ["table"];

  connect() {
    new DataTable(this.tableTarget, this.config());
  }

  config() {
    return {};
  }
}
