import { Controller } from "@hotwired/stimulus";
import * as Choices from "choices.js";

export default class extends Controller {
  static targets = ["selector", "lineItemsCategoriesLink"];
  static values = { url: String };

  connect() {
    // Change the line item categories link when an option is selected.
    this.selectorTarget.addEventListener(
      "choice",
      function (event) {
        const url =
          event.detail.choice.customProperties.line_item_categories_link;
        if (url !== null && url !== undefined) {
          this.lineItemsCategoriesLinkTarget.href = url;
          this.lineItemsCategoriesLinkTarget.classList.remove("d-none");
        } else {
          this.lineItemsCategoriesLinkTarget.classList.add("d-none");
        }
        console.log(url);
      }.bind(this),
      false,
    );
    // Make sure the tests can wait until we have some choices
    this.selectorTarget.addEventListener(
      "addItem",
      () => {
        this.element.classList.add("test-ready");
      },
      false,
    );

    new Choices(this.selectorTarget, {
      allowHTML: false,
      searchEnabled: true,
      searchFields: ["label"],
      shouldSort: false,
      fuseOptions: {
        // Making the search fuzzy enough.
        // https://github.com/Choices-js/Choices/issues/135#issuecomment-302124496
        threshold: 0.1,
        distance: 1000,
      },
    }).setChoices(this.fetchChoices.bind(this));
  }

  async fetchChoices() {
    const response = await fetch(this.urlValue);
    const json = await response.json();
    return json;
  }
}
