export default Object.freeze({
  CATEGORIES: "categories",
  COUNTRIES: "countries",
  CURRENCIES: "currencies",
  EXTERNAL_ACCOUNTS: "external_accounts",
  MERCHANTS: "merchants",
  PIE_COUNTERS: "pie_counters",
  COUNTER_ASSOCIATES: "counter_associates",
  PROPERTIES: "properties",
  SEARCHED_RESOURCE: "searched_resource",
});
