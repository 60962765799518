<template>
  <div class="row bg-light mx-1 rounded order-return-summary">
    <div class="col-12">
      <div class="row mt-3">
        <div class="col">
          {{ createdAt.toLocaleString() }}
        </div>
        <div class="col text-center return-number-of-items-returned">
          {{
            $t("purchases.returns.num_items_returned", {
              quantity: orderReturn.number_of_items_returned,
            })
          }}
        </div>
        <div class="col text-right font-weight-bold return-refund-header">
          {{ $t("purchases.returns.refund") }}: {{ orderReturn.refund }}
        </div>
      </div>
    </div>
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("line_item.table.name") }}</th>
            <th>{{ $t("line_item.table.category") }}</th>
            <th>{{ $t("line_item.table.quantity") }}</th>
            <th>{{ $t("line_item.table.line_total") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="returned_line_item in orderReturn.returned_line_items"
            :key="returned_line_item.line_item_id"
            class="returned-line-item"
          >
            <td class="text-break line-item-name">
              {{ returned_line_item.name }}
            </td>
            <td class="text-break line-item-category">
              {{ returned_line_item.category }}
            </td>
            <td class="line-item-return-quantity">
              {{ returned_line_item.quantity }}
            </td>
            <td class="line-item-total">{{ returned_line_item.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12">
      <table class="table table-sm table-borderless">
        <tr>
          <td>{{ $t("purchases.returns.returned_items_subtotal") }}</td>
          <td></td>
          <td class="return-items-subtotal">
            {{ orderReturn.returned_items_subtotal }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("purchases.returns.new_vat_refund") }}</td>
          <td></td>
          <td class="return-new-vat-refund">
            {{ orderReturn.payout_estimate }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("purchases.returns.excess_vat_refund") }}</td>
          <td class="text-right">
            {{ orderReturn.previous_payout_estimate }} -
            {{ orderReturn.payout_estimate }} =
          </td>
          <td class="return-excess-vat-refunded">
            {{ orderReturn.excess_vat_refunded }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("purchases.returns.refund") }}</td>
          <td class="text-right return-refund-calculation">
            {{ orderReturn.returned_items_subtotal }} -
            {{ orderReturn.excess_vat_refunded }} =
          </td>
          <td class="return-refund-detail">
            {{ orderReturn.refund }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orderReturn: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdAt() {
      if (this.orderReturn.created_at > 0) {
        // created_at is in seconds and Date expects
        return new Date(this.orderReturn.created_at * 1000);
      } else {
        return new Date();
      }
    },
  },
};
</script>
