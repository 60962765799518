import { Controller } from "@hotwired/stimulus";
import { initOrderFormFields } from "shared/order_form_total_fields";

export default class extends Controller {
  // TODO(alan): Rewrite this to be idiomatic Stimulus
  connect() {
    const fetchTaxFieldUpdates = function () {
      return true;
    };
    initOrderFormFields(fetchTaxFieldUpdates);
  }
}
