import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    param: String,
  };

  navigate(event) {
    const url = new URL(window.location.href);

    url.searchParams.set(this.paramValue, event.target.value);

    window.location.href = url;
  }
}
