import Vuex from "vuex";
import Resources from "admin/resources.js";
import actions from "admin/merchants/store/actions.js";
import mutations from "admin/merchants/store/mutations.js";
import getters from "admin/merchants/store/getters.js";

/**
 *
 * @param {{
 *   orderByAttribute: string
 * }} spec
 * @return {Object}
 */
function createResource(spec) {
  return {
    data: [],
    page: 1,
    pages: 0,
    count: 0,
    order_by: {
      attribute: spec.orderByAttribute,
      direction: "desc",
    },
    loading: false,
    filter: {
      countries: [],
      negative_countries: [],
      search: "",
    },
    singular_title: spec.singularTitle,
  };
}

const state = function (pageData) {
  return {
    flash_message: {
      show: false,
      status: "", // info, danger, or warn corresponding to Bootstrap alert-*
      message: "",
    },
    // TODO(alan): There is a language mismatch between the frontend and
    // backend here. This should be called resourceType.
    /** @type {string} */
    resource: Resources.PROPERTIES,
    // Note that these keys must match the values in Resources
    merchants: {
      ...createResource({ orderByAttribute: "merchants.created_at" }),
      singularTitle: "Merchant",
    },
    counter_associates: createResource({
      orderByAttribute: "users.email",
      singularTitle: "Counter Associate",
    }),
    properties: createResource({
      orderByAttribute: "properties.created_at",
      singularTitle: "Property",
    }),
    pie_counters: createResource({
      orderByAttribute: "pie_counters.created_at",
      singularTitle: "Pie Counter",
    }),
    searched_resource: createResource({ orderByAttribute: "created_at" }),
    currencies: createResource({
      orderByAttribute: "currencies.name",
      singularTitle: "Currency",
    }),
    defaultFilter: {
      search: "",
      resource: Resources.PROPERTIES,
    },
    country_counts: {},
    resource_counts: {},
    issue_counts: pageData.issueCounts,
    loading: false,
    posSystems: pageData.posSystems,
    available_categories: [],
  };
};

/**
 * @param {Class} vue
 * @param {VueRouter} router
 * @param {{
 *   issueCounts: Array,
 *   posSystems: Array
 * }} pageData
 * @return {Vuex.Store}
 */
export default function (vue, router, pageData) {
  return new Vuex.Store({
    state: state(pageData),
    mutations: mutations(vue),
    actions: actions(router, Resources),
    getters: getters,
  });
}
