<template>
  <div id="merchants">
    <div class="row justify-content-between">
      <div class="col-6">
        <h3>Store Manager</h3>
      </div>
      <div class="col-6">
        <div
          class="btn-group float-right"
          role="group"
          aria-label="Basic example"
        >
          <router-link
            id="new-property-button"
            class="btn btn-primary"
            :to="{ path: '/properties/new' }"
            >New Property</router-link
          >
          <router-link class="btn btn-primary" :to="{ path: '/merchants/new' }"
            >New Merchant</router-link
          >
          <router-link
            class="btn btn-primary"
            :to="{ path: '/pie_counters/new' }"
          >
            New Counter
          </router-link>
        </div>
      </div>
    </div>
    <MerchantFilter
      :remove-from-filter="removeFromFilter"
      :add-to-filter="addToFilter"
      :change-resource="setResourceString"
      :default-search="filter(resource).search"
    />

    <ResourceTable
      v-if="anyResources(resource)"
      :attributes="resourceAttributes(resource)"
      :resource-type="resource"
      :change-page="changePage"
      :update-order-by="updateOrderBy"
    ></ResourceTable>
    <div v-if="!anyResources(resource)" class="row">
      <div class="col-12">
        <h5>No {{ currentResourceTypeTitle }} were found.</h5>
        <p>The Filter searches in this fashion:</p>
        <p>Selected filters AND search term</p>
        <p>If you select multiple filters, they are ORed together.</p>
        <p>
          Search tearm searches the term on 'Resource Id', 'Merchant Login',
          'Name'
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ResourceTable from "admin/merchants/resource_table.vue";
import MerchantFilter from "./filter.vue";
import Resources from "../resources.js";
import { mapGetters } from "vuex";

export default {
  components: {
    MerchantFilter,
    ResourceTable,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("clearFilterResourceIds");
    });
  },
  computed: {
    resource() {
      return this.$store.getters.current_resource;
    },
    ...mapGetters([
      "anyResources",
      "current_resource",
      "currentResourceTypeTitle",
      "filter",
      "resourceAttributes",
      "resourceOrderBy",
      "resourcePages",
      "resources",
      "resourcesCurrentPage",
      "resourcesTotal",
    ]),
  },
  watch: {
    resource: function (val, oldVal) {
      if (oldVal != val) {
        return this.$store.dispatch("loadFilter", { resource: val });
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("loadFilter", {
      resource: this.$store.getters.current_resource,
    });
  },
  mounted() {
    return this.$store
      .dispatch("fetchResourceCounts")
      .then(() => {
        return this.$store.dispatch("fetchCountryCounts");
      })
      .then(() => {
        return this.$store.dispatch("query", {
          resource: Resources.PROPERTIES,
          endpoint: Resources.PROPERTIES,
        });
      });
  },
  methods: {
    addToFilter(key, value) {
      const resource = this.$store.getters.current_resource;
      const filter = this.$store.getters.filter(resource);
      if (!filter[key]) {
        filter[key] = [];
      }
      filter[key].push(value);
      this.$store.dispatch("updateFilter", {
        filter: filter,
        resource: resource,
      });
    },
    setResourceString(key, value) {
      this.$store.dispatch("setResourceString", value.toLowerCase());
    },
    changePage(page) {
      this.$store.dispatch("changePage", {
        resource: this.$store.getters.current_resource,
        page: page,
      });
    },
    removeFromFilter(key, value) {
      const resource = this.$store.getters.current_resource;
      const filter = this.$store.getters.filter(resource);
      if (filter[key].includes(value)) {
        filter[key] = filter[key].filter(function (el) {
          if (el !== value) {
            return el;
          }
        });
      }
      this.$store.dispatch("updateFilter", {
        filter: filter,
        resource: resource,
      });
    },
    updateOrderBy(newOrderBy) {
      this.$store.dispatch("updateOrderBy", {
        orderBy: newOrderBy,
        resource: this.$store.getters.current_resource,
      });
    },
  },
};
</script>
