/*
 * This file renders Microblink's BlinkID In-Browser
 * Located At https://github.com/BlinkID/blinkid-in-browser
 *
 * RENDERING THE SCANNER
 *
 * There are many ways to render BlinkID section and many ways to start or
 * stop the scanner. What we have chosen to do, is to copy some some JS files
 * from the "@microblink/blinkid-in-browser-sdk" package installed via
 * packages.json. These files are copied to public/blinkid. The file that
 * picks up the element configuration details and renders the form is
 * "/blinkid/js/blinkid-in-browser/blinkid-in-browser.esm.js" so
 * this file needs to be present on any page we want to render the scanner.
 *
 * If you want to render the scanner different, it is possible to do it
 * using the SDK js files, and that information is available in the github link.
 *
 * UPGRADING THE PACKAGE
 *
 * Microblink API Keys are tied to SDK Versions, usually with a high ceiling,
 * meaning that an API key will live for several major versions. But if you
 * issue a new key with a different floor version, then you will need to
 * update the SDK and copy certain files over like this:
 * `cp -r
 *   node_modules/\@microblink/blinkid-in-browser-sdk/resources public/blinkid/`
 *
 * TODO(Ivan): Add details on how to update the files in "public/blinkid/js"
 *
 * TESTING LOCALLY
 *
 * To test this locally, you need to log into microblink https://developer.microblink.com/
 * with you pie email address and create a new trial license.
 * Then set that in the rails credentials for the development environment
 * `EDITOR="code --wait" rails credentials:edit --environment development`
 *  blinkid_license_key: YOUR_NEW_KEY
 * Then you need to make sure your key works for the current version of the
 * SDK and update it if you need to. You can check to make sure the scanner
 * initializes by going to /traveler/passport/edit when logged in as a Tourist.
 */

import * as Sentry from "@sentry/browser";
import { Controller } from "@hotwired/stimulus";
import * as BlinkIDSDK from "@microblink/blinkid-in-browser-sdk";

import { setupBlinkID } from "../shared/blinkid";

export default class extends Controller {
  static targets = [
    "blinkId",
    "givenNames",
    "surname",
    "number",
    "dateOfBirth",
    "dateOfExpiration",
    "nationality",
    "imageFileUpload",
    "imageView",
  ];

  connect() {
    if (BlinkIDSDK.isBrowserSupported()) {
      console.log("BlinkID is supported");
      this.blinkIdTarget.classList.remove("d-none");

      setupBlinkID(this.blinkIdTarget);
    } else {
      console.log("BlinkID is not supported");
    }
  }

  startCameraScan() {
    this.dispatch("blinkIdStart");

    this.blinkIdTarget.startCameraScan();
  }

  scanStartedManually() {
    this.dispatch("blinkIdStart");
  }

  updateFromScan(event) {
    this.dispatch("blinkIdFinish");

    const passportDetails = event.detail.recognizer;
    this.numberTarget.value = passportDetails.documentNumber.latin;
    // Some passports don't distinguish between first and last name,
    // and instead return a fullName attribute. We require at least one
    // name field to be populated
    this.givenNamesTarget.value =
      passportDetails.firstName.latin || passportDetails.fullName.latin;
    this.surnameTarget.value = passportDetails.lastName.latin;
    this.nationalityTarget.value = passportDetails.mrz.nationality;
    this.dateOfBirthTarget.value = this.#formatDateForInput(
      passportDetails.dateOfBirth,
    );
    this.dateOfExpirationTarget.value = this.#formatDateForInput(
      passportDetails.dateOfExpiry,
    );

    // The next block is fauly on Huawei devices.
    try {
      const imageBlob = new Blob(
        [new Uint8Array(passportDetails.fullDocumentImage.encodedImage)],
        { type: "image/jpeg" },
      );

      this.#addImageToScreen(imageBlob);
      this.#addImageForUpload(imageBlob);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }

  /* The error handler here is listening for events on the blinkId element.
   * The errors that we expect are going to configuration issues
   * when initializing the blinkId element.
   * Dom(TODO): Update blinkId translations and handle the errors more
   * gracefully.
   */
  handleBlinkIdError(event) {
    this.dispatch("blinkIdFinish");

    console.log(event);
  }

  /**
   * Returns the given date in YYYY-MM-DD format
   * appropriate for <input type="date"> element values.
   *
   * @param {date} Microblink recognizer Date.
   * @returns {string} year-month-day string.
   */

  #formatDateForInput(date) {
    const year = date.year;
    const month = date.month.toString().padStart(2, "0");
    const day = date.day.toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  #addImageToScreen(imageBlob) {
    const imageUrl = URL.createObjectURL(imageBlob);
    this.imageViewTarget.src = imageUrl;
    this.imageViewTarget.style.display = "block";
  }

  #addImageForUpload(imageBlob) {
    const dataTransfer = new DataTransfer();
    const file = new File([imageBlob], "passport-scan.jpeg", {
      type: "image/jpeg",
    });
    dataTransfer.items.add(file);

    this.imageFileUploadTarget.files = dataTransfer.files;
  }
}
