import { Controller } from "@hotwired/stimulus";
/*
 * When attached to a form, this controller will disable the submit
 * button until all the input is valid on the form.
 * Usage:
 *  - Attach this controller to the form
 *  - Add an input / change listener on all the inputs for "enableSubmitButton"
 *  - Attach a target to the submit button.
 */
export default class extends Controller {
  static targets = ["submitButton", "waitForEnabled"];
  connect() {
    this.enableSubmitButton();
  }

  enableSubmitButton() {
    const isEnabled = (target) => {
      return !target.disabled;
    };
    // Only enable submit after all the other fields are enabled and valid.
    // This works around the issue of inputs that are initially disabled because
    // they are waiting for Javascript handling and are not considered invalid
    // by the form logic.
    const formValid = this.element.reportValidity();
    const targetsEnabled = this.waitForEnabledTargets.every(isEnabled);
    if (formValid && targetsEnabled) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
