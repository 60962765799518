import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { integrationType: String };
  static outlets = ["error-alert"];

  async connect() {
    try {
      const response = await fetch(
        `/integrations/recent_orders?${this.integrationTypeValue}`,
      );
      this.element.innerHTML = await response.text();
    } catch (e) {
      console.log(e);
      this.element.style.display = "none";
      this.errorAlertOutlet.showAlert(
        "Something went wrong. Please try again.",
      );
    }
  }
}
