import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

import { WiseAccountForm } from "wise/wise_account_form";

export default class extends Controller {
  connect() {
    // TODO(alan): This was migrated from a main packfile and could be cleaned
    // up to be idiomatic Stimulus.
    const twData = document.getElementById("wise-main-data").dataset;

    /**
     * @type {String} Path to the loader image
     */
    const LOADER_PATH = twData.loaderPath;
    /**
     * @type {String} Base route to the Transferwise account being created/edit.
     *     E.g. /traveler/transferwise vs /admin/user/...
     */
    const BASE_PATH = twData.basePath;
    /**
     * @type {String} Route to return to after successful create/update. E.g.
     *     user account details page vs admin user show page.
     */
    const BACK_PATH = twData.backPath;
    /**
     * @type {String} The base URL of the Wise HTTP API
     */
    const WISE_BASE_URL = twData.wiseBaseUrl;

    const constructorArgs = {
      currency: $("#selected_currency").val(),
      wiseBaseUrl: WISE_BASE_URL,
      basePath: BASE_PATH,
      backPath: BACK_PATH,
      loaderPath: LOADER_PATH,
      isNew: !twData.hasTransferwise,
    };

    new WiseAccountForm(constructorArgs);

    $("#set-currency-button").click(function (e) {
      console.debug("on click set currency");
      e.preventDefault();
      const loader_html = `<img src="${LOADER_PATH}" class="loader"/>`;
      $("#transferwise_select_anchor").html(loader_html);
      $("#transferwise_account_anchor").html("");
      console.debug("cleared account anchor");
      $("transferwise_submit_anchor").html("");
      constructorArgs.currency = $("#selected_currency").val();
      new WiseAccountForm(constructorArgs);
    });
  }
}
