import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

Turbo.session.drive = false;

/**
 * Makes an element visit a turbo frame with configuration to disable visiting
 * on sub-elements and for use with modals.
 * Use "click->turbo-frame-link#visit" for normal usage.
 * Use "click->turbe-frame-link#dismissModalThenVisit" in modals
 */
export default class extends Controller {
  static targets = ["noClick"];
  static values = {
    url: String,
    frameId: String,
    modalId: String,
    isClickable: { type: Boolean, default: true },
  };

  visit(event) {
    if (this.isClickable(event.target)) {
      Turbo.visit(this.urlValue, { frame: this.frameIdValue });
    }
  }

  dismissModalThenVisit(event) {
    const modal = $(`#${this.modalIdValue}`);
    modal.on("hidden.bs.modal", () => {
      this.visit(event);
    });
    modal.modal("hide");
  }

  private;

  isClickable(targetElement) {
    return this.isClickableValue && this.ancestorsAreClickable(targetElement);
  }

  ancestorsAreClickable(targetElement) {
    return !this.ancestors(targetElement).some((el) =>
      this.noClickTargets.includes(el),
    );
  }

  ancestors(targetElement) {
    const ancestors = [];
    let parent = targetElement.parentElement;
    while (parent && parent != this.element) {
      ancestors.push(parent);
      parent = parent.parentElement;
    }
    return ancestors;
  }
}
