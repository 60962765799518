<template>
  <VueTable
    :title="resourceTypeTitle(resourceType)"
    :attributes="attributes"
    :items="resources(resourceType)"
    :pages="resourcePages(resourceType)"
    :total="resourcesTotal(resourceType)"
    :current-page="resourcesCurrentPage(resourceType)"
    :order-by="resourceOrderBy(resourceType)"
    :resource-string="resourceType"
    :change-page="changePage"
    :update-order-by="updateOrderBy"
  />
</template>
<script>
import VueTable from "components/table.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VueTable,
  },
  props: {
    // TODO(alan): This should really be a list of the attribute names we want
    // to show instead of the entire attribute specification
    attributes: {
      type: Object,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
    changePage: {
      type: Function,
      required: true,
    },
    updateOrderBy: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      "resourceOrderBy",
      "resourcePages",
      "resources",
      "resourcesCurrentPage",
      "resourcesTotal",
      "resourceTypeTitle",
    ]),
  },
};
</script>
