import { jsonFetchFn, baseUriFetchFn } from "shared/fetch_decorators";

/**
 * WiseApi abstracts the details of calling Wise's HTTP API. Clients of
 * this class need not be aware of the protocol being used to get the data.
 */
export class WiseApi {
  constructor(baseUrl) {
    this.fetch = jsonFetchFn(baseUriFetchFn(fetch, baseUrl));
  }

  async createCardToken(cardNumber) {
    const response = await this.fetch("/v3/card", {
      method: "POST",
      body: { cardNumber },
    });
    const json = await response.json();
    if (response.status === 422) {
      const msg = json.validation.cardNumber;
      throw new Error(`Card validation failed: ${msg}`);
    }
    if (!response.ok) {
      throw new Error(`Card tokenization failed ${json}`);
    }
    return json.cardToken;
  }
}
