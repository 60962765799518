<template>
  <button type="button" :class="buttonClass()" @click="clickHandler">
    {{ text }} <span :class="badgeClass()">{{ number }}</span>
  </button>
</template>
<script>
export default {
  props: [
    "text",
    "number",
    "queryKey",
    "queryValue",
    "isActive",
    "isNegative",
    "negativeQueryKey",
    "removeFromFilter",
    "addToFilter",
    "queryAction",
  ],
  methods: {
    clickHandler() {
      if (this.isActive) {
        this.removeFromFilter(this.queryKey, this.queryValue);
        this.addToFilter(this.negativeQueryKey, this.queryValue);
        this.$store.dispatch(this.queryAction);
        return;
      }
      if (this.isNegative) {
        this.removeFromFilter(this.negativeQueryKey, this.queryValue);
        this.$store.dispatch(this.queryAction);
        return;
      }
      this.addToFilter(this.queryKey, this.queryValue);
      this.$store.dispatch(this.queryAction);
      return;
    },
    buttonClass() {
      const unselected = !this.isActive && !this.isNegative;
      return [
        "filter-btn",
        "btn",
        this.isActive ? "btn-info" : "",
        this.isNegative ? "btn-danger" : "",
        unselected ? "btn-outline-info" : "",
      ];
    },
    badgeClass() {
      return [
        "badge",
        this.isActive || this.isNegative ? "badge-light" : "badge-info",
      ];
    },
  },
};
</script>

<style scoped>
.filter-btn {
  margin: 5px;
  margin-top: 0px;
  width: 165px;
  text-align: left;
}
</style>
