class FakeTelephoneNumberField {
  isEmpty() {
    return true;
  }
  isValid() {
    return true;
  }
  erase() {}
  updateForFormSubmission() {}
  getNumber() {
    return "";
  }
}
export { FakeTelephoneNumberField };
