import { Controller } from "@hotwired/stimulus";
import { install, uninstall } from "@github/hotkey";

// Manages hotkey assignment to inputs.
//
// To add a hotkey to an input add data-controller="hotkey" and
// data-hotkey="<shortcut>" to a desired element.
//
// For example, to trigger a button click on Command+s:
//
//   <button data-controller="hotkey" data-hotkey="Meta+s">
//
// See https://github.com/github/hotkey for details.
export default class extends Controller {
  connect() {
    if (this.disabled) return;
    install(this.element);
  }

  disconnect() {
    if (this.disabled) return;
    uninstall(this.element);
  }

  get disabled() {
    return this.element.disabled || this.element.classList.contains("disabled");
  }
}
