import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// TODO(alan): Replace this with plain form controls
export default class extends Controller {
  static values = {
    confirmEmailPath: String,
    updateUserPath: String,
  };

  updateEmail(event) {
    event.preventDefault();
    $("#email-submit").addClass("btn-loader");
    $(".smaller-loader").show();
    const email = $("#user-email-modal").val();
    this.update_email_post(email).always(function () {
      $(".smaller-loader").hide();
      $("#email-submit").removeClass("btn-loader");
    });
  }

  update_email_post(email) {
    return window.pie
      .call(this.updateUserPathValue, { user: { email: email } }, "PUT")
      .done(function () {
        $("#email-error").hide();
        $("#email-success").show();
      })
      .fail(function (res, _data) {
        if (res?.responseJSON?.error) {
          $("#email-error").html(res.responseJSON.error);
        } else {
          $("#email-error").html(
            "Could not send email, please try again later. We will be fixing this asap.",
          );
        }

        $("#email-error").show();
      });
  }

  confirmEmail() {
    this.element.disabled = true;
    window.pie
      .call(this.confirmEmailPathValue, {}, "POST")
      .done(function () {
        $("#confirm_email_success").show();
      })
      .fail(function (res) {
        if (res?.responseJSON?.error) {
          $("#confirm_email_error").html(res.responseJSON.error);
        } else {
          $("#confirm_email_error").html(
            "Something went wrong while trying to connect.",
          );
        }
        $("#confirm_email_error").show();
      });
  }
}
