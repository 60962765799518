import {
  get,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["credentialField"];

  initialize() {
    // for test mocking
    this.webauthnGet = get;
  }

  async submit(_event) {
    // Safe navigation is used here because in the test environment the CSRF
    // token is not present.
    const csrfToken = document.getElementsByName("csrf-token")[0]?.content;
    const challengeFetch = fetch("/users/sign_in/new_challenge", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": csrfToken,
      },
    });

    const challengeJSON = await (await challengeFetch).json();
    const credentialAuthenticationOptions = parseRequestOptionsFromJSON({
      publicKey: challengeJSON,
    });

    try {
      const credentialAuthenticationResponse = await this.webauthnGet(
        credentialAuthenticationOptions,
      );

      this.credentialFieldTarget.value = JSON.stringify(
        credentialAuthenticationResponse,
      );

      this.element.submit();
    } catch (error) {
      console.info(error);
    }
  }
}
