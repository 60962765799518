import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm.js";
import Vuex from "vuex";
import OrderApp from "admin/order_app.vue";
import { pieApiFetch, checkResponseOk } from "shared/fetch_decorators";

Vue.use(Vuex);

export default class extends Controller {
  connect() {
    const order_id = this.element.dataset.orderId;
    const currentTags = JSON.parse(this.element.dataset.orderTags);
    const availableTags = JSON.parse(this.element.dataset.availableTags);
    const needs = JSON.parse(this.element.dataset.orderNeeds);
    const store = new Vuex.Store({
      state: {
        loading: false,
        current_tags: currentTags,
        available_tags: availableTags,
        available_users: [],
        available_properties: [],
        order_id: order_id,
        needs: needs,
        assign_property_error: "",
        assign_user_error: "",
      },
      mutations: {
        startLoading: (state) => (state.loading = true),
        finishLoading: (state) => (state.loading = false),
        updateCurrentTags(state, tags) {
          state.current_tags = tags;
        },
        updateAvailableTags(state, tags) {
          state.available_tags = tags;
        },
        updateAvailableUsers(state, users) {
          Vue.set(state, "available_users", users);
        },
        updateAvailableProperties(state, properties) {
          Vue.set(state, "available_properties", properties);
        },
        updatedUserAssignError(state, error) {
          Vue.set(state, "assign_user_error", error);
        },
        updatedPropertyAssignError(state, error) {
          Vue.set(state, "assign_property_error", error);
        },
      },
      actions: {
        queryTags: async ({ commit }, tagPayload) => {
          commit("startLoading");
          try {
            const response = await pieApiFetch(
              `/admin/orders/${tagPayload.parentResourceId}/available_tags?query=${tagPayload.tag}`,
            );
            if (response.ok) {
              const json = await response.json();
              commit("updateAvailableTags", json.tags);
            }
          } finally {
            commit("finishLoading");
          }
        },
        addTag: async ({ commit }, tagPayload) => {
          commit("startLoading");
          try {
            const response = await pieApiFetch(
              `/admin/orders/${tagPayload.parentResourceId}/add_tag`,
              {
                method: "POST",
                body: {
                  tag: tagPayload.tag.name,
                },
              },
            );
            if (response.ok) {
              const json = await response.json();
              commit("updateCurrentTags", json.tags);
            }
          } finally {
            commit("finishLoading");
          }
        },
        removeTag: async ({ commit }, tagPayload) => {
          commit("startLoading");
          try {
            const response = await pieApiFetch(
              `/admin/orders/${tagPayload.parentResourceId}/remove_tag`,
              {
                method: "POST",
                body: {
                  tag: tagPayload.tag,
                },
              },
            );
            if (response.ok) {
              const json = await response.json();
              commit("updateCurrentTags", json.tags);
            }
          } finally {
            commit("finishLoading");
          }
        },
        queryProperties: async ({ commit }, term) => {
          commit("startLoading");
          try {
            const response = await pieApiFetch(
              `/admin/properties/query?per_page=10&search=${term}`,
            );
            if (response.ok) {
              const json = await response.json();
              commit("updateAvailableProperties", json.properties);
            }
          } finally {
            commit("finishLoading");
          }
        },
        queryUsers: async ({ commit }, user) => {
          // Users the datatables search
          commit("startLoading");
          // The encoding is bad when using a nested object in params for a get
          try {
            const response = await pieApiFetch(
              `/admin/users/query?search[value]=${user}`,
            );
            if (response.ok) {
              const json = await response.json();
              commit("updateAvailableUsers", json.users);
            }
          } finally {
            commit("finishLoading");
          }
        },
        assignUser: async ({ commit, state }, user_id) => {
          // Users the datatables search
          commit("startLoading");
          try {
            const body = {
              user: {
                id: user_id,
              },
            };
            const options = {
              method: "POST",
              body: body,
            };
            const response = await pieApiFetch(
              `/admin/orders/${state.order_id}/reassign_user`,
              options,
            );
            await checkResponseOk(response);
            location.reload();
          } catch (error) {
            commit("updatedUserAssignError", error.message);
          } finally {
            commit("finishLoading");
          }
        },
        assignProperty: async ({ commit, state }, property_id) => {
          // Users the datatables search
          commit("startLoading");

          try {
            const body = {
              property: {
                id: property_id,
              },
            };
            const options = {
              method: "POST",
              body: body,
            };
            const response = await pieApiFetch(
              `/admin/orders/${state.order_id}/reassign_property`,
              options,
            );
            await checkResponseOk(response);
            location.reload();
          } catch (error) {
            commit("updatedPropertyAssignError", error.message);
          } finally {
            commit("finishLoading");
          }
        },
      },
      getters: {
        isLoading: (state) => () => {
          return state.loading;
        },
        current_tags: (state) => {
          return state.current_tags;
        },
        available_tags: (state) => {
          return state.available_tags;
        },
        needs: (state) => {
          return state.needs;
        },
        available_users: (state) => {
          return state.available_users;
        },
        available_properties: (state) => {
          return state.available_properties;
        },
        assign_user_error: (state) => {
          return state.assign_user_error;
        },
        assign_property_error: (state) => {
          return state.assign_property_error;
        },
        order_id: (state) => {
          return state.order_id;
        },
      },
    });

    new Vue({
      el: this.element,
      store,
      components: { OrderApp },
      template: "<OrderApp/>",
    });
  }
}
