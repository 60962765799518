import $ from "jquery";

import { WiseApi } from "wise/wise_api";
import { WisePieApi } from "wise/wise_pie_api";

export class WiseAccountForm {
  constructor({
    currency,
    wiseBaseUrl,
    basePath,
    backPath,
    loaderPath,
    isNew,
  }) {
    console.debug("WiseAccountForm ctor");
    this.currency = currency;
    this.api = new WisePieApi(basePath);
    this.add_submit_button = this.add_submit_button.bind(this);
    this.add_delete_button = this.add_delete_button.bind(this);
    this.on_submit = this.on_submit.bind(this);
    this.handle_requirement_response =
      this.handle_requirement_response.bind(this);
    this.render_field_set = this.render_field_set.bind(this);
    this.registered_ids = [];
    this.DOMState = {};
    this.pushState = {};
    this.types = {};
    this.address = "";
    this.selected_type = "";
    this.accountHolderName = "";
    this.transferwise_select_anchor = "transferwise_select_anchor";
    this.transferwise_account_anchor = "transferwise_account_anchor";
    this.transferwise_submit_anchor = "transferwise_submit_anchor";
    this.transferwise_select = "transferwise_select";
    this.$top_loader = $("#top_loader");
    this.twApi = new WiseApi(wiseBaseUrl);
    this.basePath = basePath;
    this.backPath = backPath;
    this.loaderPath = loaderPath;
    this.isNew = isNew;

    if (currency) {
      this.get_and_display_currency_requirements();
    } else {
      $("#page-alerts").html("Please select a currency to get started.");
      $("#page-alerts").show();
      $(document).scrollTop($("#page-alerts").offset().top);
    }
  }

  add_submit_button(title) {
    const html = `
          <div class="form-row">
            <div class="col-sm-12">
              <div class="form-group col-md-6">
                <button class="btn btn-primary" id="transferwise_submit_button">
                  ${title}
                </button>
              </div>
            </div>
          </div>
        `;
    $(`#${this.transferwise_submit_anchor}`).html(html);
    document
      .querySelector("#transferwise_submit_button")
      .addEventListener("click", this.on_submit);
  }

  async on_submit(event) {
    event.preventDefault();
    this.dom_state_to_push_state();
    try {
      await this.apply_cny_union_pay_workaround();
      await this.api.createAccount({
        currencyId: this.currency,
        accountHolderName: this.pushState["accountHolderName"],
        type: this.selected_type,
        details: this.pushState,
      });
      $("#page-alerts").hide();
      window.location.href = this.backPath;
    } catch (e) {
      this.handle_error(e);
      return false;
    }
  }

  /**
   * See https://docs.google.com/document/d/1tle2Ce27_-2dldrgV-6rJMgFoC3yRvj1p0P9HHZpdEg/edit#
   */
  async apply_cny_union_pay_workaround() {
    // This ID comes from our backend's database. It'd probably be better
    // if we could use the 3-character ISO code instead.
    const CNY_CURRENCY_ID = "51";
    const UNION_PAY_TYPE = "chinese_card";
    if (
      this.currency === CNY_CURRENCY_ID &&
      this.selected_type === UNION_PAY_TYPE
    ) {
      const token = await this.twApi.createCardToken(
        this.pushState["cardNumber"],
      );
      this.pushState["cardToken"] = token;
      delete this.pushState["cardNumber"];
    }
  }

  add_delete_button(title) {
    const html = `
          <div class="form-row">
            <div class="col-sm-12">
              <div class="form-group col-md-6">
                <button class="btn btn-primary"
                    id="transferwise_delete_button">
                  ${title}
                </button>
              </div>
            </div>
          </div>
        `;
    $(`#${this.transferwise_submit_anchor}`).append(html);
    $("#transferwise_delete_button").click(async (event) => {
      event.preventDefault();
      try {
        await this.api.deleteAccount();
        $("#page-alerts").hide();
        window.location.href = this.backPath;
      } catch (e) {
        this.handle_error(e);
      }
    });
  }

  handle_requirement_response(account) {
    if (account.type !== "email") {
      return (this.types[account.type] = {
        title: account.title,
        fields: account.fields,
        type: account.type,
      });
    }
  }

  render_new_account(types) {
    console.debug("render_new_account");
    let type;
    $("#transferwise_select_anchor").html("");
    $("#transferwise_account_anchor").html("");
    const options = types
      .filter((value) => value.type !== "email")
      .map((type) => this.select_option_template(type.type, type.title));
    if (options.length === 1) {
      type = $(options[0]).val();
      this.selected_type = type;
      return this.render_field_set(this.types[this.selected_type]);
    } else {
      const select_field = this.account_type_select(options);
      $("#transferwise_select_anchor").html(select_field);
      $("#transferwise_select").on("change", (event) => {
        type = event.target.value;
        this.selected_type = type;
        this.render_field_set(this.types[this.selected_type]);
        return (this.pushState = {});
      });
      this.selected_type = $("#transferwise_select").val();
      return this.render_field_set(this.types[this.selected_type]);
    }
  }

  render_field_set(field_set) {
    // This array will be filled by the template methods
    this.registered_ids = [];
    const html = this.add_fieldset(field_set);
    console.debug("rendering account anchor");
    $("#transferwise_account_anchor").html(html);
    console.debug("rendered account anchor");
    $("#transferwise_account_anchor").append();
    const results = [];
    for (const id of this.registered_ids) {
      results.push(this.attach_change_listener(id));
    }
    return results;
  }

  account_type_select(options) {
    const id = "transferwise_select";
    return `
          <div class="form-group col-sm-12 col-lg-6">
            <label for="${id}">Account Type</label>
            <select id="${id}" class="form-control">${options.join("")}</select>
          </div>
        `;
  }

  add_fieldset(field_set) {
    console.debug(field_set);
    const html = field_set.fields
      .map((field) => {
        return this.render_field(field_set.type, field);
      })
      .flat()
      .join("");

    return `
          <div class="form-group col-sm-12" id="${field_set.type}">
            <h5>${field_set.title}</h5>
            <div class="form-row">
              ${html}
            </div>
          </div>
        `;
  }

  render_field(type, field) {
    return field.group.map((field) => {
      return this.render_field_type(field);
    });
  }

  render_field_type(field) {
    switch (field.type) {
      case "select":
        return this.select_field_template(field.type, field);
      case "text":
        return this.text_field_template(field.type, field);
      case "radio":
        return this.radio_button_template(field.type, field);
      case "date":
        return this.date_field_template(field.type, field);
      default:
        return "<div> Unknown Field Type </div>";
    }
  }

  select_field_template(type, field) {
    const id = this.convert_key_to_id(type, field.key);
    this.registered_ids.push(id);
    let refresh_on_change = "";
    let required = "";
    if (field.refreshRequirementsOnChange) {
      refresh_on_change = ' data-refresh-on-change="true" ';
    }
    if (field.required) {
      required = "*";
    }
    if (field.valuesAllowed != null && field.valuesAllowed.length > 0) {
      const options = field.valuesAllowed.map((option) => {
        return this.select_option_template(
          option.key,
          option.name,
          this.DOMState[field.key] === option.key,
        );
      });
      if (!this.DOMState[field.key]) {
        this.DOMState[field.key] = field.valuesAllowed[0].key;
      }
      // @pushState[]
      return `
            <div class="form-group col-sm-12 col-lg-6">
              <label for="${id}">${field.name} ${required}</label>
              <select ${refresh_on_change}
                  name="${field.key}"
                  id="${id}"
                  class="form-control">
                ${options.join("")}
              </select>
            </div>
          `;
    }
  }

  select_option_template(key, value, selected) {
    let sel_attr = "";
    if (selected) {
      sel_attr = ' selected="true" ';
    }
    return `<option value="${key}"${sel_attr}>${value}</option>`;
  }

  text_field_template(type, field) {
    const id = this.convert_key_to_id(type, field.key);
    this.registered_ids.push(id);
    const value = this.DOMState[field.key] || "";
    let required = "";
    let refresh_on_change = "";
    if (field.refreshRequirementsOnChange) {
      refresh_on_change = ' data-refresh-on-change="true" ';
    }
    if (field.required) {
      required = "*";
    }
    return `
          <div class="form-group col-sm-12 col-lg-6">
            <label for="${id}">${field.name} ${required}</label>
            <input ${refresh_on_change}
                id="${id}"
                class="form-control"
                type="text"
                value="${value}"
                name="${field.key}"
            >
          </div>
        `;
  }

  date_field_template(type, field) {
    const id = this.convert_key_to_id(type, field.key);
    this.registered_ids.push(id);
    const value = this.DOMState[field.key] || "";
    let refresh_on_change = "";
    if (field.refreshRequirementsOnChange) {
      refresh_on_change = ' data-refresh-on-change="true" ';
    }
    let required = "";
    if (field.required) {
      required = "*";
    }
    return `
      <div class="form-group col-sm-12 col-lg-6">
        <label for="${id}">${field.name} ${required}</label>
        <input ${refresh_on_change}
            id="${id}"
            class="form-control"
            type="date"
            value="${value}"
            name="${field.key}"
        >
      </div>`;
  }

  dom_state_to_push_state() {
    Object.entries(this.DOMState).forEach(([name, value]) => {
      // TODO(alan): What exactly is this doing/why?
      // Handling 1 level of nesting?
      if (name.indexOf(".") > -1) {
        const keys = name.split(".");
        this.pushState[keys[0]] = this.pushState[keys[0]] || {};
        this.pushState[keys[0]][keys[1]] = value;
      } else {
        this.pushState[name] = value;
      }
    });
  }

  loader_html(id) {
    return `<img src="${this.loaderPath}" id="${id}" class="loader"/>`;
  }

  handle_error(error) {
    console.error(error);
    this.show_error_message(error.message);
  }

  show_error_message(message_html) {
    $("#page-alerts").html(
      `We had some trouble with the request: <br/>${message_html}`,
    );
    $("#page-alerts").show();
    $(document).scrollTop($("#page-alerts").offset().top);
  }

  attach_change_listener(id) {
    return $(`#${id}`).on("change", async (event) => {
      const data = $(event.target).data();
      this.DOMState[event.target.name] = event.target.value;
      if (data.refreshOnChange) {
        this.dom_state_to_push_state();
        $(`#${this.transferwise_account_anchor}`).fadeTo(200, 0.5);
        console.debug("dimmed account anchor");
        $(event.target).parent().html(this.loader_html("loader"));

        try {
          const response = await this.api.accountRequirements({
            currencyId: this.currency,
            type: this.selected_type,
            details: this.pushState,
          });
          $("#page-alerts").hide();
          response.forEach(this.handle_requirement_response);
          this.render_new_account(response);
          $(`#${this.transferwise_account_anchor}`).fadeTo(200, 1);
          console.debug("fade in account anchor");
        } catch (e) {
          return this.handle_error(e);
        }
      }
    });
  }

  convert_key_to_id(type, key) {
    return type + "_" + key.replace(".", "_");
  }

  radio_button_options(id, key, display_name, value, refresh, selected) {
    let checked = "";
    id = id + "_" + value;
    this.registered_ids.push(id);
    if (selected) {
      checked = " checked ";
    }
    return `
          <div class="custom-control custom-radio">
            <input ${refresh}
                type="radio"
                id="${id}"
                name="${key}"
                value="${value}"
                class="custom-control-input"
                ${checked}>
            <label class="custom-control-label" for="${id}">
              ${display_name}
            </label>
          </div>`;
  }

  radio_button_template(type, field) {
    const id = type + "_" + field.key;
    let refresh_on_change = "";
    let required = "";
    if (field.refreshRequirementsOnChange) {
      refresh_on_change = ' data-refresh-on-change="true" ';
    }
    if (field.required) {
      required = "*";
    }
    const inputs = field.valuesAllowed.map((option) => {
      return this.radio_button_options(
        id,
        field.key,
        option.name,
        option.key,
        refresh_on_change,
        this.DOMState[field.key] === option.key,
      );
    });
    return `
          <div class="form-group col-sm-12 col-lg-6">
            <label>${field.name} ${required}</label>
            ${inputs.join("")}
          </div>
        `;
  }

  async get_and_display_currency_requirements() {
    this.$top_loader.show();
    if (!this.isNew) {
      this.dom_state_to_push_state();
    }
    try {
      const response = await this.api.currencyRequirements(this.currency);
      response.forEach(this.handle_requirement_response);
      this.render_new_account(response);
      if (this.isNew) {
        this.add_submit_button("Add Account");
      } else {
        this.add_submit_button("Update Account");
        this.add_delete_button("Delete Account");
      }
    } catch (e) {
      this.handle_error(e);
    }
    this.$top_loader.hide();
  }
}
