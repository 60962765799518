export function createOrderFilter() {
  return {
    status: [],
    tags: [],
    negative_tags: [],
    negative_status: [],
    order_relation: null,
    // string with format YYYY-MM-DD or empty string
    created_after: "",
    // string with format YYYY-MM-DD or empty string
    created_before: "",
    minimum_total: null,
    maximum_total: null,
    currency_iso: "",
    search: "",
    property_id: "",
    order_search: "",
    user_search: "",
  };
}
