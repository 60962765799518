import DatatableController from "controllers/datatable_controller";

export default class extends DatatableController {
  static values = {
    url: String,
  };

  config() {
    return {
      serverSide: true,
      searchDelay: 700,
      ajax: {
        url: this.urlValue,
        type: "GET",
        dataSrc: "charges",
        beforeSend: function (request) {
          request.setRequestHeader("Accept", "application/json");
        },
      },
      order: [["5", "desc"]], // created_at
      search: {
        search: function () {
          const searchParam = new URLSearchParams(window.location.search);
          if (searchParam.has("search")) {
            return searchParam.get("search");
          }
        },
      },
      columns: [
        {
          data: "processor",
          orderable: false,
        },
        {
          data: "amount",
          orderable: false,
        },
        {
          data: "currency",
          orderable: true,
        },
        {
          data: "status",
          orderable: true,
        },
        {
          data: "attempt_count",
          orderable: true,
        },
        {
          data: "created_at",
          orderable: true,
          render: function (data, type, _row, _meta) {
            if (type === "display") {
              return moment(data).fromNow();
            }
            return data;
          },
        },
        {
          data: "link",
          orderable: false,
        },
      ],
    };
  }
}
