<template>
  <div class="row">
    <div class="col-12 col-lg-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ $t("purchases.original_details") }}</h5>
          <div class="card-text">
            <div class="row">
              <div class="col-6">{{ $t("tables.total") }}</div>
              <div class="col-6 text-right">{{ order.total }}</div>
            </div>
            <div class="row">
              <div class="col-6">{{ $t("tables.vat") }}</div>
              <div class="col-6 text-right">{{ order.tax_total }}</div>
            </div>
            <div class="row">
              <div class="col-6">
                {{ $t("user.orders.table.payout_estimate") }}
              </div>
              <div class="col-6 text-right">{{ order.payout_estimate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-8">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ $t("purchases.line_items") }}</h5>
          <div class="card-text">
            <table class="table">
              <tr>
                <th>{{ $t("line_item.table.name") }}</th>
                <th>{{ $t("line_item.table.category") }}</th>
                <th>{{ $t("line_item.table.quantity") }}</th>
                <th>{{ $t("line_item.table.return_quantity") }}</th>
                <th>{{ $t("line_item.unit_price_label") }}</th>
                <th>{{ $t("line_item.table.unit_vat") }}</th>
              </tr>
              <tr
                v-for="(line_item, index) in order.line_items"
                :key="line_item.id"
                class="return-line-item-row"
              >
                <td class="text-break line-item-name">
                  {{ line_item.name }}
                </td>
                <td class="text-break line-item-category">
                  {{ line_item.category }}
                </td>
                <td class="line-item-quantity-remaining">
                  {{ line_item.quantity_after_returns }}
                </td>
                <td>
                  <input
                    v-model.number="
                      newReturn.returned_line_items_attributes[index].quantity
                    "
                    type="number"
                    min="0"
                    :max="line_item.quantity_after_returns"
                    class="line-item-return-quantity"
                  />
                </td>
                <td>{{ line_item.unit_price_excluding_tax }}</td>
                <td>{{ line_item.unit_tax }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 text-right mb-3">
      <button
        id="return-preview-button"
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#return-preview-modal"
        :disabled="newReturnIsEmpty"
        @click="getPreview"
      >
        {{ $t("purchases.returns.preview_return") }}
      </button>
    </div>

    <div class="col-12 col-lg-8 offset-lg-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ $t("purchases.returns.plural") }}</h5>
          <div class="card-text">
            <OrderReturnSummary
              v-for="order_return in order.order_returns"
              :key="order_return.id"
              :order-return="order_return"
              class="mx-1 order-return"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="return-preview-modal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("purchases.returns.return_preview") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              :aria-label="$t('close')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="preview" class="modal-body">
            <OrderReturnSummary :order-return="preview" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              @click="clearPreview"
            >
              {{ $t("cancel") }}
            </button>
            <button
              id="create-return-button"
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="createReturn"
            >
              {{ $t("purchases.returns.create_return") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderReturnSummary from "components/order_returns/order_return_summary.vue";
import { createReturn, previewReturn } from "shared/order_returns_api";

export default {
  components: { OrderReturnSummary },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    const newReturn = {
      order_id: this.order.id,
      returned_line_items_attributes: this.order.line_items.map((li) => {
        return {
          line_item_id: li.id,
          quantity: 0,
        };
      }),
    };

    return {
      newReturn: newReturn,
      preview: undefined,
    };
  },
  computed: {
    newReturnIsEmpty() {
      return this.newReturn.returned_line_items_attributes.every(
        (li) => li.quantity === 0,
      );
    },
  },
  methods: {
    async createReturn() {
      await createReturn(this.newReturn);
      window.location.reload();
    },
    async getPreview() {
      this.preview = await previewReturn(this.newReturn);
    },
    clearPreview() {
      this.preview = undefined;
    },
  },
};
</script>
