import $ from "jquery";
import { initInternationalTelField } from "shared/telephone_input";

class TelephoneNumberField {
  constructor(rootElementId) {
    this.element = $(`#${rootElementId}`);
    this.intlTel = initInternationalTelField(rootElementId);
    // This element is added by initInternationalTelField
    this.intlTelContainer = $(`.tel-${rootElementId}`);
  }

  erase() {
    this.element.val("");
  }

  isValid() {
    return this.isEmpty() || this.intlTel.isValidNumber();
  }

  isEmpty() {
    return this.element.val().length == 0;
  }

  markAsInvalid() {
    this.intlTelContainer.addClass("is-invalid");
  }

  updateForFormSubmission() {
    // Set the international number as the value of the phone number field
    this.element.val(this.intlTel.getNumber());
  }

  getNumber() {
    return this.intlTel.getNumber();
  }
}

export { TelephoneNumberField };
