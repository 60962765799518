import * as Sentry from "@sentry/browser";

function initSentry(extraSentryConfig) {
  const config = {
    dsn: "https://d0589bfa15b543bb9ef880c26225ab6c@o1295387.ingest.sentry.io/6520840",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // We disabled tracing because we had a lot of Transactions in Sentry and
    // the data was mostly duplicated by the server side tracing. Note, this
    // still captures errors.
    enableTracing: false,

    // Set `tracePropagationTargets` to control for which URLs distributed
    // tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/app.pievat.com\//,
      /^https:\/\/piesystems.io\//,
    ],

    // NOTE(alan): We're disabling replays for now due to suspected performance
    // issues.
    // https://pie-systems.slack.com/archives/C03N41X60GH/p1704831338415549
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,

    ...extraSentryConfig,
  };

  Sentry.init(config);
}

export { initSentry };
