import { Controller } from "@hotwired/stimulus";

// Used to hide instructions and show form fields after the user clicks
// the capture button on landing permit and receipt steps.
export default class extends Controller {
  static targets = ["instructions", "fields", "capture"];
  static values = { show: Boolean };

  connect() {
    if (this.showValue) {
      this.dispatch("shown");
    }
  }

  capture() {
    this.captureTarget.click();
  }

  showFields() {
    this.dispatch("hidden");

    this.instructionsTarget.classList.add("d-none");
    this.fieldsTarget.classList.remove("d-none");
  }
}
