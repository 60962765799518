import { dataTableLanguage } from "shared/data_table_language";

import JSZip from "jszip"; // For Excel export
import DataTable from "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-datetime"; // required for searchbuilder
import "datatables.net-searchbuilder-bs4";

DataTable.Buttons.jszip(JSZip); // For Excel export

function getDataTableOpts(tableConfig) {
  const buttonsConfig = [
    "colvis",
    {
      exportOptions: { columns: ":visible:not(.orders-table-controls)" },
      extend: "csv",
    },
    {
      // This does not work in Safari.
      // @see https://datatables.net/extensions/buttons/built-in
      exportOptions: { columns: ":visible:not(.orders-table-controls)" },
      extend: "excel",
    },
  ];
  const domConfig = `
    <'row'<'col-12'Q>>
    <'row'<'col-12 col-md-3'l><'col-12 col-md-9'f>>
    <'row'<'col-12'tr>>
    <'row'<'col-12 col-md-5'i><'col-sm-12 col-md-7'p>>
    <'row'<'col-12'B>>`;

  const columns = [
    {
      className: "orders-table-created-at",
      data: "created_at",
      orderable: true,
      render: (data, type, _row, _meta) => {
        if (type === "display") {
          return moment(data).format("YYYY-MM-DD HH:mm");
        }
        return data;
      },
      type: "date",
    },
    {
      className: "orders-table-receipt-id",
      data: "entered_receipt_id",
      orderable: true,
    },
    {
      className: "orders-table-property-name",
      data: "property_name",
      orderable: true,
      visible: false,
    },
    {
      className: "orders-table-property-address",
      data: "property_address",
      orderable: true,
      visible: false,
    },
    {
      className: "orders-table-user-country-of-residence",
      data: "user_country_of_residence",
      orderable: true,
      visible: false,
    },
    {
      className: "orders-table-user-country-of-citizenship",
      data: "user_country_of_citizenship",
      orderable: true,
    },
  ];
  if (tableConfig.showLineItems) {
    columns.push(
      {
        className: "orders-table-line-item-categories-and-quantities",
        data: "line_item_categories_and_quantities",
        orderable: true,
      },
      {
        className: "orders-table-line-item-names-and-quantities",
        data: "line_item_names_and_quantities",
        orderable: true,
        visible: false,
      },
      {
        className: "orders-table-line-item-quantities",
        data: "line_item_quantities",
        orderable: true,
      },
    );
  }
  columns.push({
    className: "orders-table-total",
    data: "total",
    orderable: true,
  });
  if (tableConfig.showJapaneseTaxTotals) {
    columns.push(
      {
        className: "orders-table-eight-percent-tax-total",
        data: "eight_percent_tax_total",
        orderable: true,
      },
      {
        className: "orders-table-ten-percent-tax-total",
        data: "ten_percent_tax_total",
        orderable: true,
      },
    );
  } else {
    columns.push({
      className: "orders-table-tax-total",
      data: "tax_total",
      orderable: true,
    });
  }

  columns.push(
    {
      className: "orders-table-status",
      data: "status",
      orderable: true,
    },
    {
      className: "orders-table-controls",
      data: "control_links",
      orderable: false,
    },
  );

  const dataTableOpts = {
    ajax: {
      url: tableConfig.url,
      type: "GET",
      dataSrc: "orders",
      data: (data) => {
        // We set the timezone to the merchant account that is loaded with
        // the dashboard. This keeps the view consistent based on who is
        // viewing the dashboard, an admin or a merchant
        data.time_zone = tableConfig.timeZone;
      },
    },
    buttons: buttonsConfig,
    language: dataTableLanguage,
    // See this for syntax https://datatables.net/reference/option/dom
    dom: domConfig,
    columns: columns,
    order: [[/* created_at */ 0, "desc"]],
  };
  return dataTableOpts;
}
function renderOrdersTable(config) {
  const opts = getDataTableOpts(config);
  new DataTable(".merchant-orders-table", opts);
}

export { renderOrdersTable };
