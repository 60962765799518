import { Controller } from "@hotwired/stimulus";

/*
 * The product image is the only proof that requires geo-location.
 * This class will ask the browser for geo-location permission, and
 * show an error if it's not available on the device. If geo-location is blocked
 * then a button is shown that will reload the page and prompt the user again.
 *
 */
export default class extends Controller {
  static targets = [
    "geoAlert",
    "geoNotice",
    "geoLocationButton",
    "geoLocationInput",
    "imageInput",
  ];

  connect() {
    console.info("product_image_form_controller_connect");
    this.geoLocationSetup(
      this.successfullyAccessedGeoLocation.bind(this),
      this.handleGeoLocationError.bind(this),
    );
  }

  successfullyAccessedGeoLocation(locationString) {
    console.info("successfullyAccessedGeoLocation");
    this.geoLocationInputTarget.value = locationString;
    this.geoNoticeTarget.style.display = "none";
    this.geoAlertTarget.style.display = "none";
    this.geoLocationButtonTarget.style.display = "none";
    this.imageInputTarget.style.display = "block";
  }

  handleGeoLocationError(error) {
    console.log(error);
    this.geoAlertTarget.innerHTML = error;
    this.geoAlertTarget.style.display = "block";
    this.geoNoticeTarget.style.display = "none";
    this.geoLocationButtonTarget.style.display = "block";
  }

  reloadPage(event) {
    event.preventDefault();
    window.location.reload(true);
  }

  geoLocationSetup(successFunction, errorHandler) {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          successFunction(
            `${position.coords.latitude},${position.coords.longitude}`,
          );
        },
        function (error) {
          errorHandler(error.message);
        },
      );
    } else {
      errorHandler(
        "Can not fetch location on device, please try another device or browser.",
      );
    }
  }
}
