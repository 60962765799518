import DatatableController from "controllers/datatable_controller";

export default class extends DatatableController {
  config() {
    return {
      serverSide: true,
      searchDelay: 700,
      ajax: {
        url: "/admin/payouts",
        type: "GET",
        dataSrc: "payouts",
        beforeSend: function (request) {
          request.setRequestHeader("Accept", "application/json");
        },
      },
      order: [["6", "desc"]], // created_at
      search: {
        search: function () {
          const searchParam = new URLSearchParams(window.location.search);
          if (searchParam.has("search")) {
            return searchParam.get("search");
          }
        },
      },
      columns: [
        {
          data: "processor",
          orderable: false,
        },
        {
          data: "amount",
          orderable: false,
        },
        {
          data: "source_currency",
          orderable: false,
        },
        {
          data: "target_currency",
          orderable: false,
        },
        {
          data: "status",
          orderable: false,
        },
        {
          data: "status_changed_at",
          orderable: true,
          render: function (data, type, _row, _meta) {
            if (type === "display" && data != null) {
              return moment(data).fromNow();
            }
            return data;
          },
        },
        {
          data: "created_at",
          orderable: true,
          render: function (data, type, _row, _meta) {
            if (type === "display") {
              return moment(data).fromNow();
            }
            return data;
          },
        },
        {
          data: "processing_at",
          orderable: true,
          render: function (data, _type, _row, _meta) {
            if (data !== null) {
              return moment(data).fromNow();
            }
            return data;
          },
        },
        {
          data: "",
          orderable: false,
          render: function (_data, _type, row, _meta) {
            const links =
              '<a href="/admin/payouts/' +
              row.id +
              '" data-method="get">Show</a>';
            return links;
          },
        },
      ],
    };
  }
}
