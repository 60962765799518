import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "daterangepicker";
import I18n from "i18n-js";

// From https://momentjs.com/docs/#/parsing/string-format/
const DATE_IN_LOCAL_FORMAT = "L";

export default class extends Controller {
  static targets = ["label"];
  static values = {
    url: String,
    startDate: String,
    endDate: String,
  };

  get startDate() {
    return moment(this.startDateValue);
  }

  get endDate() {
    return moment(this.endDateValue);
  }

  connect() {
    // This sets the locale for all daterangepicker datetime values as well.
    moment.locale(I18n.locale);

    $(this.element).daterangepicker(
      {
        startDate: this.startDate,
        endDate: this.endDate,
        ranges: this.#ranges,
        alwaysShowCalendars: false,
        opens: "left",
        buttonClasses: "btn btn-small",
        applyButtonClasses: "btn-secondary",
        showDropdowns: true,
        locale: {
          applyLabel: I18n.t("buttons.apply"),
          cancelLabel: I18n.t("cancel"),
          customRangeLabel: I18n.t("dateranges.custom_range"),
        },
      },
      this.#afterRangePicked.bind(this),
    );

    this.#setLabel();
  }

  #afterRangePicked(startDate, endDate) {
    this.startDateValue = startDate.toISOString();
    this.endDateValue = endDate.toISOString();
    this.#navigateToUrlWithStartFinish();
  }

  #navigateToUrlWithStartFinish() {
    const url = new URL(this.urlValue);

    // set timestamps with local tz offset
    url.searchParams.set("start", this.startDate.format());
    url.searchParams.set("finish", this.endDate.format());

    url.searchParams.set(
      "time_zone",
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    window.location = url;
  }

  #setLabel() {
    this.labelTarget.textContent = this.#formattedRange();
  }

  #formattedRange() {
    let rangeString = this.#formattedDate(this.startDate);

    if (!this.startDate.isSame(this.endDate, "day")) {
      rangeString += " – " + this.#formattedDate(this.endDate);
    }

    return rangeString;
  }

  #formattedDate(date) {
    return date.format(DATE_IN_LOCAL_FORMAT);
  }

  get #ranges() {
    return {
      [I18n.t("dateranges.today")]: [moment(), moment()],
      [I18n.t("dateranges.yesterday")]: [
        moment().subtract(1, "days"),
        moment().subtract(1, "days"),
      ],
      [I18n.t("dateranges.last_7_days")]: [
        moment().subtract(6, "days"),
        moment(),
      ],
      [I18n.t("dateranges.last_30_days")]: [
        moment().subtract(29, "days"),
        moment(),
      ],
      [I18n.t("dateranges.this_month")]: [
        moment().startOf("month"),
        moment().endOf("month"),
      ],
      [I18n.t("dateranges.last_month")]: [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      [I18n.t("metrics.past_year")]: [moment().subtract(364, "days"), moment()],
      [I18n.t("metrics.all_time")]: [moment().subtract(5, "years"), moment()],
    };
  }
}
