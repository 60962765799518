<template>
  <div class="form-group col-md-12">
    <PopOver
      v-if="displayPopup"
      :close-icon-click-action="hidePopup"
      :list-item-click-action="selectResource"
      :list-items="available_resources"
      :list-item-display-attribute="displayAttribute"
      :title="popover_title"
      :is-loading="isLoading(resultsString)"
    />

    <label>{{ label }}</label>

    <input
      :id="id"
      v-model="searchTerm"
      :disabled="disableInput"
      type="text"
      class="form-control"
      autocomplete="off"
      :required="required"
      @input="searchResource"
      @focus="showPopup()"
    />
  </div>
</template>

<script>
// TODO
/* eslint-disable vue/prop-name-casing */

import { debounce } from "lodash";
import { mapGetters } from "vuex";
import PopOver from "components/popover.vue";

export default {
  components: { PopOver },
  props: {
    available_resources: { required: true },
    defaultValue: { required: true },
    disableInput: { required: true },
    displayAttribute: { type: String, default: "name" },
    id: { type: String, required: false, default: "" },
    label: { required: true },
    popover_title: { required: true },
    resourceString: { required: true },
    resultsString: { required: true },
    selectMethod: { required: true },
    required: { required: false, default: false },
  },
  data() {
    console.log("searchTerm defaultValue", this.defaultValue);
    return {
      searchTerm: this.defaultValue,
      displayPopup: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  watch: {
    defaultValue: function (val, oldVal) {
      if (oldVal != val) {
        console.log("updating searchTerm", oldVal, val);
        this.searchTerm = val;
      }
    },
  },
  methods: {
    shouldAddResource() {
      return this.available_resources.length == 0 && this.searchTerm.length > 0;
    },
    searchResource: debounce(
      function () {
        const filter = { search: this.searchTerm };
        this.$store.dispatch("updateFilterAndFetch", {
          resource: this.resultsString,
          endpoint: this.resourceString,
          filter: filter,
        });
      },
      200,
      { trailing: true },
    ),
    selectResource(resource) {
      this.searchTerm = resource[this.displayAttribute];
      this.$store.dispatch("clearSearchResults");
      this.selectMethod(resource);
      this.hidePopup();
    },
    showPopup() {
      this.displayPopup = true;
      this.searchResource();
    },
    hidePopup() {
      this.displayPopup = false;
    },
  },
};
</script>
