<template>
  <div class="form-group col-md-12">
    <PopOver
      v-if="displayPopup"
      :close-icon-click-action="hidePopup"
      :list-item-click-action="selectResource"
      :list-items="filteredResources"
      :list-item-display-attribute="'display_name'"
      :title="popoverTitle"
    />
    <label :for="inputId">{{ label }}</label>

    <input
      :id="inputId"
      v-model="searchTerm"
      :disabled="disableInput"
      type="text"
      class="form-control"
      autocomplete="off"
      @focus="showPopup()"
    />
  </div>
</template>

<script>
import { filter } from "lodash";
import { mapGetters } from "vuex";
import PopOver from "components/popover.vue";
export default {
  components: {
    PopOver,
  },
  props: [
    "availableResources",
    "defaultValue",
    "disableInput",
    "inputId",
    "label",
    "popoverTitle",
    "selectMethod",
  ],
  data() {
    return {
      searchTerm: this.defaultValue,
      displayPopup: false,
    };
  },
  computed: {
    filteredResources() {
      if (!this.searchTerm) {
        return this.availableResources;
      }
      return filter(this.availableResources, (item) => {
        return (
          item.id.toString().includes(this.searchTerm.toLowerCase()) ||
          item.display_name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase())
        );
      });
    },
    ...mapGetters(["isLoading"]),
  },
  watch: {
    defaultValue: function (val, oldVal) {
      if (oldVal != val) {
        this.searchTerm = val;
      }
    },
  },
  methods: {
    shouldAddResource() {
      return this.availableResources.length == 0 && this.searchTerm.length > 0;
    },
    selectResource(resource) {
      this.searchTerm = resource.display_name;
      this.selectMethod(resource);
      this.hidePopup();
    },
    showPopup() {
      this.displayPopup = true;
    },
    hidePopup() {
      this.displayPopup = false;
    },
  },
};
</script>
