<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row justify-content-between">
            <div class="col-auto">
              <h5 class="card-title">
                {{ $t("metrics.insights") }}
              </h5>
            </div>
            <div class="col-auto">
              <div class="btn-toolbar" role="toolbar">
                <div class="btn-group mr-4" role="group">
                  <select
                    v-model="view"
                    data-width="auto"
                    class="insights-view-select selectpicker"
                  >
                    <option value="customers">
                      {{ $t("metrics.customers") }}
                    </option>
                    <option value="gross_merchandise_value">
                      {{ $t("metrics.gmv") }}
                    </option>
                    <option value="tax_free_orders">
                      {{ $t("metrics.tax_free_orders") }}
                    </option>
                    ">
                  </select>
                </div>
                <div
                  class="btn-group btn-group-toggle mr-2"
                  data-toggle="buttons"
                >
                  <label class="btn btn-light active">
                    <input
                      id="country"
                      v-model="grouping"
                      type="radio"
                      name="options"
                      autocomplete="off"
                      value="country"
                      checked
                    />
                    {{ $t("metrics.country") }}
                  </label>
                  <label class="btn btn-light">
                    <input
                      id="age"
                      v-model="grouping"
                      type="radio"
                      name="options"
                      value="age"
                      autocomplete="off"
                    />
                    {{ $t("metrics.age") }}
                  </label>
                </div>
                <div
                  class="btn-group btn-group-toggle mr-2"
                  data-toggle="buttons"
                >
                  <label class="btn btn-light active">
                    <input
                      v-model="values"
                      type="radio"
                      name="values"
                      autocomplete="off"
                      value="percentage"
                      checked
                    />
                    &nbsp;%&nbsp;
                  </label>
                  <label class="btn btn-light">
                    <input
                      v-model="values"
                      type="radio"
                      name="values"
                      value="absolute"
                      autocomplete="off"
                    />
                    123
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <ul class="list-group list-group-flush">
                <li
                  v-for="item in insight.slice(0, 5)"
                  :key="item.id"
                  class="list-group-item d-flex justify-content-between"
                >
                  {{ item.icon }}&nbsp;&nbsp;&nbsp;{{ item.name }}
                  <span class="text-muted">{{ item[values] }}</span>
                </li>
              </ul>
            </div>
            <div class="col-6">
              <ul class="list-group list-group-flush">
                <li
                  v-for="item in insight.slice(5)"
                  :key="item.id"
                  class="list-group-item d-flex justify-content-between"
                >
                  {{ item.icon }}&nbsp;&nbsp;&nbsp;{{ item.name }}
                  <span class="text-muted">{{ item[values] }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    insights: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      view: "customers",
      grouping: "country",
      values: "percentage",
    };
  },
  computed: {
    insight() {
      return this.insights[this.view][this.grouping];
    },
  },
};
</script>
