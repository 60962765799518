import { pieApiFetch, checkResponseOk } from "shared/fetch_decorators";

export async function previewReturn(newReturn) {
  const response = await pieApiFetch("/order_returns/preview", {
    method: "POST",
    body: newReturn,
  });
  await checkResponseOk(response);
  return response.json();
}

export async function createReturn(newReturn) {
  const response = await pieApiFetch("/order_returns", {
    method: "POST",
    body: newReturn,
  });
  await checkResponseOk(response);
  return response.json();
}
