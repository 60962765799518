import { pieFormFetch } from "shared/fetch_decorators.js";

/**
 * @param {string} counterId
 * @param {FileList} imageFiles
 */
export const addDirectionsImages = async (counterId, imageFiles) => {
  const formData = new FormData();
  for (const file of imageFiles) {
    formData.append("images[]", file, file.name);
  }

  const options = {
    method: "POST",
    body: formData,
  };

  const response = await pieFormFetch(
    `/admin/pie_counters/${counterId}/add_directions_images`,
    options,
  );
  return response.json();
};
