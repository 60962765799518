import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["singleCategory", "bulkAssignButton", "bulkCategoryInput"];

  bulkUpdateCategories(e) {
    e.preventDefault();

    this.singleCategoryTargets.forEach((categoryInput) => {
      if (!categoryInput.disabled) {
        categoryInput.value = this.bulkCategoryInputTarget.value;
      }
    });
  }
}
