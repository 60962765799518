import $ from "jquery";

import { ExpectedRefund } from "components/expected_refund";

const initOrderFormFields = (fetchTaxFieldUpdates) => {
  const config = document.getElementById("merchant-order-main-data").dataset;
  const MIN_ORDER_TOTAL = parseFloat(config.minOrderTotal) || 0;
  const MAX_ORDER_TOTAL = parseFloat(config.maxOrderTotal);
  const CURRENCY_CODE = config.currencyCode;
  const taxFieldVisual = $("#order_tax_total_visual");
  const taxFieldHidden = $("#order_tax_total_hidden");
  const totalField = $("#order_total");
  const totalFeedback = $("#order_total_feedback");
  const expectedRefund = new ExpectedRefund();
  const editBtn = $("#edit-tax-total");

  const showTotalFeedbackError = (msg) => {
    totalField.attr("class", "form-control border-error");
    totalFeedback.attr("class", "invalid-feedback");
    showTotalFeedbackMsg(msg);
  };

  const showTotalFeedbackWarning = (msg) => {
    totalField.attr("class", "form-control border-warning");
    totalFeedback.attr("class", "invalid-feedback text-warning");
    showTotalFeedbackMsg(msg);
  };

  const showTotalFeedbackMsg = (msg) => {
    totalFeedback.html(msg);
    totalFeedback.show();
  };

  const updateTaxField = () => {
    // the tax value is based on the base value of the item.
    const value = parseFloat(totalField.val());
    const taxValue = value - value / (1 + parseFloat(config.taxPercentage));
    const parsedValue = parseFloat(taxValue).toFixed(2);
    if (parsedValue && parsedValue > 0) {
      taxFieldVisual.val(parsedValue);
      taxFieldHidden.val(parsedValue);
    } else {
      taxFieldVisual.val(0);
      taxFieldHidden.val(0);
    }
  };

  const orderTotal = () => {
    return parseFloat(totalField.val());
  };

  const checkForValidOrderTotal = () => {
    if (orderTotal() < MIN_ORDER_TOTAL) {
      showTotalFeedbackError(
        `Must be at least ${MIN_ORDER_TOTAL} ${CURRENCY_CODE} to qualify for VAT refund`,
      );
    } else if (orderTotal() > MAX_ORDER_TOTAL) {
      showTotalFeedbackWarning(`This total seems high. Is it correct?`);
    } else {
      totalFeedback.hide();
    }
  };

  totalField.on("input", function () {
    if (fetchTaxFieldUpdates()) {
      updateTaxField();
    }
    checkForValidOrderTotal();
    const taxVal = parseFloat(taxFieldVisual.val()).toFixed(2);
    const totalVal = parseFloat(totalField.val()).toFixed(2);
    expectedRefund.update(totalVal, taxVal);
  });

  taxFieldVisual.on("input", function () {
    const parsedValue = parseFloat(taxFieldVisual.val()).toFixed(2);
    const totalVal = parseFloat(totalField.val()).toFixed(2);
    taxFieldHidden.val(parsedValue);
    expectedRefund.update(totalVal, parsedValue);
  });

  editBtn.click(function (_event) {
    return taxFieldVisual.attr("disabled", false);
  });
};

export { initOrderFormFields };
