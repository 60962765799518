import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    showPreview: Boolean,
  };
  static targets = ["errorMessage", "imagePreview", "fileInput"];

  fileInputTargetConnected(target) {
    // Promise.resolve() pushes to the next tick after connection completes.
    Promise.resolve().then(() => {
      target.disabled = false;
      this.dispatch("ready");
    });
  }

  fileAdded() {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      this.imagePreviewTarget.src = e.target.result;
      this.imagePreviewTarget.style.display = "block";
    };

    const file = this.fileInputTarget.files && this.fileInputTarget.files[0];

    if (this.validateFileSize(file)) {
      reader.readAsDataURL(file);
    } else {
      this.fileInputTarget.value = "";
    }
  }

  validateFileSize(file) {
    const size = file?.size;
    if (size && size / 1024 > 10000) {
      this.errorMessageTarget.style.display = "block";
      this.imagePreviewTarget.style.display = "none";
      return false;
    } else {
      this.errorMessageTarget.style.display = "none";
      return true;
    }
  }
}
