import { Controller } from "@hotwired/stimulus";

/**
 * WeChat accounts need an Exit Port for the trip at the time of withdrawal.
 * This controller swaps from the view to input the port name to the normal
 * withdrawal summary view.
 */
export default class extends Controller {
  static targets = [
    "continueButton",
    "exitPortName",
    "exitPortNameSection",
    "refundSummarySection",
  ];

  continueButtonTargetConnected() {
    this.updateContinueButton();
  }

  updateContinueButton() {
    this.continueButtonTarget.disabled = !this.exitPortNameTarget.value;
  }

  continue() {
    this.exitPortNameSectionTarget.style.display = "none";
    this.refundSummarySectionTarget.style.display = "";
  }
}
