import { Controller } from "@hotwired/stimulus";
import {
  jpCalculateTaxFromTotal,
  convertToDecimalRate,
} from "../../shared/tax_calculator";

// This controller is used for the JP line items editing tool.
export default class extends Controller {
  static targets = [
    "quantity",
    "unitPrice",
    "totalPrice",
    "reducedTaxRate",
    "taxRatePercentage",
    "taxPercentageLabel",
    "taxIncludedInTotal",
    "tax",
    "taxOverriden",
  ];

  get quantity() {
    return Number(this.quantityTarget.value);
  }

  get unitPrice() {
    return Number(this.unitPriceTarget.value);
  }

  get totalPrice() {
    return this.quantity * this.unitPrice;
  }

  get taxRate() {
    return convertToDecimalRate(this.taxRatePercentageTarget.value);
  }

  get tax() {
    return jpCalculateTaxFromTotal(
      this.totalPrice,
      this.taxRate,
      this.taxIncludedInTotalTarget.checked,
    );
  }

  get taxOverriden() {
    return this.taxOverridenTarget.checked;
  }

  connect() {
    /*
     * These are the core tax values in Japan. Generally the tax rate for goods
     * and services is 10% of the total. Consumables have an 8% tax rate.
     */
    this.reducedTaxRate = "8";
    this.generalTaxRate = "10";
  }

  update() {
    this.totalPriceTarget.value = this.totalPrice;

    this.taxPercentageLabelTarget.innerText =
      this.taxRatePercentageTarget.value;

    if (!this.taxOverriden) {
      this.taxTarget.value = this.tax;
    }

    // We dispatch an event when the form is updated
    // so anything that relies on these calculations can
    // follow along
    this.dispatch("totalsUpdated");
  }

  toggleTaxRate() {
    if (this.reducedTaxRateTarget.checked) {
      this.taxRatePercentageTarget.value = this.reducedTaxRate;
    } else {
      this.taxRatePercentageTarget.value = this.generalTaxRate;
    }
    this.update();
  }

  toggletaxOverriden() {
    if (this.taxOverriden) {
      this.taxTarget.readOnly = false;
    } else {
      this.taxTarget.readOnly = true;
      this.update();
    }
  }
}
