<template>
  <span :class="[needsClass()]" :title="needsWhatPhrase()">
    {{ needsHowMuch() }}
  </span>
</template>
<script>
import { each } from "lodash";
export default {
  props: ["needs"],
  methods: {
    needsClass() {
      const have = this.haveNeeds();
      const total = this.totalNeeds();
      const havePercent = (have / total) * 100;
      if (havePercent <= 50) {
        return "text-danger";
      }
      if (havePercent <= 80) {
        return "text-warning";
      }
      if (havePercent > 80) {
        return "text-success";
      }
    },
    needsWhatPhrase() {
      if (this.needsWhat().length == 0) {
        return "Ready to go!";
      }
      return `Needs: ${this.needsWhat().join(", ")}`;
    },
    needsWhat() {
      const needs_phrase = [];
      each(this.needs, function (value, key) {
        if (!value) {
          needs_phrase.push(key);
        }
      });
      return needs_phrase;
    },
    needsHowMuch() {
      return `${this.haveNeeds()} / ${this.totalNeeds()}`;
    },
    haveNeeds() {
      const total = this.totalNeeds();
      const missing = this.needsWhat().length;
      return total - missing;
    },
    totalNeeds() {
      return Object.keys(this.needs).length;
    },
  },
};
</script>
<style scoped></style>
