<template>
  <div class="popover popover__selector">
    <div class="popover__title">
      {{ title }}
      <Loader v-if="isLoading" width="15px" height="15px"></Loader>
      <div class="resource__remove" @click="closeIconClickAction()">
        <span class="icon__close"> </span>
      </div>
    </div>
    <ul class="popover__list">
      <li
        v-for="resource in listItems"
        :key="resource.id"
        class="popover__item"
        @click="dispatchListItemClickAction(resource)"
      >
        {{ resource[listItemDisplayAttribute] }}
      </li>
    </ul>
  </div>
</template>

<script>
import Loader from "./loader.vue";
import { debounce } from "lodash";

export default {
  components: { Loader },
  props: [
    "listItems",
    "listItemClickAction",
    "listItemDisplayAttribute",
    "closeIconClickAction",
    "title",
    "isLoading",
  ],
  data() {
    return {};
  },
  methods: {
    dispatchListItemClickAction: debounce(
      function (resource) {
        this.listItemClickAction(resource);
      },
      200,
      {
        leading: true,
        trailing: false,
      },
    ),
  },
};
</script>

<style src="stylesheets/admin/popover-selector.css" scoped></style>
