import $ from "jquery";
import Cookies from "js-cookie";

if (!Cookies.get("acceptCookies")) {
  $(".cookiealert").addClass("show");
}
$(".acceptcookies").on("click", function () {
  Cookies.set("acceptCookies", true, {
    expires: 60,
  });
  $(".cookiealert").removeClass("show");
});
