<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{ title }}</h4>
          <div class="row">
            <div class="col-12">
              <table class="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th
                      v-for="value in attributes"
                      :class="headerClass(value.query)"
                      scope="col"
                      @click="changeOrderBy(value.query)"
                    >
                      {{ value.header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :class="item.bg_class">
                    <td
                      v-for="(value, key) in attributes"
                      v-if="key !== 'link'"
                      :title="tableTitle(item, key)"
                      :class="value.query + '_data'"
                    >
                      {{ tableValue(item, key) }}
                    </td>
                    <td
                      v-for="(value, key) in attributes"
                      v-if="key === 'link'"
                      :title="tableTitle(item, key)"
                    >
                      <Needs v-if="item.needs" :needs="item.needs" />
                      <a v-if="item.link" :href="item[key]" data-method="get"
                        >></a
                      >
                      <router-link
                        v-if="!item.link"
                        :to="{
                          name: `${resourceString}_show`,
                          params: { id: item.id },
                        }"
                        >></router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-5">
              Showing items {{ startingRecord() }} to {{ endingRecord() }}
              <span v-if="total > 0"> of {{ total }} entries</span>
            </div>
            <div class="col-sm-12 col-md-7 align-right">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li
                    :class="['page-item', currentPage === 1 ? 'disabled' : '']"
                  >
                    <a class="page-link" @click="changePage(currentPage - 1)"
                      >Previous</a
                    >
                  </li>
                  <li :class="['page-item']">
                    <a class="page-link" @click="changePage(1)">First</a>
                  </li>
                  <li
                    v-for="page in pages"
                    v-if="truncatePages(page)"
                    class="page-item"
                  >
                    <a
                      :class="[
                        'page-link',
                        page === currentPage ? 'current-page' : '',
                      ]"
                      @click="changePage(page)"
                      >{{ page }}</a
                    >
                  </li>
                  <li :class="['page-item']">
                    <a class="page-link" @click="changePage(pages)">Last</a>
                  </li>
                  <li
                    :class="[
                      'page-item',
                      currentPage === pages ? 'disabled' : '',
                    ]"
                  >
                    <a class="page-link" @click="changePage(currentPage + 1)"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Needs from "./table_needs.vue";

export default {
  components: {
    Needs,
  },
  props: [
    "title",
    "attributes",
    "items",
    "pages",
    "total",
    "currentPage",
    "from",
    "to",
    "orderBy",
    "resourceString",
    "changePage",
    "updateOrderBy",
  ],
  methods: {
    startingRecord() {
      if (this.from !== undefined) {
        return this.from;
      }

      return (this.currentPage - 1) * 10 + 1;
    },
    endingRecord() {
      if (this.to !== undefined) {
        return this.to;
      }

      const lastRecord = this.currentPage * 10;
      if (lastRecord > this.total) {
        return this.total;
      }
      return lastRecord;
    },
    headerClass(attribute) {
      let sortAttribute = "";
      if (attribute === this.orderBy.attribute) {
        sortAttribute = "sorting_" + this.orderBy.direction;
      }
      return [sortAttribute, attribute + "_header"];
    },
    changeOrderBy(attribute) {
      const newOrderBy = {
        attribute: attribute,
      };
      if (this.orderBy.attribute === attribute) {
        newOrderBy["direction"] =
          this.orderBy.direction === "desc" ? "asc" : "desc";
      } else {
        newOrderBy["direction"] = "desc";
      }
      this.updateOrderBy(newOrderBy);
    },
    truncatePages(page) {
      if (page <= this.currentPage + 2 && page >= this.currentPage - 1) {
        return true;
      }
      if (page >= this.pages - 2 && this.currentPage <= 3) {
        return true;
      }
      if (page <= 3 && this.currentPage >= this.pages - 2) {
        return true;
      }

      return false;
    },
    tableValue(item, key) {
      // Generally has an abbreviation and a hover
      if (key === "status" || key === "country") {
        return item[key][0];
      }
      return item[key];
    },
    tableTitle(item, key) {
      // Generally has an abbreviation and a hover
      if (key === "status" || key === "country") {
        return item[key][1];
      }

      return item[key];
    },
  },
};
</script>

<style scoped>
.current-page {
  font-weight: bold;
}
td {
  min-width: 1em;
}
</style>
