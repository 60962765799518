import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm.js";

import { renderOrdersTable } from "merchant/orders_table";
import renderLineItemSalesTable from "merchant/line_item_sales_table";
import Trends from "merchant/dashboard/trends.vue";
import Insights from "merchant/dashboard/insights.vue";
import { initVueI18n } from "shared/vue_i18n.js";

export default class extends Controller {
  connect() {
    const orderMainData = document.getElementById(
      "merchant-orders-index-main-data",
    ).dataset;
    const config = JSON.parse(orderMainData.dashboardConfig);
    renderOrdersTable(config.ordersTableConfig);
    renderLineItemSalesTable(config.lineItemSalesTableConfig);

    const trends = JSON.parse(orderMainData.trends);
    const trendsElement = document.getElementById("trends");

    new Vue({
      el: "#trends",
      components: { Trends },
      data: {
        trends: trends,
      },
      template: '<Trends :trends="trends" />',
      i18n: initVueI18n(trendsElement),
    });

    const insights = JSON.parse(orderMainData.insights);
    const insightsElement = document.getElementById("insights");

    new Vue({
      el: "#insights",
      components: { Insights },
      data: {
        insights: insights,
      },
      template: '<Insights :insights="insights" />',
      i18n: initVueI18n(insightsElement),
    });
  }
}
