import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

/*
 * Apple requires a script tag be added to the page where the button is used.
 * Refer to documentation here https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
 * This script is used to render the button within their design guidelines
 * and adds AppleID to the global object space
 * Some AppleID global events are registered on the
 * controller that handles login.
 */
export default class extends Controller {
  static values = {
    clientId: String,
  };
  static targets = ["tokenField", "form"];

  connect() {
    if (typeof AppleID === "undefined") {
      return;
    }

    AppleID.auth.init({
      clientId: this.clientIdValue,
      // This needs to be configured on apple's client app UI
      redirectURI: window.location.href,
      scope: "email",
      usePopup: true,
    });

    const btn = document.getElementById("appleid-signin");
    if (btn) {
      btn.disabled = false;
    }
  }

  async submitFormWithToken(event) {
    console.debug(event);
    this.tokenFieldTarget.value = event.detail.authorization.id_token;
    /*
     * This allows Turbo to handle any returned errors rather than
     * reloading the whole page. We could do this.element.submit()
     * but then errors need to be handled differently. However, any
     * page we Turbo navigate to must support Turbo or have
     * `turbo_page_requires_reload`.
     */
    Turbo.navigator.submitForm(this.element);
  }

  async logError(event) {
    console.error(event);
  }
}
