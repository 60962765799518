import $ from "jquery";

/**
 * This method converts options.body from an Object to query parameters for
 * GET requests. This is useful when options.body contains values with arrays.
 * Rails expects arrays to be sent as repeated query parameters like this:
 * ?key[]=value1&key[]=value2
 * @example
 * pieApiFetch("/admin/orders/query", {
 *   body: { status: ["requested", "not_requested"] }
 * });
 * // becomes
 * pieApiFetch("/admin/orders/query?status[]=requested&status[]=not_requested");
 *
 * @param {string} uri
 * @param {Object} options
 * @return {string} uri
 */
function convertGetBodyObjectToQueryParams(uri, options) {
  const method = options.method || "GET";
  if (method !== "GET") {
    return uri;
  }
  if (!options.body) {
    return uri;
  }
  if (uri.includes("?")) {
    throw new Error("URI already contains query parameters");
  }

  const params = new URLSearchParams();
  for (const key of Object.keys(options.body)) {
    const value = options.body[key];
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(`${key}[]`, v));
    } else if (value !== undefined && value !== null && value !== "") {
      params.append(key, value);
    }
  }
  uri += "?" + params.toString();
  delete options.body;
  return uri;
}

/**
 * Decorates the given fetch function to automatically apply JSON headers and
 * JSON.stringify to body data to all requests.
 * @param {Function} fetch
 * @return {Function}
 */
function jsonFetchFn(fetch) {
  return (uri = "", options = {}) => {
    options.headers = options.headers || {};
    options.headers["Content-Type"] = "application/json";
    options.headers["Accept"] = "application/json";
    uri = convertGetBodyObjectToQueryParams(uri, options);
    options.body = JSON.stringify(options.body);
    return fetch(uri, options);
  };
}

function csrfProtectedFetchFn(fetch) {
  return (uri = "", options = {}) => {
    options.headers = options.headers || {};
    options.headers["X-CSRF-Token"] = $('meta[name="csrf-token"]').attr(
      "content",
    );
    return fetch(uri, options);
  };
}

/**
 * Decorates the given fetch function to automatically apply the headers needed
 * to call Pie APIs. This means JSON headers and the X-CSRF-Token, applying
 * JSON.stringify to body data, and debug logging.
 * Note the response is not parsed.
 * @param {Function} fetch
 * @return {Function}
 */
function pieApiFetchFn(fetch) {
  return jsonFetchFn(debugLoggingFetchFn(csrfProtectedFetchFn(fetch)));
}

/**
 * Decorates the given fetch function to automatically apply the headers needed
 * to call Pie APIs with multipart/form-data, the X-CSRF-Token, and debug
 * logging.
 *
 * @param {Function} fetch
 * @return {Function}
 */
function pieFormDataApiFetchFn(fetch) {
  // NOTE(alan): The Content-Type header is not automatically set because the
  // browser does it automatically.
  // See the warning here:
  // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
  return debugLoggingFetchFn(csrfProtectedFetchFn(fetch));
}

/**
 * Decorates the given fetch function to automatically log requests and OK
 * responses at debug level and error responses at error level.
 * @param {Function} fetch
 * @return {Function}
 */
function debugLoggingFetchFn(fetch) {
  return async (uri = "", options = {}) => {
    console.debug(">", uri, options);
    const response = await fetch(uri, options);
    const body = await response.clone().text();
    if (response.ok) {
      console.debug("<", response, "body:", body);
    } else {
      console.error("<", response, "body", body);
    }
    return response;
  };
}

/**
 * Decorates the given fetch function to automatically prefix all requests with
 * the given baseUri.
 * @param {Function} fetch
 * @param {String} baseUri
 * @return {Function}
 */
function baseUriFetchFn(fetch, baseUri) {
  return (uri = "", options = {}) => {
    return fetch(baseUri + uri, options);
  };
}

async function checkResponseOk(response, defaultErrorMsg) {
  if (!response.ok) {
    throw Error(await extractErrorMessage(response, defaultErrorMsg));
  }
}

/**
 * Responses generally follow a pattern of putting error messages in the
 * response as JSON. This looks for the following patterns:
 * { errors: ['errMsg1', 'errMsg2'] }
 * or
 * { error: 'errMsg' }
 * or falls back to defaultMsg.
 * @param {Response} response
 * @param {string} defaultMsg
 * @return {string}
 */
async function extractErrorMessage(response, defaultMsg) {
  let msg = defaultMsg;
  try {
    const errorJson = await response.clone().json();
    if (errorJson.errors) {
      msg = errorJson.errors.map((error) => error.message).join("<br/>");
    } else if (errorJson.error) {
      msg = errorJson.error;
    }
  } catch (e) {
    // If the response is not JSON it was likely not a validation error
    // and the defaultMessage will suffice. There is not another
    // message to show.
    console.debug(e);
  }
  return msg;
}

const pieApiFetch = pieApiFetchFn(fetch);
const pieFormFetch = pieFormDataApiFetchFn(fetch);

export {
  jsonFetchFn,
  baseUriFetchFn,
  pieApiFetch,
  pieFormFetch,
  checkResponseOk,
  extractErrorMessage,
};
