import I18n from "i18n-js";

/**
 * dataTableLanguage is the configuration for the DataTable language option.
 * See https://datatables.net/manual/i18n
 */
export const dataTableLanguage = {
  emptyTable: I18n.t("tables.empty"),
  info: I18n.t("tables.info"),
  infoEmpty: I18n.t("tables.info_empty"),
  infoFiltered: I18n.t("tables.info_filtered"),
  lengthMenu: I18n.t("tables.length_menu"),
  loadingRecords: I18n.t("tables.loading_records"),
  processing: I18n.t("tables.processing"),
  search: I18n.t("tables.search"),
  zeroRecords: I18n.t("tables.zero_records"),
  paginate: {
    first: I18n.t("tables.paginate.first"),
    last: I18n.t("tables.paginate.last"),
    next: I18n.t("tables.paginate.next"),
    previous: I18n.t("tables.paginate.previous"),
  },
  aria: {
    sortAscending: I18n.t("tables.aria.sort_ascending"),
    sortDescending: I18n.t("tables.aria.sort_descending"),
  },
  buttons: {
    colvis: I18n.t("buttons.column_visibility"),
    csv: I18n.t("buttons.download_csv"),
    excel: I18n.t("buttons.download_excel"),
  },
  searchBuilder: {
    add: I18n.t("search_builder.add"),
    button: {
      0: I18n.t("search_builder.button.zero"),
      _: I18n.t("search_builder.button._"),
    },
    clearAll: I18n.t("search_builder.clear_all"),
    condition: I18n.t("search_builder.condition"),
    conditions: {
      array: {
        contains: I18n.t("search_builder.conditions.array.contains"),
        empty: I18n.t("search_builder.conditions.array.empty"),
        equals: I18n.t("search_builder.conditions.array.equals"),
        not: I18n.t("search_builder.conditions.array.not"),
        notEmpty: I18n.t("search_builder.conditions.array.not_empty"),
        without: I18n.t("search_builder.conditions.array.without"),
      },
      date: {
        after: I18n.t("search_builder.conditions.date.after"),
        before: I18n.t("search_builder.conditions.date.before"),
        between: I18n.t("search_builder.conditions.date.between"),
        empty: I18n.t("search_builder.conditions.date.empty"),
        equals: I18n.t("search_builder.conditions.date.equals"),
        not: I18n.t("search_builder.conditions.date.not"),
        notBetween: I18n.t("search_builder.conditions.date.not_between"),
        notEmpty: I18n.t("search_builder.conditions.date.not_empty"),
      },
      number: {
        between: I18n.t("search_builder.conditions.number.between"),
        empty: I18n.t("search_builder.conditions.number.empty"),
        equals: I18n.t("search_builder.conditions.number.equals"),
        gt: I18n.t("search_builder.conditions.number.gt"),
        gte: I18n.t("search_builder.conditions.number.gte"),
        lt: I18n.t("search_builder.conditions.number.lt"),
        lte: I18n.t("search_builder.conditions.number.lte"),
        not: I18n.t("search_builder.conditions.number.not"),
        notBetween: I18n.t("search_builder.conditions.number.not_between"),
        notEmpty: I18n.t("search_builder.conditions.number.not_empty"),
      },
      string: {
        contains: I18n.t("search_builder.conditions.string.contains"),
        empty: I18n.t("search_builder.conditions.string.empty"),
        endsWith: I18n.t("search_builder.conditions.string.ends_with"),
        equals: I18n.t("search_builder.conditions.string.equals"),
        not: I18n.t("search_builder.conditions.string.not"),
        notContains: I18n.t("search_builder.conditions.string.not_contains"),
        notEmpty: I18n.t("search_builder.conditions.string.not_empty"),
        notEndsWith: I18n.t("search_builder.conditions.string.not_ends_with"),
        notStartsWith: I18n.t(
          "search_builder.conditions.string.not_starts_with",
        ),
        startsWith: I18n.t("search_builder.conditions.string.starts_with"),
      },
    },
    data: I18n.t("search_builder.data"),
    deleteTitle: I18n.t("search_builder.delete_title"),
    leftTitle: I18n.t("search_builder.left_title"),
    logicAnd: I18n.t("search_builder.logic_and"),
    logicOr: I18n.t("search_builder.logic_or"),
    rightTitle: I18n.t("search_builder.right_title"),
    title: {
      0: I18n.t("search_builder.title.zero"),
      _: I18n.t("search_builder.title._"),
    },
    value: I18n.t("search_builder.value"),
  },
};
