import { pieApiFetch, baseUriFetchFn } from "shared/fetch_decorators";

export class WisePieApi {
  constructor(baseUri) {
    this.fetch = baseUriFetchFn(pieApiFetch, baseUri);
  }

  async currencyRequirements(currencyId) {
    const response = await this.fetch(
      `/requirements?currency_id=${currencyId}`,
      { method: "GET" },
    );
    await this.checkResponseOk(response, "Failed to get currency requirements");
    return response.json();
  }

  async accountRequirements({ currencyId, type, details }) {
    const response = await this.fetch("/requirements", {
      method: "POST",
      body: {
        currency_id: currencyId,
        params: {
          type: type,
          details: details,
        },
      },
    });
    await this.checkResponseOk(response, "Failed to get account requirements");
    return response.json();
  }

  async createAccount({ currencyId, accountHolderName, type, details }) {
    const response = await this.fetch("", {
      method: "POST",
      body: {
        currency_id: currencyId,
        params: {
          accountHolderName: accountHolderName,
          type: type,
          details: details,
        },
      },
    });
    await this.checkResponseOk(response, "Account creation failed");
    return response.json();
  }

  async deleteAccount() {
    const response = await this.fetch("", { method: "DELETE" });
    await this.checkResponseOk(response, "Failed to delete account");
    return response.json();
  }

  async checkResponseOk(response, defaultErrorMsg) {
    if (!response.ok) {
      throw Error(await this.extractErrorMessage(response, defaultErrorMsg));
    }
  }

  async extractErrorMessage(response, defaultMsg) {
    let msg = defaultMsg;
    try {
      const errorJson = await response.clone().json();
      if (errorJson.errors) {
        msg = errorJson.errors.map((error) => error.message).join("<br/>");
      } else if (errorJson.error) {
        msg = errorJson.error;
      }
    } catch (e) {
      // If the response is not JSON it was likely not a validation error
      // and the defaultMessage will suffice. There is not another
      // message to show.
      console.debug(e);
    }
    return msg;
  }
}
