import $ from "jquery";

const call = async function (path, value, method) {
  return $.ajax(path, {
    data: value,
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    dataType: "json",
    method: method,
  });
};

async function fetchPosOrderData(receiptId) {
  const params = {
    order: {
      entered_receipt_id: receiptId,
    },
  };
  const data = await call("/integrations/fetch_order_data", params, "get");
  return data?.order;
}
export { fetchPosOrderData };
