import { Controller } from "@hotwired/stimulus";

// This controller is used for the JP line items editing tool.
export default class extends Controller {
  static targets = [
    "category",
    "reducedTaxRate",
    "isConsumable",
    "consumableInputs",
    "isAlcohol",
    "alcoholInputs",
    "alcoholInput",
  ];

  connect() {
    this.categoryTarget.focus();
  }

  toggleConsumableInputs() {
    if (this.isConsumableTarget.checked) {
      this.enableConsumableInputs();
    } else {
      this.disableConsumableInputs();
    }
  }

  enableConsumableInputs() {
    this.consumableInputsTargets.forEach((element) => {
      element.classList.remove("d-none");
    });
  }

  disableConsumableInputs() {
    this.consumableInputsTargets.forEach((element) => {
      element.classList.add("d-none");
    });

    this.isAlcoholTarget.checked = false;
    this.reducedTaxRateTarget.checked = false;
    this.disableAlcoholInputs();
  }

  toggleAlcoholInputs() {
    if (this.isAlcoholTarget.checked) {
      this.enableAlcoholInputs();
    } else {
      this.disableAlcoholInputs();
    }
  }

  enableAlcoholInputs() {
    this.alcoholInputsTarget.classList.remove("d-none");
    this.alcoholInputTargets.forEach((element) => {
      element.disabled = false;
      element.required = true;
    });
  }

  disableAlcoholInputs() {
    this.alcoholInputsTarget.classList.add("d-none");
    this.alcoholInputTargets.forEach((element) => {
      element.disabled = true;
      element.required = false;
    });
  }
}
