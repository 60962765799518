export const resourceDisplayAttributes = {
  properties: {
    flags: {
      header: "",
      query: "properties.flags",
    },

    display_name: {
      header: "Name - Address",
      query: "properties.name",
    },
    merchant_count: {
      header: "Merchants",
      query: "merchant_count",
    },
    last_order_placed_at: {
      header: "Last Order",
      query: "last_order_placed_at",
    },
    ninety_day_tax_total: {
      header: "90 Day Tax Total",
      query: "ninety_day_tax_total",
    },
    country: {
      header: "Country",
      query: "country_iso",
    },
    created_at: {
      header: "Created At",
      query: "properties.created_at",
    },
    link: {
      header: "",
      query: null,
    },
  },
  merchants: {
    merchant_name: {
      header: "Name",
      query: "merchants.merchant_name",
    },
    login: {
      header: "Login",
      query: "merchants.login",
    },
    properties_count: {
      header: "Properties",
      query: "store_count",
    },
    created_at: {
      header: "Created At",
      query: "merchants.created_at",
    },
    link: {
      header: "Actions",
      query: null,
    },
  },
  pie_counters: {
    name: {
      header: "Name",
      query: "pie_counters.name",
    },
    link: {
      header: "",
      query: null,
    },
  },
};
