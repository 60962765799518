import $ from "jquery";

import { fetchPosOrderData } from "shared/integrations_service";

const initPosIntegrationForm = async (disableTaxFieldEditingCb) => {
  const config = document.getElementById("merchant-order-main-data").dataset;
  const translations = JSON.parse(config.translations);
  const integrationConfig = JSON.parse(config.integration);

  if (!integrationConfig.has_integration) {
    return;
  }

  const extra_integration_fields = $("#extra-integration-fields");
  const find_order_btn = $("#integration-find-order");
  const find_order_query_input = $("#find-order-query-input");
  const entered_receipt_id = $("#order_entered_receipt_id");
  const error_alert = $("#error-alert");
  const send_to_email = $("#order_send_to_email");
  const tax_field_visual = $("#order_tax_total_visual");
  const tax_field_hidden = $("#order_tax_total_hidden");
  const total_field_visual = $("#order_total");
  const total_field_hidden = $("#order_total_hidden");
  const tips_for_success = $("#tips-for-success");
  const show_fields_class = "order__integration-fields--show";
  const loader = $("#integration-loader");
  const recent_order_container = $("#recent-order-container");
  const manually_create = $("#manual-create-link");
  const manual_container = $("#manual-create-container");
  const edit_receipt = $("#edit-receipt-number");
  const edit_total = $("#edit-total");
  const edit_tax_total = $("#edit-tax-total");
  const success_alert = $("#success-alert");
  const external_id = $("#order_external_id");
  const external_system = $("#order_external_system");
  const integration_search_form = $("#integration-search-form");

  function showManualForm() {
    loader.hide();
    manual_container.hide();
    total_field_visual.attr("disabled", false);
    integration_search_form.hide();
    tips_for_success.show();
    extra_integration_fields.addClass(show_fields_class);
  }

  function useOrder(orderData) {
    manual_container.hide();
    error_alert.hide();
    success_alert.hide();
    loader.hide();
    recent_order_container.hide();
    edit_receipt.show();
    entered_receipt_id.attr("readonly", true);
    if (!orderData) {
      error_alert.html("Something went wrong: Please try again.");
      error_alert.show();
      return;
    }

    setOrderFormData(orderData);
    disableTaxFieldEditingCb();
    // trigger revalidation of total and expected refund update
    total_field_visual.trigger("input");
    success_alert.html(translations.double_check);
    success_alert.show();
    tips_for_success.show();
    // Show the order form
    extra_integration_fields.addClass(show_fields_class);
  }

  // TODO(alan): Turn all this into a Stimulus form
  window.pie = window.pie || {};
  window.pie.useOrder = useOrder;

  function setOrderFormData(orderData) {
    entered_receipt_id.val(orderData.entered_receipt_id);
    external_id.val(orderData.external_id);
    external_system.val(orderData.external_system);
    send_to_email.val(orderData.send_to_email);
    total_field_visual.val(orderData.total);
    total_field_hidden.val(orderData.total);
    tax_field_hidden.val(orderData.tax_total);
    tax_field_visual.val(orderData.tax_total);
  }

  edit_receipt.on("click", function (_event) {
    entered_receipt_id.attr("readonly", false);
  });
  edit_total.on("click", function (_event) {
    total_field_visual.attr("disabled", false);
  });
  edit_tax_total.on("click", function (_event) {
    tax_field_visual.attr("disabled", false);
  });
  total_field_visual.keyup(function () {
    total_field_hidden.val(total_field_visual.val());
  });
  manually_create.on("click", function (event) {
    event.preventDefault();
    showManualForm();
  });

  function showErrorMessage(error) {
    loader.hide();
    if (error && error.responseJSON && error.responseJSON.message != null) {
      const message = error.responseJSON.message;
      let url = "";
      if (
        error.responseJSON.order_url != null &&
        error.responseJSON.order_url.length > 0
      ) {
        url = `
          <a data-method="get" href="${error.responseJSON.order_url}">
            ${translations.view_order}
          </a>`;
      }
      error_alert.html(message + " " + url);
    } else {
      error_alert.html(`Something went wrong: ${error.statusText}`);
    }
    error_alert.show();
  }

  find_order_btn.on("click", async function (_event) {
    console.log("find_order_btn click");
    error_alert.hide();
    success_alert.hide();
    loader.show();
    const receipt_id = find_order_query_input.val();
    if (
      typeof receipt_id !== "undefined" &&
      receipt_id !== null &&
      receipt_id.length === 0
    ) {
      find_order_query_input.addClass("is-invalid");
      loader.hide();
      return;
    } else {
      find_order_query_input.removeClass("is-invalid");
    }
    try {
      $("order-form-submit").attr("disabled", true);
      const orderData = await fetchPosOrderData(receipt_id);
      useOrder(orderData);
    } catch (error) {
      console.error("fetchPosOrderData error", error);
      showErrorMessage(error);
    } finally {
      $("order-form-submit").attr("disabled", false);
    }
  });
  console.log("find_order_btn click handler added");

  if (find_order_query_input.val()) {
    find_order_btn.click();
  }

  // This function is called by Sitoo when barcode scanner is used.
  //
  // From Sitoo docs:
  //
  // "If the web app implements a global function "sitooPosScannerEvent(text)
  // bool", any scanner input is forwarded to that function. Note that the
  // function should return as fast as possible in order to have a good user
  // experience. The return value should be true if the value was accepted,
  // which results in a good beep on the scanner. If false is returned then
  // a negative beep is issued."
  //
  window.sitooPosScannerEvent = (text) => {
    if (typeof text !== "undefined" && text !== null && text.length !== 0) {
      // Barcode scanner returns receipt numbers prefixed with digits but
      // the API they all start with letters so let's remove leading digits.
      const receiptId = text.replace(/^\d*/, "");
      find_order_query_input.val(receiptId);
      find_order_btn.trigger("click");
      return true;
    }

    return false;
  };

  total_field_visual.attr("disabled", true);
  if (!integrationConfig.active) {
    showManualForm();
    return;
  }
  tips_for_success.hide();
};

export { initPosIntegrationForm };
