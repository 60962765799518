import { Controller } from "@hotwired/stimulus";

import ReturnsApp from "merchant/returns_app.vue";
import Vue from "vue/dist/vue.esm.js";
import { initVueI18n } from "shared/vue_i18n.js";

export default class extends Controller {
  connect() {
    const order = JSON.parse(this.element.dataset.order);
    console.log("Parsed order", order);
    new Vue({
      el: this.element,
      components: { ReturnsApp },
      data: {
        order: order,
      },
      template: '<ReturnsApp :order="order" />',
      i18n: initVueI18n(this.element),
    });
  }
}
