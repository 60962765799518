import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import { FakeTelephoneNumberField } from "components/fake_telephone_number_field";
import { TelephoneNumberField } from "components/telephone_number_field";
import { sendOrderToCustomer } from "shared/order_notifications_api";

/**
 * This file controls the popups shown on the Order show page. There are a few
 * different cases:
 * 1. For order creation through the normal web-UI the param "new_order=true" is
 *    passed and the order explanation popup is shown.
 * 2. For order creation via Shopify "new_order=true" is NOT passed, but
 *    show_email_popup is set to true. After the email is collected, the
 *    order explanation popup is shown. When that is closed the page is
 *    reloaded to show the updated email.
 */
export default class extends Controller {
  connect() {
    const SMS_FIELD_ID = "order_send_to_sms";
    const SMS_FIELD_ID_ON_POPUP = "send-to-sms-popup";

    const initializeSmsFields = (id) => {
      return document.getElementById(id)
        ? new TelephoneNumberField(id)
        : new FakeTelephoneNumberField();
    };

    const smsFieldOnForm = initializeSmsFields(SMS_FIELD_ID);
    const smsFieldOnPopup = initializeSmsFields(SMS_FIELD_ID_ON_POPUP);

    const shouldShowOneLastThingModal = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const isNewOrder = urlParams.get("new_order") === "true";
      const hasAtLeastOneTip =
        document.querySelectorAll("#one-last-thing-modal li").length > 0;
      return isNewOrder && hasAtLeastOneTip;
    };

    const showOneLastThingModal = function () {
      $("#one-last-thing-modal").modal("show");
      // Remove the "new_order=true" param so any reloads don't show the popup
      // again. (E.g. adding a comment).
      window.history.replaceState({}, document.title, window.location.pathname);
    };

    const hideErrorsAndReload = function () {
      $(".contact-error").hide();
      $("#collect-email").modal("hide");
      window.location.reload();
    };

    const updateErrorHtmlAndShow = function (message, errorClass) {
      $(errorClass).html(message);
      $(errorClass).show();
    };

    const updateAndSendOrderToContact = async function (
      orderId,
      email,
      smsNumber,
    ) {
      try {
        await sendOrderToCustomer(orderId, email, smsNumber);
        hideErrorsAndReload();
      } catch (error) {
        updateErrorHtmlAndShow(error.message, ".contact-error");
      }
    };

    const clearContactFormErrors = function () {
      $(".contact-error").hide();
      $(`#${SMS_FIELD_ID}`).removeClass("is-invalid");
      $(`#${SMS_FIELD_ID_ON_POPUP}`).removeClass("is-invalid");
      $(".tel-send-to-sms").removeClass("is-invalid");
      $(".tel-send-to-sms-popup").removeClass("is-invalid");
      $("#order_send_to_email").removeClass("is-invalid");
      $("#send-to-email-popup").removeClass("is-invalid");
    };

    const addPopupValidationErrors = function (email, sms) {
      if (sms.length == 0 && email.length == 0) {
        $(".tel-send-to-sms-popup").addClass("is-invalid");
        $(`#${SMS_FIELD_ID_ON_POPUP}`).addClass("is-invalid");
        $("#send-to-email-popup").addClass("is-invalid");
        return true;
      }
    };

    const addFormValidationErrors = function (email, sms) {
      if (sms.length == 0 && email.length == 0) {
        $(".tel-send-to-sms").addClass("is-invalid");
        $(`#${SMS_FIELD_ID}`).addClass("is-invalid");
        $("#order_send_to_email").addClass("is-invalid");
        return true;
      }
    };

    $(".contact-form").on("submit", async function (event) {
      event.preventDefault();

      const orderId = $("#order-id").val();
      const email =
        $("#order_send_to_email").val() || $("#send-to-email-popup").val();
      const smsNumber =
        smsFieldOnForm.getNumber() || smsFieldOnPopup.getNumber();

      clearContactFormErrors();

      if ($("#collect-email").hasClass("show")) {
        if (addPopupValidationErrors(email, smsNumber)) {
          return;
        }

        // validate sms number in the popup
        if (smsNumber.length > 0 && !smsFieldOnPopup.isValid()) {
          $(".tel-send-to-sms-popup").addClass("is-invalid");
          $(`#${SMS_FIELD_ID_ON_POPUP}`).addClass("is-invalid");
          return;
        }
      } else {
        if (addFormValidationErrors(email, smsNumber)) {
          return;
        }

        // validate the sms field on the form
        if (smsNumber.length > 0 && !smsFieldOnForm.isValid()) {
          $(".tel-send-to-sms").addClass("is-invalid");
          $(`#${SMS_FIELD_ID}`).addClass("is-invalid");
          return;
        }
      }

      $(".smaller-loader").show();

      try {
        await updateAndSendOrderToContact(orderId, email, smsNumber);
      } finally {
        $(".smaller-loader").hide();
      }
    });

    if (window.pie.show_email_popup) {
      $("#collect-email").modal("show");
      // The one last thing modal will be shown after the collect email modal
      // is hidden.
      $("#collect-email").on("hidden.bs.modal", () => {
        if (shouldShowOneLastThingModal()) {
          showOneLastThingModal();
        }
      });
    } else if (shouldShowOneLastThingModal()) {
      showOneLastThingModal();
    }
  }
}
