<template>
  <fieldset>
    <legend>{{ title }}</legend>
    <div
      v-for="(resourceId, index) in valuesSortedByName"
      :key="index"
      class="form-group form-row"
    >
      <button
        class="btn btn-primary form-control col-auto remove-resource-button"
        @click="onRemove(index)"
      >
        x
      </button>
      <input
        :value="resourceName(resourceId)"
        :name="`resource-ids-${resourceType}-${index}`"
        class="form-control-plaintext col"
        type="text"
        disabled
      />
      <slot :resource-id="resourceId"></slot>
    </div>
    <ResourceIdInput
      v-model="nextResourceId"
      class="form-row resource-id-input"
      :resource-type="resourceType"
      @input="onAdd"
    />
  </fieldset>
</template>
<script>
import ResourceIdInput from "components/resource_id_input.vue";
import { mapGetters } from "vuex";

/**
 * ResourceIdsInput is a component to show a modifiable list of resources based
 * on a list of resource ids.
 * The component takes a list of resourceIds and a resourceType. It shows the
 * list of names of the associated resources, input for adding another resource,
 * and buttons for removing resources. An 'input' event is emitted when the list
 * of resource ids changes with the updated list.
 *
 * It's designed to make editing lists of resource associations easy and
 * analogous to other simple input types (e.g. a text input).
 *
 * Note: Because a new list is created for each change to the resource list
 * it may not be performant for very large lists.
 *
 * Example usage:
 *
 * <ResourceIdInput
 *   v-model="myObj.associated_resource_ids"
 *   :resource-type="resourceTypes.PROPERTIES"
 * />
 *
 * @displayName ResourceIdsInput
 */
export default {
  components: {
    ResourceIdInput,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: Array,
    },
    resourceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nextResourceId: "",
    };
  },
  computed: {
    /** @return {string} */
    title() {
      return this.resourceTypeTitle(this.resourceType);
    },
    valuesSortedByName() {
      if (typeof this.value?.toSorted === "function") {
        return this.value.toSorted((a, b) =>
          this.resourceName(a) < this.resourceName(b) ? -1 : 1,
        );
      } else {
        return this.value;
      }
    },
    ...mapGetters(["getResourceById", "resourceTypeTitle"]),
  },
  methods: {
    onAdd() {
      const newValue = this.value.concat([this.nextResourceId]);
      this.$emit("input", newValue);
      this.nextResourceId = "";
    },
    onRemove(index) {
      const newValue = this.valuesSortedByName.toSpliced(index, 1);
      this.$emit("input", newValue);
    },
    resourceName(resourceId) {
      const resource = this.getResourceById(this.resourceType, resourceId);
      return resource?.display_name || resource?.name;
    },
  },
};
</script>
<style scoped>
fieldset:disabled .add-resource-button,
fieldset:disabled .remove-resource-button,
fieldset:disabled .resource-id-input {
  display: none;
}

.remove-resource-button {
  width: 38px;
}
</style>
