/*
 * Rounding mode here is consistent with Rails default rounding
 * that is currently being used on object creation. This happens
 * to match the majority of our partner stores (for now)
 */
function jpCalculateTaxFromTotal(total, taxRate, taxIncluded) {
  if (taxIncluded) {
    return calcTaxFromTotalIncludingTax(total, taxRate);
  } else {
    return calcTaxFromTotalExcludingTax(total, taxRate);
  }
}

function calcTaxFromTotalIncludingTax(total, taxRate) {
  const taxAmount = parseFloat(total / (1 + taxRate));
  return Math.round(parseFloat(total - taxAmount).toFixed(2));
}

function calcTaxFromTotalExcludingTax(total, taxRate) {
  return Math.round(parseFloat(total * taxRate).toFixed(2));
}

function convertToDecimalRate(value) {
  return Number(value) / 100;
}

export { convertToDecimalRate, jpCalculateTaxFromTotal };
