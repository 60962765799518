<template>
  <div class="show">
    <div class="row justify-content-between">
      <div class="col-6">
        <h1>Merchant</h1>
      </div>
      <div class="col-1">
        <router-link :to="{ path: '/' }"> Back </router-link>
      </div>
    </div>
    <Flash />
    <div>
      <div class="card" style="margin-bottom: 1em">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="btn-toolbar justify-content-between" role="toolbar">
                <div v-if="!newMerchant" class="btn-group" role="group">
                  <button
                    v-if="!merchant['confirmed?']"
                    type=" button"
                    class="btn btn-outline-primary"
                    @click="resendConfirmation()"
                  >
                    Resend Confirmation
                  </button>
                  <a
                    v-if="!newMerchant"
                    type=" button"
                    class="btn btn-link"
                    :href="`/merchants/${merchant.id}/dashboard`"
                    target="_blank"
                  >
                    Merchant Dashboard
                  </a>
                  <Loader
                    v-if="isLoading(resourceTypes.MERCHANTS)"
                    :width="'25px'"
                    :height="'25px'"
                  />
                </div>
                <div class="btn-group">
                  <button
                    v-if="!isEditing"
                    id="edit-button"
                    type="button"
                    class="btn btn-primary"
                    @click="sendPasswordResetEmail()"
                  >
                    Send Password Reset Email
                  </button>
                  <button
                    v-if="!isEditing"
                    id="edit-button"
                    type="button"
                    class="btn btn-primary"
                    @click="startEditing()"
                  >
                    Edit
                  </button>
                  <button
                    v-if="isEditing"
                    type="button"
                    class="btn btn-primary"
                    @click="cancelEditing()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-lg-6 col-sm-12">
              <div class="form-group col-md-12">
                <label for="merchant-name">Name *</label>
                <input
                  id="merchant-name"
                  v-model="merchant.merchant_name"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                  placeholder="Name"
                />
              </div>

              <div class="form-group col-md-12">
                <label for="merchant-email">Email *</label>
                <input
                  id="merchant-email"
                  v-model="merchant.email"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                  placeholder="Company Email"
                />
              </div>

              <div class="form-group col-md-12">
                <label for="merchant-id">ID *</label>
                <input
                  id="merchant-id"
                  v-model="merchant.id"
                  type="text"
                  :disabled="true"
                  class="form-control"
                  placeholder="ID"
                />
              </div>

              <SearchSuggestInput
                id="default-property"
                display-attribute="display_name"
                :label="'Default Property *'"
                :popover_title="'Properties'"
                :results-string="resourceTypes.SEARCHED_RESOURCE"
                :resource-string="resourceTypes.PROPERTIES"
                :available_resources="
                  resources(resourceTypes.SEARCHED_RESOURCE)
                "
                :disable-input="!isEditing"
                :default-value="
                  merchant.default_property &&
                  merchant.default_property.display_name
                "
                :select-method="updateDefaultProperty"
              />
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group col-md-12">
                <label for="merchant-login">Login *</label>
                <input
                  id="merchant-login"
                  v-model="merchant.login"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                  placeholder="Username to login"
                />
              </div>

              <div v-if="newMerchant" class="form-group col-md-12">
                <label for="merchant-password">Password *</label>
                <input
                  id="merchant-password"
                  v-model="merchant.password"
                  type="password"
                  :class="{
                    'form-control': true,
                    'is-invalid': passwordInvalid,
                  }"
                />
                <div class="invalid-feedback">
                  Password must be longer than 8 characters
                </div>
              </div>

              <div v-if="newMerchant" class="form-group col-md-12">
                <label for="merchant-password-confirmation"
                  >Password Confirmation*</label
                >
                <input
                  id="merchant-password-confirmation"
                  v-model="merchant.password_confirmation"
                  type="password"
                  :class="{
                    'form-control': true,
                    'is-invalid': passwordsMismatched,
                  }"
                />
                <div class="invalid-feedback">Passwords do not match</div>
              </div>

              <SearchSuggestInput
                id="display-currency"
                :label="'Display Currency *'"
                :popover_title="'Found Currencies'"
                :results-string="resourceTypes.SEARCHED_RESOURCE"
                :resource-string="resourceTypes.CURRENCIES"
                :available_resources="
                  resources(resourceTypes.SEARCHED_RESOURCE)
                "
                :disable-input="!isEditing"
                :default-value="merchant.currency && merchant.currency.name"
                :resource-attribute="'code'"
                :select-method="updateCurrency"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-lg-6 col-sm-12">
              <button
                v-if="isEditing && !newMerchant"
                type="button"
                class="btn btn-primary"
                @click="saveResource()"
              >
                Save
              </button>
              <button
                v-if="isEditing && newMerchant"
                id="create-new-merchant"
                type="button"
                class="btn btn-primary"
                @click="createResource()"
              >
                Create New Merchant
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!newMerchant" class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#assign-properties"
          >
            Manage Properties
          </button>
          <SearchAndAssignTable
            id="assign-properties"
            :search-resource-attributes="
              resourceAttributes(resourceTypes.PROPERTIES)
            "
            :search-resource-type="resourceTypes.PROPERTIES"
            :current-resource-id="merchant.id"
            :current-resource-type="resourceTypes.MERCHANTS"
            :change-page="changePageResource"
          />
        </div>
      </div>
      <ResourceTable
        v-if="anyResources(resourceTypes.PROPERTIES) && !newMerchant"
        id="merchant-properties"
        :attributes="resourceAttributes(resourceTypes.PROPERTIES)"
        :resource-type="resourceTypes.PROPERTIES"
        :change-page="changePage"
      />
    </div>
  </div>
</template>

<script>
import Loader from "../../components/loader.vue";
import SearchSuggestInput from "../../components/search_suggest_input.vue";
import Flash from "../../components/flash_message.vue";
import Resources from "../resources.js";
import ResourceTable from "admin/merchants/resource_table.vue";
import SearchAndAssignTable from "components/search_and_assign_table.vue";
import { mapGetters } from "vuex";

/**
 * @typedef {object} Merchant
 * @property {object} currency
 * @property {object} category
 * @property {string|undefined} password
 * @property {string|undefined} password_confirmation
 */
export default {
  components: {
    Loader,
    ResourceTable,
    SearchAndAssignTable,
    SearchSuggestInput,
    Flash,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.newMerchant = to.name == "merchants_new";
    });
  },
  data() {
    return {
      /** @type {Merchant} */
      merchant: {
        currency: { name: "" },
        category: {},
        password: undefined,
        password_confirmation: undefined,
      },
      resourceTypes: Resources,
      isEditing: this.$route.params.id ? false : true,
      newMerchant: this.$route.name == "merchants_new",
    };
  },
  computed: {
    storedMerchant() {
      return this.$store.getters.getResourceById(
        Resources.MERCHANTS,
        this.$route.params.id,
      );
    },
    passwordInvalid() {
      return (
        0 < this.merchant.password?.length && this.merchant.password?.length < 8
      );
    },
    passwordsMismatched() {
      return this.merchant.password !== this.merchant.password_confirmation;
    },
    ...mapGetters([
      "anyResources",
      "isLoading",
      "resourceAttributes",
      "resourceOrderBy",
      "resourcePages",
      "resourceRoles",
      "resources",
      "resourcesTotal",
      "resourcesCurrentPage",
    ]),
  },
  watch: {
    storedMerchant: {
      handler: function (newData, oldData) {
        console.log(newData, oldData);
        this.merchant = newData;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.loadData();
  },
  methods: {
    changePage(page) {
      this.$store.dispatch("changePage", {
        resource: Resources.PROPERTIES,
        page: page,
      });
    },
    changePageResource(page, resource) {
      this.$store.dispatch("changePage", { resource: resource, page: page });
    },
    startEditing() {
      this.isEditing = true;
    },
    cancelEditing() {
      this.isEditing = false;
    },
    saveResource() {
      this.isEditing = false;
      this.$store.dispatch("updateResource", {
        endpoint: `${Resources.MERCHANTS}/${this.merchant.id}`,
        resource: Resources.MERCHANTS,
        payload: { merchant: this.merchant },
      });
    },
    createResource() {
      if (this.merchant.password === "") {
        this.merchant.password = undefined;
      }
      if (this.merchant.password_confirmation === "") {
        this.merchant.password_confirmation = undefined;
      }
      if (this.merchant.password != this.merchant.password_confirmation) {
        return;
      }

      this.isEditing = false;
      this.$store.dispatch("createResource", {
        endpoint: `${Resources.MERCHANTS}`,
        resource: "merchants",
        singularResource: "merchant",
        payload: { merchant: this.merchant },
      });
    },
    updateCurrency(newCurrency) {
      this.merchant.currency_id = newCurrency.id;
    },
    updateDefaultProperty(newProperty) {
      this.merchant.default_property_id = newProperty.id;
    },
    loadData() {
      if (this.$route.params.id) {
        return this.$store
          .dispatch("fetchResource", {
            resource: Resources.MERCHANTS,
            id: this.$route.params.id,
          })
          .then(() => {
            return this.$store.dispatch("updateFilterAndFetch", {
              resource: Resources.PROPERTIES,
              endpoint: Resources.PROPERTIES,
              filter: { merchant_id: this.$route.params.id },
            });
          });
      }
    },
    resendConfirmation() {
      this.$store.dispatch("resendConfirmation", { id: this.merchant.id });
    },
    sendPasswordResetEmail() {
      this.$store.dispatch("sendPasswordResetEmail", {
        merchant: { login: this.merchant.login },
      });
    },
  },
};
</script>
