import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    emailInputId: String,
  };

  connect() {
    const emailInput = document.getElementById(this.emailInputIdValue);
    emailInput.addEventListener("input", () => {
      const url = new URL(this.element.href);
      url.searchParams.set("email", emailInput.value);
      this.element.href = url.toString();
    });
  }
}
