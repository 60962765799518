import JSZip from "jszip"; // For Excel export
import DataTable from "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-datetime"; // required for searchbuilder
import "datatables.net-searchbuilder-bs4";
import { dataTableLanguage } from "shared/data_table_language";

DataTable.Buttons.jszip(JSZip); // For Excel export

function renderLineItemSalesTable(tableConfig) {
  const TABLE = ".item-sales-table";

  const dataTableOpts = {
    ajax: {
      url: tableConfig.url,
      type: "GET",
      dataSrc: "item_sales_table_entries",
    },
    buttons: [
      {
        exportOptions: { columns: ":visible" },
        extend: "csv",
      },
      {
        // This does not work in Safari.
        // @see https://datatables.net/extensions/buttons/built-in
        exportOptions: { columns: ":visible" },
        extend: "excel",
      },
    ],
    // See this for syntax https://datatables.net/reference/option/dom
    dom: `
    <'#orders-table-controls.row'<'col-sm-6 col-md-3'l><'col-sm-18 col'f>>
    <'row'<'col-sm-12'tr>>
    <'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>
    <'#item-sales-table-download-buttons.row'<'col'B>>
  `,
    columns: [
      {
        className: "text-right",
        data: "name",
        orderable: true,
      },
      {
        className: "text-right",
        data: "category",
        orderable: true,
      },
      {
        className: "text-right",
        data: "quantity",
        orderable: true,
      },
      {
        className: "text-right",
        data: "total_without_tax",
        orderable: true,
      },
      {
        className: "text-right",
        data: "total_tax",
        orderable: true,
      },
      {
        className: "text-right",
        data: "order_count",
        orderable: true,
      },
    ],
    language: dataTableLanguage,
    order: [[/* total_without_tax */ 2, "desc"]],
  };
  new DataTable(TABLE, dataTableOpts);
}

export default function (config) {
  renderLineItemSalesTable(config);
}
