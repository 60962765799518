<template>
  <label
    class="drop-zone"
    @drop="handleDrop"
    @dragdrop="handleDrop"
    @dragover="preventEvent"
    @dragenter="preventEvent"
  >
    <div class="drop__info">
      <img width="27" height="22" :src="imgIcon" />
      <slot name="image-limits"></slot>
    </div>
    <div class="drop__cta">
      <img width="37" height="38" :src="uploadArrow" />
      <span class="drop__cta__text">
        {{ $t("instructions.click_or_drag_images") }}
      </span>
    </div>
    <input
      id="image-input"
      name="images"
      type="file"
      accept="image/png,image/jpeg"
      class="custom-file-input"
      :multiple="multiple"
      @change="handleInput"
    />
  </label>
</template>

<script>
import uploadArrow from "images/upload_arrow.svg";
import imgIcon from "images/img_icon.svg";

export default {
  props: ["multiple"],
  data() {
    return {
      files: [],
      uploadArrow,
      imgIcon,
    };
  },
  methods: {
    preventEvent(event) {
      event.preventDefault();
    },
    handleDrop(event) {
      event.preventDefault();
      this.files = event.dataTransfer.files;
      this.$emit("input", this.files);
    },
    handleInput(event) {
      event.preventDefault();
      this.files = event.target.files;
      this.$emit("input", this.files);
    },
  },
};
</script>

<style scoped>
.drop-zone {
  border-radius: 15px;
  border: dashed 1.2px #979797;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}
.drop__info {
  margin-top: 16px;
  color: #ccc;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #333333;
  opacity: 0.5;
}
.drop__cta {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: center;
}
.drop__cta__text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: center;
  color: #333333;
  margin-top: 16px;
}
</style>
