import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

/*
 * Google requires us to load the script directly from their website
 * using <script src="https://accounts.google.com/gsi/client" async defer></script>
 * see https://developers.google.com/identity/gsi/web/guides/client-library
 * so this file contains some helper methods for google SSO.
 * Make sure that the script tag is included on pages where this file is used.
 */
export default class extends Controller {
  static values = {
    clientId: String,
  };
  static targets = ["button", "tokenField"];

  connect() {
    if (typeof google === "undefined") {
      return;
    }

    google.accounts.id.initialize({
      client_id: this.clientIdValue,
      callback: this.authCallback.bind(this),
      use_fedcm_for_prompt: true,
    });

    // These are the configuration options for the button
    google.accounts.id.renderButton(
      this.buttonTarget,
      {
        theme: "outline",
        size: "large",
        text: "continue_with",
        width: 260,
        height: 38,
        logo_alignment: "center",
        "data-allow": "identity-credentials-get",
      }, // customization attributes
    );
    this.buttonTarget.disabled = false;
  }

  async authCallback(response) {
    const token = response.credential;
    this.tokenFieldTarget.value = token;
    /*
     * This allows Turbo to handle any returned errors rather than
     * reloading the whole page. We could do this.element.submit()
     * but then errors need to be handled differently. However, any
     * page we Turbo navigate to must support Turbo or have
     * `turbo_page_requires_reload`.
     */
    Turbo.navigator.submitForm(this.element);
  }
}
