import { Controller } from "@hotwired/stimulus";

// Adds an ability to zoom in and out, clockwise rotation to images.
// zooming and rotation are achieved by applying "transform" CSS
// directives to an underlying image target.
export default class extends Controller {
  static targets = ["image"];

  initialize() {
    this.scale = 1; // initial scale
    this.factor = 2; // defines the factor by which scale is applied.
    this.angle = 0; // initial rotation angle
    this.dragging = false;
  }

  connect() {
    this.captureImageDragging();
    this.element.classList.add("test-ready");
  }

  captureImageDragging() {
    this.element.addEventListener("mousedown", (event) => {
      this.dragging = true;
      this.currentTranslateX ||= 0;
      this.currentTranslateY ||= 0;
      this.initialOffsetX = event.offsetX;
      this.initialOffsetY = event.offsetY;
    });

    this.element.addEventListener("mouseup", (_event) => {
      this.dragging = false;
    });

    this.element.addEventListener("mousemove", (event) => {
      if (this.dragging) {
        this.translateX =
          event.offsetX - this.initialOffsetX + this.currentTranslateX;
        this.initialOffsetX = event.offsetX;
        this.currentTranslateX = this.translateX;

        this.translateY =
          event.offsetY - this.initialOffsetY + this.currentTranslateY;
        this.initialOffsetY = event.offsetY;
        this.currentTranslateY = this.translateY;

        // When an image is transformed by rotation the coordinate
        // net is rotated. To compensate for that we need to invert
        // the X and Y translation vectors according to current angle.
        if (this.angle % 360 == 90) {
          this.virtualTranslateX = this.translateY;
          this.virtualTranslateY = -this.translateX;
        } else if (this.angle % 360 == 180) {
          this.virtualTranslateX = -this.translateX;
          this.virtualTranslateY = -this.translateY;
        } else if (this.angle % 360 == 270) {
          this.virtualTranslateX = -this.translateY;
          this.virtualTranslateY = this.translateX;
        } else {
          // this.angle % 360 == 0
          this.virtualTranslateX = this.translateX;
          this.virtualTranslateY = this.translateY;
        }

        this.updateTransform(this.virtualTranslateX, this.virtualTranslateY);
      }
    });
  }

  zoomIn() {
    this.scale *= this.factor;
    this.updateTransform();
  }

  zoomOut() {
    this.scale /= this.factor;
    this.updateTransform();
  }

  rotate() {
    this.angle = (this.angle + 90) % 360;

    // reset translate to prevent jumping around on initial
    // dragging after a rotation
    this.currentTranslateX = 0;
    this.currentTranslateY = 0;

    this.updateTransform();
  }

  updateTransform(translateX, translateY) {
    const transform = [`scale(${this.scale})`, `rotate(${this.angle}deg)`];
    if (translateX !== undefined && translateY !== undefined) {
      transform.push(
        `translate(${translateX / this.scale}px, ${translateY / this.scale}px)`,
      );
    }

    this.imageTarget.style.transform = transform.join(" ");
  }
}
