import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    imageId: String,
  };

  rotateImage() {
    const img = document.getElementById(this.imageIdValue);
    const transform = img.style.transform;
    const rotateMatch = transform.match(/rotate\((.+)deg\)/);
    const currentAngle = rotateMatch ? +rotateMatch[1] : 0;
    const newAngle = (currentAngle + 90) % 360;
    const width = img.width;
    const height = img.height;

    // Just always make sure we have enough space for the rotated image.
    const margin = width > height ? (width - height) / 2 : 0;

    img.style.transform = `rotate(${newAngle}deg)`;
    img.style.marginTop = `${margin}px`;
    img.style.marginBottom = `${margin}px`;
    img.classList.add("capybara-image-rotated"); // adding this for capybara to wait for the rotated element to "appear"
  }
}
