import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const FILTER_INPUT = ".property-selector-filter-input";

    const onClickAddAnotherProperty = () => {
      const template = document.querySelector("#property-selector-template");
      const templateSelector =
        template.content.querySelector(".property-selector");
      const newSelector = document.importNode(templateSelector, true);
      initPropSelector(newSelector);
      newSelector.querySelector(FILTER_INPUT).value = "";
      template.before(newSelector);
    };

    const matchesFilter = (filter, text) => {
      const lcFilter = filter.toLowerCase();
      const lcText = text.toLowerCase();
      return filter === "" || lcText.includes(lcFilter);
    };

    const initPropSelector = (propSelector) => {
      const filterInput = propSelector.querySelector(FILTER_INPUT);
      const popover = propSelector.querySelector(".popover");
      const popoverClose = propSelector.querySelector(".popover__close");
      const options = propSelector.querySelectorAll(".popover__item");

      if (popover != null) {
        popover.style.display = "none";
        popoverClose.onclick = () => {
          popover.style.display = "none";
        };

        filterInput.onfocus = () => {
          popover.style.display = "block";
        };

        for (const option of options) {
          option.onclick = function () {
            const idInput = propSelector.querySelector(
              ".property-selector-id-input",
            );
            idInput.value = this.dataset.value;
            filterInput.value = this.innerText;
            popover.style.display = "none";
          };
        }
      }

      filterInput.oninput = function () {
        for (const option of options) {
          if (matchesFilter(this.value, option.innerText)) {
            option.style.display = "block";
          } else {
            option.style.display = "none";
          }
        }
      };

      const removeButton = propSelector.querySelector(
        ".property-selector-remove-button",
      );
      removeButton.onclick = () => {
        propSelector.remove();
      };
    };

    document
      .querySelector("#add-another-property")
      .addEventListener("click", onClickAddAnotherProperty);

    Array.from(document.querySelectorAll(".property-selector")).forEach(
      (propSelector) => {
        initPropSelector(propSelector);
      },
    );
  }
}
