import Vue from "vue/dist/vue.esm.js";
import Vuex from "vuex";

import { createOrderFilter } from "admin/orders/order_filter.js";
import * as actions from "admin/orders/store/actions.js";
import { cloneDeep } from "lodash";

function saveFilterToLocalStorage(filter) {
  filter["search"] = "";
  filter["order_search"] = "";
  filter["user_search"] = "";
  filter["property_id"] = "";
  const parsed = JSON.stringify(filter);
  localStorage.setItem("__pie__admin_order_filter", parsed);
}

function loadFilterFromQueryParams(urlSearchParams) {
  const filter = createOrderFilter();

  if (urlSearchParams.get("property_id")) {
    filter.property_id = urlSearchParams.get("property_id");
  }
  if (urlSearchParams.get("search")) {
    filter.search = urlSearchParams.get("search");
  }

  return filter;
}

Vue.use(Vuex);

const state = {
  loading: false,
  searching: false,
  filter: createOrderFilter(),
  defaultFilter: createOrderFilter(),
  status_filters: [],
  tag_filters: [],
  country_filters: {},
  relation_filters: [],
  flag_filters: [],
  orders: [],
  pages: 0,
  page: 1,
  total: 0,
  from: 1,
  to: 10,
  order_by: {
    attribute: "orders.created_at",
    direction: "desc",
  },
  time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const mutations = {
  startLoading: (state) => (state.loading = true),
  finishLoading: (state) => (state.loading = false),
  startSearching: (state) => (state.searching = true),
  finishSearching: (state) => (state.searching = false),
  updateOrders(state, orders) {
    state.orders = orders;
  },
  updateFilters(state, filters) {
    state.status_filters = filters.statuses;
    state.country_filters = filters.countries;
    state.tag_filters = filters.tags;
    state.flag_filters = filters.flags;
    state.relation_filters = filters.relations;
  },
  updatePages(state, pages) {
    state.pages = pages;
  },
  updateTotal(state, total) {
    state.total = total;
  },
  updateFrom(state, from) {
    state.from = from;
  },
  updateTo(state, to) {
    state.to = to;
  },
  updateFilter(state, filter) {
    state.filter = filter;
    saveFilterToLocalStorage(cloneDeep(filter));
  },
  setCreatedAfterFilter(state, createdAfter) {
    state.filter.created_after = createdAfter;
    saveFilterToLocalStorage(state.filter);
  },
  setCreatedBeforeFilter(state, createdBefore) {
    state.filter.created_before = createdBefore;
    saveFilterToLocalStorage(state.filter);
  },
  setMinimumTotal(state, minimumTotal) {
    state.filter.minimum_total = minimumTotal;
    saveFilterToLocalStorage(state.filter);
  },
  setMaximumTotal(state, maximumTotal) {
    state.filter.maximum_total = maximumTotal;
    saveFilterToLocalStorage(state.filter);
  },
  updatePage(state, page) {
    state.page = page;
  },
  updateOrderBy(state, orderBy) {
    state.order_by = orderBy;
  },
  loadFilter(state) {
    const jsonFilter = localStorage.getItem("__pie__admin_order_filter");
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.get("property_id") && urlSearchParams.get("search")) {
      state.filter = loadFilterFromQueryParams(urlSearchParams);
      return;
    }

    if (jsonFilter) {
      state.filter = JSON.parse(jsonFilter);
    }
  },
};

export const getters = {
  isLoading: (state) => {
    return state.loading;
  },
  isSearching: (state) => {
    return state.searching;
  },
  statusFilters: (state) => {
    return state.status_filters;
  },
  tagFilters: (state) => {
    return state.tag_filters;
  },
  countryFilters: (state) => {
    return state.country_filters;
  },
  relationFilters: (state) => {
    return state.relation_filters;
  },
  orders: (state) => {
    return state.orders;
  },
  pages: (state) => {
    return state.pages;
  },
  total: (state) => {
    return state.total;
  },
  from: (state) => {
    return state.from;
  },
  to: (state) => {
    return state.to;
  },
  orderAttributes: (_state) => {
    return {
      flags: {
        header: "",
        query: null,
      },
      name: {
        header: "Reference",
        query: "properties.name",
      },
      users_email: {
        header: "User",
        query: "users.email",
      },
      tax_total: {
        header: "Tax Total",
        query: "orders.tax_total_subunit",
      },
      total: {
        header: "Total",
        query: "orders.total_subunit",
      },
      status: {
        header: "Status",
        query: "orders.status",
      },
      country: {
        header: "Country",
        query: "countries.iso",
      },
      updated_at: {
        header: "Updated At",
        query: "orders.updated_at",
      },
      created_at: {
        header: "Created At",
        query: "orders.created_at",
      },
      link: {
        header: "Actions",
        query: null,
      },
    };
  },
  anyOrders: (state) => {
    return state.orders.length > 0;
  },
  currentPage: (state) => {
    return state.page;
  },
  orderBy: (state) => {
    return state.order_by;
  },
  filter: (state) => {
    return state.filter;
  },
  defaultFilter: (state) => {
    return state.defaultFilter;
  },
  flagFilters: (state) => {
    return state.flag_filters;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
