import $ from "jquery";
import "daterangepicker";
import "jszip"; // for export
import "pdfmake"; // for export
import DataTable from "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-datetime"; // required for searchbuilder
import "datatables.net-searchbuilder-bs4";
import { Controller } from "@hotwired/stimulus";

import I18n from "i18n-js";
import { dataTableLanguage } from "shared/data_table_language";

export default class extends Controller {
  // TODO(alan): Rewrite this to idiomatic Stimulus
  connect() {
    const propertyId = document.getElementById("merchant-reporting-main-data")
      .dataset.propertyId;
    const start = moment().subtract(29, "days");
    const end = moment();
    const startFormat = "YYYY-MM-DD 00:00";
    const endFormat = "YYYY-MM-DD 23:59";
    const statuses = $(".status_checkboxes input");
    let selectedStart = start.format(startFormat);
    let selectedEnd = end.format(endFormat);

    const cb = function (start, end) {
      return $("#report_range_text").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"),
      );
    };

    const dateRanges = {};
    dateRanges[I18n.t("dateranges.last_7_days")] = [
      moment().subtract(6, "days"),
      moment(),
    ];
    dateRanges[I18n.t("dateranges.last_30_days")] = [
      moment().subtract(29, "days"),
      moment(),
    ];
    dateRanges[I18n.t("dateranges.this_month")] = [
      moment().startOf("month"),
      moment().endOf("month"),
    ];
    dateRanges[I18n.t("dateranges.last_month")] = [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ];
    $("#report_range").daterangepicker(
      {
        startDate: start,
        endDate: end,
        ranges: dateRanges,
        locale: {
          format: "DD/MM/YYYY",
          applyLabel: I18n.t("buttons.apply"),
          cancelLabel: I18n.t("cancel"),
          customRangeLabel: I18n.t("dateranges.custom_range"),
        },
      },
      cb,
    );

    cb(start, end);

    const updateSelectedDates = function (start, end) {
      selectedStart = start.format(startFormat);
      selectedEnd = end.format(endFormat);
      return cb(start, end);
    };

    $("#report_range").on("apply.daterangepicker", function (ev, picker) {
      return updateSelectedDates(picker.startDate, picker.endDate);
    });

    $("#report_range").on("hide.daterangepicker", function (ev, picker) {
      return updateSelectedDates(picker.startDate, picker.endDate);
    });

    const table = new DataTable("#report_sample_orders_table", {
      columns: [
        {
          data: "created_at",
          render: function (data, type, _row, _meta) {
            if (type === "display") {
              return moment(data).fromNow();
            }
            return data;
          },
        },
        {
          data: "total",
        },
        {
          data: "tax_total",
        },
        {
          data: "entered_receipt_id",
        },
        {
          data: "status",
          render: function (data, type, _row, _meta) {
            if (type === "display") {
              return I18n.t(`purchases.status.${data}`);
            }
            return data;
          },
        },
      ],
      info: false,
      language: dataTableLanguage,
      paging: false,
      searching: false,
    });

    const sendRequest = function (url, start, end, checked) {
      return $.ajax(url, {
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        method: "POST",
        data: {
          updated_after: start,
          updated_before: end,
          statuses: checked,
          property_id: propertyId,
        },
      });
    };

    const getChecked = function (checkboxes) {
      return checkboxes
        .filter(":checked")
        .map(function (i, el) {
          return $(el).attr("name");
        })
        .toArray();
    };

    $("#run_sample_report").click(function () {
      const checked = getChecked(statuses);
      return sendRequest(
        "reporting/sample_report.json",
        selectedStart,
        selectedEnd,
        checked,
      ).done(function (data, _response) {
        $("#sample_orders_container").removeClass("d-none");
        $("#report_count_container").removeClass("d-none");
        table.rows().remove().draw();
        table.rows.add(data.orders).draw();
        return $("#report_number_of_orders").html(data.count);
      });
    });

    $("#send_report").click(async function () {
      const checked = getChecked(statuses);
      await sendRequest(
        "reporting/send_report",
        selectedStart,
        selectedEnd,
        checked,
      );
      const msg = I18n.t("merchant_reporting.report_will_be_mailed");
      $("#report_count_container").html(`<div class="col-12">${msg}</div>`);
      $("#report_count_container").removeClass("d-none");
    });
  }
}
