import $ from "jquery";

class EmailField {
  constructor(elementId) {
    this.element = $(`#${elementId}`);
  }

  erase() {
    this.element.val("");
  }

  markAsInvalid() {
    this.element.addClass("is-invalid");
  }

  isValid() {
    // TODO(alan): Validate the email on the client for speed. For now, all
    // email addresses are validated on the backend.
    return true;
  }

  isEmpty() {
    return this.element.val().length == 0;
  }
}

export { EmailField };
