<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div class="row justify-content-center">
    <div class="col-11 col-md-12">
      <div v-if="images.length > 0 && orderable" class="card__header">
        {{ $t("instructions.drag_and_drop") }}
      </div>
      <Draggable
        v-if="images.length > 0 && orderable"
        v-model="sortedImages"
        group="images"
        class="sortable__area"
        @start="drag = true"
        @end="drag = false"
      >
        <div
          v-for="(image, index) in sortedImages"
          :key="image.id"
          :class="slotClass(image, index)"
        >
          <div class="col-3 col-md-2 p-0">
            <img :src="image.url" class="card__image" />
          </div>
          <div class="col-7 col-md-8 card__name__container">
            <span class="card__name">{{ image.name }}</span>
          </div>
          <div class="col-2 card__remove__container">
            <div class="remove" @click="removeHandler(image.id)"></div>
          </div>
        </div>
      </Draggable>
      <div
        v-for="image in sortedImages"
        v-if="!orderable"
        :key="image.id"
        class="row sort-card"
      >
        <div class="col-2 p-0">
          <img :src="image.url" class="card__image" />
        </div>
        <div class="col-8 card__name__container">
          <span class="card__name">{{ image.name }}</span>
        </div>
        <div class="col-2 card__remove__container">
          <div class="remove" @click="removeHandler(image.id)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  props: {
    images: {
      type: Array,
      required: false,
      default: Array,
    },
    orderingDispatch: {
      type: String,
      required: false,
      default: undefined,
    },
    orderable: {
      type: Boolean,
      required: true,
    },
    removalDispatch: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    sortedImages: {
      get() {
        console.debug("sortedImages get", JSON.stringify(this.images));
        return this.images;
      },
      set(images) {
        if (this.orderable) {
          console.debug("images-reordered", JSON.stringify(images));
          this.$emit("images-reordered", images);
          this.orderingDispatch &&
            this.$store.dispatch(this.orderingDispatch, images);
        }
      },
    },
  },
  methods: {
    removeHandler(id) {
      this.$emit("image-removed", id);
      this.removalDispatch && this.$store.dispatch(this.removalDispatch, id);
    },
    slotClass(image, index) {
      const highlight =
        !image.confirmed || image.sort_order !== index ? "alert-info" : "";
      return ["row", "sort-card", highlight];
    },
  },
};
</script>

<style scoped>
.sort-card {
  border-radius: 8px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
}
.card__image {
  height: 62px;
  width: 62px;
}
.card__name__container {
  flex-direction: row;
  display: flex;
  padding: 0;
}
.card__remove__container {
  padding-right: 16px;
  display: flex;
  flex-direction: row;
}
.remove {
  align-self: center;
  position: absolute;
  right: 16px;
}
.card__name {
  font-size: 15px;
  align-self: center;
}
.card__header {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #333;
  opacity: 0.5;
  margin-bottom: 16px;
}
</style>
