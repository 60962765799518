import { Controller } from "@hotwired/stimulus";

// Used to submit the form when the user clicks the next step button.
export default class extends Controller {
  static targets = ["form", "footer", "nextStep"];

  submitForm(event) {
    if (this.hasFormTarget) {
      event.preventDefault();
      this.formTarget.requestSubmit();
    }
  }

  hideFooter() {
    this.footerTarget.classList.add("d-none");
  }

  showFooter() {
    this.footerTarget.classList.remove("d-none");
  }

  hideNextStep() {
    this.nextStepTarget.classList.add("d-none");
  }

  showNextStep() {
    this.nextStepTarget.classList.remove("d-none");
  }
}
