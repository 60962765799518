<template>
  <div
    v-if="flashMessage.show"
    id="flash-message"
    class="alert alert-dismissible"
    :class="classObject"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="flashMessage.message"></span>
    <button
      type="button"
      class="close"
      aria-label="Close"
      @click="hideMessage()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>
<script type="text/javascript">
/*
    Requires this object in the state:
    {
      state: {
        flash_message: {
          show: false,
          status: '', //danger, info, warn
          message: ''
        }
      },
      mutations: {
        hideFlash(state) {
          Vue.set(state['flash_message'], 'show', false)
        },
        showFlash(state) {
          Vue.set(state['flash_message'], 'show', true)
        },
        setFlashMessage(state, payload) {
          Vue.set(state, 'flash_message', payload)
        }
      },
      actions: {
        closeFlashMessage: ({commit, state}) => {
          commit('hideFlash')
        })
      }
      getters: {
        showFlash: state => {
          return state.flash_message.show
        },
        showFlashInfo: state => {
          return state.flash_message.status == 'info'
        },
        showFlashDanger: state => {
          return state.flash_message.status == 'danger'
        },
        showFlashWarn: state => {
          return state.flash_message.status == 'warn'
        },
        flashMessage: state => {
          return state.flash_message
        }
      }
    }

  */
export default {
  computed: {
    flashMessage() {
      return this.$store.getters.flashMessage;
    },
    classObject() {
      return {
        "alert-info": this.$store.getters.showFlashInfo,
        "alert-danger": this.$store.getters.showFlashDanger,
        "alert-warn": this.$store.getters.showFlashWarn,
      };
    },
  },
  methods: {
    hideMessage() {
      this.$store.dispatch("closeFlashMessage");
    },
  },
};
</script>
