import { pieApiFetch, checkResponseOk } from "shared/fetch_decorators";

export default class {
  constructor(resourceType) {
    this.resourceType = resourceType;
  }

  /**
   * @param {string} searchTerm
   * @return {Array} Array of resourceType objects matching the searchTerm
   */
  async search(searchTerm) {
    const response = await pieApiFetch(
      `/admin/${this.resourceType}/query?search=${searchTerm}&page=1`,
    );
    await checkResponseOk(response);
    const data = await response.json();
    return data[this.resourceType];
  }
}
