import { pieApiFetch, checkResponseOk } from "shared/fetch_decorators";

export async function sendOrderToCustomer(orderId, sendToEmail, sendToSms) {
  const response = await pieApiFetch(
    `/orders/${orderId}/send_order_to_customer`,
    {
      method: "POST",
      body: {
        order: {
          send_to_sms: sendToSms,
          send_to_email: sendToEmail,
        },
      },
    },
  );
  await checkResponseOk(response);
  return response.json();
}
