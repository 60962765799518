<template>
  <div class="show">
    <div class="row justify-content-between">
      <div class="col-6">
        <h1>Counter</h1>
      </div>
      <div class="col-1">
        <router-link :to="{ path: '/' }"> Back </router-link>
      </div>
    </div>
    <Flash />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="btn-toolbar justify-content-between" role="toolbar">
              <div class="btn-group" role="group">
                <Loader
                  v-if="isLoading(resourceTypes.PIE_COUNTERS)"
                  :width="'25px'"
                  :height="'25px'"
                />
              </div>
              <div class="input-group">
                <button
                  v-if="!isEditing"
                  id="pie-counter-edit-button"
                  type="button"
                  class="btn btn-primary"
                  @click="startEditing()"
                >
                  Edit
                </button>
                <button
                  v-if="isEditing"
                  id="pie-counter-cancel-button"
                  type="button"
                  class="btn btn-primary"
                  @click="cancelEditing()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <fieldset class="form-row" :disabled="!isEditing">
          <div class="form-group col-12 col-lg-6">
            <label for="pie_counter_name">Name *</label>
            <input
              id="pie_counter_name"
              v-model="pieCounter.name"
              name="pie_counter[name]"
              type="text"
              class="form-control"
              required
              aria-describedby="pie-counter-name-help-block"
              @input="updateSlug"
            />
            <small
              id="pie-counter-name-help-block"
              class="form-text text-muted"
            >
              Must contain only printable ASCII characters.
            </small>
          </div>
          <div class="form-group col-12 col-lg-6">
            <label for="pie_counter_slug">Slug</label>
            <input
              id="pie_counter_slug"
              v-model="pieCounter.slug"
              name="pie_counter[slug]"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="form-group col-12 col-lg-6">
            <label for="pie_counter_slack_notification_channel_id">
              Slack Channel ID
            </label>
            <input
              id="pie_counter_slack_notification_channel_id"
              v-model="pieCounter.slack_notification_channel_id"
              name="pie_counter[slack_notification_channel_id]"
              type="text"
              class="form-control"
            />
          </div>
          <ResourceIdsInput
            v-model="pieCounter.property_ids"
            class="col-12 pie-counter-properties-list"
            :resource-type="resourceTypes.PROPERTIES"
          >
            <template #default="slotProps">
              <a
                :href="newOrderHref(slotProps.resourceId)"
                class="new-order-link"
                target="_blank"
              >
                New Order
              </a>
            </template>
          </ResourceIdsInput>
        </fieldset>
        <div class="form-row">
          <div class="col-12 col-lg-6">
            <button
              v-if="isEditing && !newPieCounter"
              id="pie-counter-save-button"
              type="button"
              class="btn btn-primary"
              @click="saveResource()"
            >
              Save
            </button>
            <button
              v-if="isEditing && newPieCounter"
              id="pie-counter-create-button"
              type="button"
              class="btn btn-primary"
              @click="createResource()"
            >
              Create New Pie Counter
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->
    <div v-if="!newPieCounter" class="card col-12 col-lg-6">
      <div class="card-body">
        <h2 class="card-title">Directions Images</h2>
        <ImageCarousel
          :images="directionsImages"
          :orderable="true"
          class="pie-counter-directions-images-carousel"
          @images-reordered="assignAndSortImages"
          @image-removed="removeDirectionsImage"
        />
        <div v-if="removedDirectionsImages.length > 0">
          <div class="alert alert-warning" role="alert">
            These images will be removed.
          </div>
          <ImageCarousel
            :images="removedDirectionsImages"
            :orderable="false"
            class="pie-counter-removed-directions-images-carousel"
            @image-removed="undoRemoveDirectionsImage"
          />
        </div>
        <fieldset>
          <ImageInput class="image-input" multiple="true" @input="uploadImages">
            <image-limits>
              <span>JPG/PNG</span>
            </image-limits>
          </ImageInput>
        </fieldset>
        <button
          class="btn btn-primary pie-counter-save-images-button"
          @click="saveResource"
        >
          Save Images
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Flash from "components/flash_message.vue";
import ImageCarousel from "components/image_carousel.vue";
import ImageInput from "components/image_input.vue";
import Loader from "components/loader.vue";
import ResourceIdsInput from "components/resource_ids_input.vue";
import ResourceTypes from "admin/resources.js";
import { cloneDeep, snakeCase } from "lodash";
import { mapGetters } from "vuex";
import * as PieCounterApi from "shared/pie_counter_api";

/**
 * @typedef {object} PieCounter
 * @property {string} id
 * @property {string} name
 * @property {string} slug
 * @property {string} slack_notification_channel_id
 * @property {Array} directions_images
 * @property {Array} directions_images_attributes
 */

export default {
  components: {
    Flash,
    ImageCarousel,
    ImageInput,
    Loader,
    ResourceIdsInput,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.newPieCounter = to.name == "pie_counters_new";
    });
  },
  data() {
    return {
      /** @type {PieCounter} */
      pieCounter: {
        id: this.$route.params.id,
        name: "",
        slug: "",
        slack_notification_channel_id: "",
        directions_images: [],
        directions_images_attributes: [],
      },
      resourceTypes: ResourceTypes,
      isEditing: this.$route.params.id ? false : true,
      newPieCounter: this.$route.name == "pie_counters_new",
    };
  },
  computed: {
    /** @return {Object} */
    storedPieCounter() {
      return this.$store.getters.getResourceById(
        ResourceTypes.PIE_COUNTERS,
        this.$route.params.id,
      );
    },
    snakeCaseName() {
      return snakeCase(this.pieCounter.name);
    },
    directionsImages() {
      return this.pieCounter.directions_images.filter((i) => !i._destroy);
    },
    removedDirectionsImages() {
      return this.pieCounter.directions_images.filter((i) => i._destroy);
    },
    ...mapGetters(["anyResources", "isLoading", "resourceAttributes"]),
  },
  watch: {
    storedPieCounter: {
      handler: function (newData, _oldData) {
        this.pieCounter = cloneDeep(newData);
      },
      deep: true,
    },
  },
  async beforeMount() {
    if (this.$route.params.id) {
      await this.$store.dispatch("fetchResource", {
        resource: ResourceTypes.PIE_COUNTERS,
        id: this.$route.params.id,
      });
      await this.$store.dispatch("updateFilterAndFetch", {
        resource: ResourceTypes.PROPERTIES,
        endpoint: ResourceTypes.PROPERTIES,
        filter: {
          pie_counter_id: this.$route.params.id,
          per_page: 0 /* infinite */,
        },
      });
    }
  },
  methods: {
    assignAndSortImages(images) {
      images.forEach((image, index) => {
        image.sort_order = index;
      });
      this.pieCounter.directions_images = images;
    },
    changePage(page) {
      this.$store.dispatch("changePage", {
        resource: ResourceTypes.PROPERTIES,
        page: page,
      });
    },
    changePageResource(page, resource) {
      this.$store.dispatch("changePage", { resource: resource, page: page });
    },
    cancelEditing() {
      this.isEditing = false;
      this.pieCounter = cloneDeep(
        this.$store.getters.getResourceById(
          ResourceTypes.PIE_COUNTERS,
          this.$route.params.id,
        ),
      );
    },
    createResource() {
      this.isEditing = false;
      this.$store.dispatch("createResource", {
        endpoint: `${ResourceTypes.PIE_COUNTERS}`,
        resource: "pie_counters",
        singularResource: "pie_counter",
        payload: { pie_counter: this.pieCounter },
      });
    },
    removeDirectionsImage(id) {
      const image = this.pieCounter.directions_images.find(
        (image) => image.id === id,
      );
      this.$set(image, "_destroy", true);
    },
    saveResource() {
      this.isEditing = false;
      this.pieCounter.directions_images_attributes =
        this.pieCounter.directions_images;
      this.$store.dispatch("updateResource", {
        endpoint: `${ResourceTypes.PIE_COUNTERS}/${this.pieCounter.id}`,
        resource: ResourceTypes.PIE_COUNTERS,
        payload: { pie_counter: this.pieCounter },
        id: this.pieCounter.id,
      });
    },
    startEditing() {
      this.isEditing = true;
    },
    undoRemoveDirectionsImage(id) {
      const image = this.pieCounter.directions_images.find(
        (image) => image.id === id,
      );
      this.$set(image, "_destroy", false);
    },
    async uploadImages(files) {
      const updatedCounter = await PieCounterApi.addDirectionsImages(
        this.pieCounter.id,
        files,
      );
      this.$store.commit("updateIndividualResource", {
        resource: ResourceTypes.PIE_COUNTERS,
        id: updatedCounter.id,
        data: updatedCounter,
      });
    },
    updateSlug() {
      if (!this.newPieCounter) {
        return;
      }

      this.pieCounter.slug = snakeCase(this.pieCounter.name);
    },
    newOrderHref(resourceId) {
      return `/orders/new?property_id=${resourceId}`;
    },
  },
};
</script>
