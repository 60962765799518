import { Controller } from "@hotwired/stimulus";

// Handles a simple carousel with slides.
//
// Taken from https://stimulus.hotwired.dev/handbook/managing-state with
// boundaries check for index added.
export default class extends Controller {
  static targets = ["slide", "previousBtn", "nextBtn"];
  static values = { index: { type: Number, default: 0 } };

  connect() {
    this.initControls();
  }

  initControls() {
    this.previousBtnTargets.forEach((element, index) => {
      // Disable the previous button on the first slide
      if (index === 0) {
        element.disabled = true;
      }
    });

    this.nextBtnTargets.forEach((element, index) => {
      // Disable the next button on the last slide
      if (index === this.slidesCount - 1) {
        element.disabled = true;
      }
    });
  }

  get slidesCount() {
    return this.slideTargets.length;
  }

  next() {
    this.indexValue = Math.min(
      this.indexValue + 1,
      this.slideTargets.length - 1,
    );
  }

  previous() {
    this.indexValue = Math.max(this.indexValue - 1, 0);
  }

  indexValueChanged() {
    this.showCurrentSlide();
  }

  showSlide(event) {
    this.indexValue = event.params.index;
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index !== this.indexValue;
    });
  }
}
