<template>
  <!-- TODO -->
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <!-- eslint-disable vue/require-v-for-key -->
  <!-- eslint-disable max-len -->
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Assigned {{ resourceTypeTitle(searchResourceType) }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row" style="overflow: scroll">
            <div class="col-12">
              <div class="card">
                <PopOver
                  v-if="showPopup"
                  :close-icon-click-action="hidePopup"
                  :list-item-click-action="selectResource"
                  :list-items="resources(resourceTypes.SEARCHED_RESOURCE)"
                  :list-item-display-attribute="'display_name'"
                  :title="`Found ${resourceTypeTitle(searchResourceType)}`"
                  :is-loading="isLoading(resourceTypes.SEARCHED_RESOURCE)"
                />
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h5>Search</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group col-sm-12">
                        <div class="form-group col-sm-12">
                          <input
                            v-model="searchTerm"
                            class="form-control"
                            type="text"
                            @input="searchResource"
                            @keyup.enter="searchResource()"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th
                              v-for="(schema, key) in searchResourceAttributes"
                              :key="key"
                              :class="sortingClass(schema.query)"
                              scope="col"
                              @click="updateOrderBy(schema.query)"
                            >
                              {{ schema.header }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="resource in pendingResources"
                            :key="resource.id"
                            :class="resource.bg_class"
                          >
                            <td
                              v-for="(
                                value, attributeName
                              ) in searchResourceAttributes"
                              :key="attributeName"
                              :title="resource[attributeName]"
                            >
                              {{ resource[attributeName] }}
                            </td>
                            <td>
                              <button
                                class="btn btn-primary search_assign-remove_assignment"
                                title="Remove assignment"
                                @click="removeAssignment(resource.id)"
                              >
                                &times;
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-5">
                      Showing {{ startingRecord() }} to {{ endingRecord() }} of
                      {{ total }} entries
                    </div>
                    <div class="col-sm-12 col-md-7 align-right">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                          <li
                            :class="[
                              'page-item',
                              currentPage === 1 ? 'disabled' : '',
                            ]"
                          >
                            <a
                              class="page-link"
                              @click="
                                changePage(currentPage - 1, searchResourceType)
                              "
                              >Previous</a
                            >
                          </li>
                          <li :class="['page-item']">
                            <a
                              class="page-link"
                              @click="changePage(1, searchResourceType)"
                              >First</a
                            >
                          </li>
                          <li
                            v-for="page in pages"
                            v-if="truncatePages(page)"
                            class="page-item"
                          >
                            <a
                              :class="[
                                'page-link',
                                page === currentPage ? 'current-page' : '',
                              ]"
                              @click="changePage(page, searchResourceType)"
                              >{{ page }}</a
                            >
                          </li>
                          <li :class="['page-item']">
                            <a
                              class="page-link"
                              @click="changePage(pages, searchResourceType)"
                              >Last</a
                            >
                          </li>
                          <li
                            :class="[
                              'page-item',
                              currentPage === pages ? 'disabled' : '',
                            ]"
                          >
                            <a
                              class="page-link"
                              @click="
                                changePage(currentPage + 1, searchResourceType)
                              "
                              >Next</a
                            >
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary search_assign-save-btn"
            data-dismiss="modal"
            @click="saveResources()"
          >
            Save
          </button>
          <button class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopOver from "components/popover.vue";
import Resources from "admin/resources.js";
import { map, debounce, filter } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    PopOver,
  },
  props: {
    searchResourceAttributes: {
      type: Object,
      required: true,
    },
    searchResourceType: {
      type: String,
      required: true,
    },
    currentResourceId: {
      type: String,
      required: false,
      default: undefined,
    },
    currentResourceType: {
      type: String,
      required: true,
    },
    changePage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: "",
      pendingResources: [],
      resourceTypes: Resources,
    };
  },
  computed: {
    showPopup() {
      return this.searchTerm.length > 0;
    },
    orderBy() {
      return this.resourceOrderBy(this.searchResourceType);
    },
    items() {
      return this.resources(this.searchResourceType);
    },
    pages() {
      return this.resourcePages(this.searchResourceType);
    },
    total() {
      return this.resourcesTotal(this.searchResourceType);
    },
    currentPage() {
      return this.resourcesCurrentPage(this.searchResourceType);
    },
    ...mapGetters([
      "isLoading",
      "resourceOrderBy",
      "resourcePages",
      "resources",
      "resourcesCurrentPage",
      "resourcesTotal",
      "resourceTypeTitle",
    ]),
  },
  watch: {
    items: function (val, oldVal) {
      if (oldVal != val) {
        this.pendingResources = val;
      }
    },
  },
  methods: {
    startingRecord() {
      return (this.currentPage - 1) * 10 + 1;
    },
    endingRecord() {
      const lastRecord = this.currentPage * 10;
      if (lastRecord > this.total) {
        return this.total;
      }
      return lastRecord;
    },
    sortingClass(attribute) {
      if (attribute === this.orderBy.attribute) {
        return "sorting_" + this.orderBy.direction;
      }
      return "";
    },
    updateOrderBy(attribute) {
      const newOrderBy = {
        attribute: attribute,
      };
      if (this.orderBy.attribute === attribute) {
        newOrderBy["direction"] =
          this.orderBy.direction === "desc" ? "asc" : "desc";
      } else {
        newOrderBy["direction"] = "desc";
      }
      this.$store.dispatch("updateOrderBy", newOrderBy);
    },
    truncatePages(page) {
      if (page <= this.currentPage + 2 && page >= this.currentPage - 1) {
        return true;
      }
      if (page >= this.pages - 2 && this.currentPage <= 3) {
        return true;
      }
      if (page <= 3 && this.currentPage >= this.pages - 2) {
        return true;
      }

      return false;
    },
    searchResource: debounce(
      function () {
        const filter = { search: this.searchTerm };
        this.$store.dispatch("updateFilterAndFetch", {
          resource: this.resourceTypes.SEARCHED_RESOURCE,
          endpoint: this.searchResourceType,
          filter: filter,
        });
      },
      200,
      { trailing: true },
    ),
    selectResource(resource) {
      this.pendingResources.push(resource);
    },
    saveResources() {
      if (this.pendingResources.length > 0) {
        const ids = map(this.pendingResources, function (resource) {
          return resource.id;
        });
        // TODO(alan): No need to know the endpoint here.
        const payload = {
          endpoint: `${this.currentResourceType}/${this.currentResourceId}/assign_${this.searchResourceType}`,
          data: { resource_ids: ids },
          current_resource_id: this.currentResourceId,
          resource: this.searchResourceType,
        };
        this.$store.dispatch("resourceAssignment", payload);
      }
    },
    removeAssignment(resourceId) {
      this.pendingResources = filter(
        this.pendingResources,
        function (resource) {
          return resource.id != resourceId;
        },
      );
    },
    hidePopup() {
      this.searchTerm = "";
    },
  },
};
</script>

<style scoped>
.current-page {
  font-weight: bold;
}
td {
  min-width: 1em;
}
.resource_list {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.resource {
  background-color: #dfe3e8;
  color: #454f5b;
  display: inline-flex;
  word-wrap: break-word;
  max-width: 100%;
  min-height: 24px;
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.resource__label {
  flex: 0 1 auto;
  min-width: 0;
  width: 100%;
  margin-right: 5px;
  display: block;
}
.resource__remove {
  flex-shrink: 0;
  display: block;
  cursor: pointer;
  float: right;
}
</style>
