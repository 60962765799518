var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-striped table-hover table-sm"},[_c('thead',[_c('tr',_vm._l((_vm.attributes),function(value){return _c('th',{class:_vm.headerClass(value.query),attrs:{"scope":"col"},on:{"click":function($event){return _vm.changeOrderBy(value.query)}}},[_vm._v("\n                    "+_vm._s(value.header)+"\n                  ")])}),0)]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{class:item.bg_class},[_vm._l((_vm.attributes),function(value,key){return (key !== 'link')?_c('td',{class:value.query + '_data',attrs:{"title":_vm.tableTitle(item, key)}},[_vm._v("\n                    "+_vm._s(_vm.tableValue(item, key))+"\n                  ")]):_vm._e()}),_vm._v(" "),_vm._l((_vm.attributes),function(value,key){return (key === 'link')?_c('td',{attrs:{"title":_vm.tableTitle(item, key)}},[(item.needs)?_c('Needs',{attrs:{"needs":item.needs}}):_vm._e(),_vm._v(" "),(item.link)?_c('a',{attrs:{"href":item[key],"data-method":"get"}},[_vm._v(">")]):_vm._e(),_vm._v(" "),(!item.link)?_c('router-link',{attrs:{"to":{
                        name: `${_vm.resourceString}_show`,
                        params: { id: item.id },
                      }}},[_vm._v(">")]):_vm._e()],1):_vm._e()})],2)}),0)])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-5"},[_vm._v("\n            Showing items "+_vm._s(_vm.startingRecord())+" to "+_vm._s(_vm.endingRecord())+"\n            "),(_vm.total > 0)?_c('span',[_vm._v(" of "+_vm._s(_vm.total)+" entries")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-sm-12 col-md-7 align-right"},[_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-end"},[_c('li',{class:['page-item', _vm.currentPage === 1 ? 'disabled' : '']},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.changePage(_vm.currentPage - 1)}}},[_vm._v("Previous")])]),_vm._v(" "),_c('li',{class:['page-item']},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.changePage(1)}}},[_vm._v("First")])]),_vm._v(" "),_vm._l((_vm.pages),function(page){return (_vm.truncatePages(page))?_c('li',{staticClass:"page-item"},[_c('a',{class:[
                      'page-link',
                      page === _vm.currentPage ? 'current-page' : '',
                    ],on:{"click":function($event){return _vm.changePage(page)}}},[_vm._v(_vm._s(page))])]):_vm._e()}),_vm._v(" "),_c('li',{class:['page-item']},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.changePage(_vm.pages)}}},[_vm._v("Last")])]),_vm._v(" "),_c('li',{class:[
                    'page-item',
                    _vm.currentPage === _vm.pages ? 'disabled' : '',
                  ]},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.changePage(_vm.currentPage + 1)}}},[_vm._v("Next")])])],2)])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }