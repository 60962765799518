// Polyfill for Stera terminal
// Chrome 52, android 7, 8
import "core-js/features/global-this";

// This needs to come first to ensure we catch errors during the imports below.
import { initSentry } from "initializers/sentry";
initSentry(JSON.parse(document.currentScript.dataset.extraSentryConfig));

// This needs to go before anything that might do translations like the
// controllers.
import { initI18n } from "initializers/i18n.js.erb";
initI18n(document.currentScript.dataset.locale);

import "bootstrap";
import "bootstrap-select";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
import "channels";
import "shared/rails_jquery_helper";
import "shared/cookie_popup";

import "@fortawesome/fontawesome-free/js/all";

import RailsNestedForm from "@stimulus-components/rails-nested-form";

Rails.start();

const application = Application.start();
// Call webpack's require.context to load all the controllers in the controllers
// directory. This is the only place in the application where we use
// something from the Node environment, hence the eslint-disable-next-line.
// @see https://www.npmjs.com/package/@hotwired/stimulus-webpack-helpers
// eslint-disable-next-line no-undef
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

application.register("nested-form", RailsNestedForm);

// For the sign in page tests.
window.Stimulus = application;

// Disable Turbo by default because we are not ready to go full throttle yet.
// If we ever migrate from Vue to Hotwire then this probably can be removed.
Turbo.session.drive = false;
