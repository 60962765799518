import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import I18n from "i18n-js";

// TODO(alan): This whole thing can be replaced with a standard link_to form
// form call with a redirect_back in the Rails controller.
export default class extends Controller {
  static values = {
    id: String,
  };

  cancel() {
    if (window.confirm(I18n.t("purchases.returns.confirm"))) {
      this.element.disabled = true;
      window.pie
        .call(`/jp_nta/send_sets/${this.idValue}/cancel`, {}, "POST")
        .done(function () {
          window.location.reload();
        })
        .fail((error) => {
          if (error?.responseJSON) {
            $("#nta-error").html(error.responseJSON.error);
            $("#nta-error").show();
          } else {
            $("#nta-error").html("Had an issue with the request.");
            $("#nta-error").show();
          }
          console.log(error);
        });
      this.element.disabled = false;
    }
  }
}
