import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["input", "results", "field"];
  static values = {
    url: String, // url + query must return HTML for the resultsTarget
  };

  search(event) {
    if (event.target.value.length < 3) {
      return;
    }
    this.debouncedSearch(event);
  }

  select(event) {
    this.fieldTarget.value = event.params.id;
    this.inputTarget.value = event.target.innerText;
    this.resultsTarget.innerHTML = "";
  }

  private;

  debouncedSearch = debounce((event) => {
    const query = event.target.value;
    this.resultsTarget.src = `${this.urlValue}${query}`;
  }, 250);
}
