<template>
  <div id="orders_app">
    <OrderFilter
      :remove-from-filter="removeFromFilter"
      :add-to-filter="addToFilter"
      :clear-order-relation-filter="clearOrderRelationFilter"
      :set-order-relation-filter="setOrderRelationFilter"
      :default-search="$store.getters.filter.search"
      :default-property="$store.getters.filter.property_id"
    />

    <VueTable
      v-if="$store.getters.anyOrders"
      :title="'Orders'"
      :attributes="$store.getters.orderAttributes"
      :items="$store.getters.orders"
      :pages="$store.getters.pages"
      :total="$store.getters.total"
      :from="$store.getters.from"
      :to="$store.getters.to"
      :current-page="$store.getters.currentPage"
      :order-by="$store.getters.orderBy"
      :change-page="changePage"
      :update-order-by="updateOrderBy"
    ></VueTable>
    <div
      v-if="!$store.getters.anyOrders"
      id="empty-results-section"
      class="row"
    >
      <div class="col-12">
        <h5>No orders were found.</h5>
        <p>The Filter searches in this fashion:</p>
        <p>Selected statuses AND selected tags AND search term</p>
        <p>If you select multiple status or tags, they are ORed together.</p>
        <p>
          Search term searches exactly the term on 'Order Id', 'Merchant Login',
          'User Email'
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OrderFilter from "../../components/order_filter.vue";
import VueTable from "../../components/table.vue";

export default {
  components: {
    OrderFilter,
    VueTable,
  },
  props: ["filters"],
  beforeMount() {
    return this.$store.dispatch("loadFilter");
  },
  async mounted() {
    await this.$store.dispatch("initFilters", this.filters);
    this.$store.dispatch("queryOrders");
  },
  methods: {
    loaded() {
      this.$store.commit("finishLoading");
    },
    addToFilter(key, value) {
      const filter = this.$store.getters.filter;
      if (!filter[key]) {
        filter[key] = [];
      }
      filter[key].push(value);
      this.$store.commit("updatePage", 1);
      this.$store.dispatch("updateWithoutQuery", filter);
    },
    changePage(page) {
      this.$store.dispatch("changePage", page);
    },
    removeFromFilter(key, value) {
      const filter = this.$store.getters.filter;
      if (filter[key].includes(value)) {
        filter[key] = filter[key].filter(function (el) {
          if (el !== value) {
            return el;
          }
        });
      }
      this.$store.commit("updatePage", 1);
      this.$store.dispatch("updateWithoutQuery", filter);
    },
    clearOrderRelationFilter() {
      const filter = this.$store.getters.filter;
      filter["order_relation"] = null;
      this.$store.dispatch("updateWithoutQuery", filter);
    },

    setOrderRelationFilter(value) {
      const filter = this.$store.getters.filter;
      // Keeping order_relation snake case to match the other filters
      filter["order_relation"] = value;
      this.$store.dispatch("updateWithoutQuery", filter);
    },
    updateOrderBy(newOrderBy) {
      this.$store.dispatch("updateOrderBy", newOrderBy);
    },
  },
};
</script>

<style scoped></style>
