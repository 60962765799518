<template>
  <div>
    <SearchAndAssign
      id="order-tags"
      :current_resources="$store.getters.current_tags"
      :available_resources="$store.getters.available_tags"
      :title="'Tags'"
      :popover_title="'Available Tags'"
      :query-resource-action="'queryTags'"
      :add-resource-action="'addTag'"
      :remove-resource-action="'removeTag'"
      :resource-name="'tag'"
      :parent-resource-id="$store.getters.order_id"
      :can-create-resource="true"
    />
    <div class="card">
      <div class="card-header">Needs</div>
      <div class="card-body">
        <Needs :needs="$store.getters.needs" />
      </div>
    </div>
    <ReassignOrder
      :available_resources="$store.getters.available_users"
      :resource_label="'Users'"
      :card_label="'Change User'"
      :search-query="'queryUsers'"
      :resource_display="'email'"
      :assignment-dispatch="'assignUser'"
      :error="$store.getters.assign_user_error"
    />

    <ReassignOrder
      :available_resources="$store.getters.available_properties"
      :resource_label="'Property'"
      :card_label="'Change Property'"
      :search-query="'queryProperties'"
      :resource_display="'display_name'"
      :assignment-dispatch="'assignProperty'"
      :error="$store.getters.assign_property_error"
    />
  </div>
</template>

<script>
import SearchAndAssign from "../components/search_and_assign.vue";
import Needs from "../components/table_needs.vue";
import ReassignOrder from "../components/order_reassignment.vue";

export default {
  components: {
    Needs,
    ReassignOrder,
    SearchAndAssign,
  },
};
</script>

<style scoped></style>
