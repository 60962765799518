<template>
  <div class="form-group position-relative">
    <input
      v-model="searchTerm"
      type="search"
      class="form-control"
      autocomplete="false"
      @input="searchResource"
      @focus="showPopup"
    />
    <div v-if="displayPopup" class="popover">
      <div class="popover__title">
        {{ title }}
        <Loader v-if="isLoading" width="15px" height="15px"></Loader>
        <div class="resource__remove" @click="hidePopup">
          <span class="icon__close"> </span>
        </div>
      </div>
      <ul class="popover__list">
        <li
          v-for="resource in searchResults"
          :key="resource.id"
          class="popover__item"
          @click="selectResource(resource)"
        >
          {{ resource.display_name || resource.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Loader from "components/loader.vue";
import ResourceRepository from "shared/resource_repository";
import { debounce } from "lodash";
import { mapGetters, mapMutations } from "vuex";

/**
 * ResourceInput provides a SearchSelect style input for resources, useful for
 * associating entities with each other, e.g. CounterAssociates with
 * PieCounters.
 *
 * Emits an 'input' event with the selected value when a selection is made.
 *
 * @displayName ResourceIdInput
 */
export default {
  components: {
    Loader,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    resourceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchTerm:
        this.$store.getters.getResourceById(this.resourceType, this.value)
          ?.name || "",
      displayPopup: false,
      /** @type {boolean} */ isLoading: false,
      searchResults: [],
      repository: new ResourceRepository(this.resourceType),
    };
  },
  computed: {
    /** @return {string} */
    title() {
      return this.resourceTypeTitle(this.resourceType);
    },
    ...mapGetters(["resourceTypeTitle"]),
  },
  methods: {
    searchResource: debounce(
      async function () {
        this.isLoading = true;
        this.searchResults = await this.repository.search(this.searchTerm);
        // Adding the resources ensure that parent components will be able to
        // display the found resources once selected without additional
        // querying.
        this.addResources({
          type: this.resourceType,
          resources: this.searchResults,
        });
        this.isLoading = false;
      },
      200,
      { trailing: true },
    ),
    selectResource(resource) {
      this.$emit("input", resource.id);
      this.hidePopup();
    },
    showPopup() {
      this.displayPopup = true;
      this.searchResource();
    },
    hidePopup() {
      this.displayPopup = false;
    },
    ...mapMutations(["addResources"]),
  },
};
</script>
<style scoped>
.resource_list {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.resource {
  background-color: #dfe3e8;
  color: #454f5b;
  display: inline-flex;
  word-wrap: break-word;
  max-width: 100%;
  min-height: 24px;
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.resource__label {
  flex: 0 1 auto;
  min-width: 0;
  width: 100%;
  margin-right: 5px;
  display: block;
}
.resource__remove {
  flex-shrink: 0;
  display: block;
  cursor: pointer;
  float: right;
}
</style>
