import $ from "jquery";

// DEPRECATED: Use fetch_decorators instead
window.pie = window.pie || {};
window.pie.call = function (path, value, method) {
  return $.ajax(path, {
    data: value,
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    dataType: "json",
    method: method,
  });
};
