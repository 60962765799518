/*
 * More config info: https://www.npmjs.com/package/intl-tel-input
 */
import intlTelInput from "intl-tel-input";
import utils from "intl-tel-input/build/js/utils";

/*
 * Initialize the telephone field using the intl-tel-input library.
 * This library adds functionality for international phone number validation and
 * structure.
 *
 * You will have to use Javascript to submit your form. If you want to use
 * standard html form submission then you will have to use the "hiddenInput"
 * setting that is provided by the library. You may run into validation issues
 * using this method because the library is using the 'utils' script to do
 * number structure validation.
 *
 * The should be validated before the form is submitted, you can do this like:
 *
 * const intlTel = initInternationalTelField(smsFieldId)
 * intlTel.isValidNumber()
 *
 * @input fieldId: the ID of the input field
 */
export function initInternationalTelField(fieldId) {
  const input = document.getElementById(fieldId);

  return intlTelInput(input, {
    nationalMode: true,
    customContainer: `tel-${fieldId} ${input.dataset.intlTelInputClasses}`,
    preferredCountries: ["us", "jp", "cn"],
    utilsScript: utils,
  });
}
