<template>
  <!-- eslint-disable vue/require-v-for-key TODO -->
  <div
    id="payout-tiers"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Payout Tiers</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <Flash />
              <table class="table">
                <thead>
                  <tr>
                    <th v-for="detail in tierDetails" scope="col">
                      {{ detail.col_header }}
                    </th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="payoutTier in sortedPayoutTiers">
                    <td v-for="detail in tierDetails">
                      <input
                        v-model="payoutTier[detail.attribute]"
                        class="form-control"
                        type="number"
                        :name="detail.model"
                        :data-id="payoutTier.id"
                        @input="updatePayoutTier"
                      />
                    </td>
                    <td>
                      <button
                        class="btn btn-sm btn-primary"
                        data-confirm="Are you sure you want to delete this?"
                        @click="deleteTier(payoutTier)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="form">
                <div class="form-row">
                  <div v-for="detail in tierDetails" class="col">
                    <input
                      class="form-control"
                      :placeholder="detail.placeholder"
                      type="number"
                      :name="detail.model"
                      @input="updateNewPayoutTier"
                    />
                  </div>
                  <div class="col">
                    <button class="btn btn-primary" @click="addTier()">
                      Add Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <Loader
            v-if="$store.getters.isLoading()"
            :width="'25px'"
            :height="'25px'"
          ></Loader>
          <button class="btn btn-primary" @click="updateTiers()">Save</button>
          <button class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "./loader.vue";
import Flash from "./flash_message.vue";
import { sortBy, find } from "lodash";

export default {
  components: {
    Loader,
    Flash,
  },
  props: ["payoutTiers", "propertyId"],
  data() {
    return {
      newTier: {
        min_vat_total: "",
        max_vat_total: "",
        reserved_percentage: "",
        revenue_share_percentage: "",
      },
      updatedTiers: [],
    };
  },
  computed: {
    tierDetails() {
      return [
        {
          col_header: "Min VAT",
          attribute: "min_vat_total",
          placeholder: "Min Vat Total Ex: 1,000.01",
          model: "min_vat_total",
        },
        {
          col_header: "Max VAT",
          attribute: "max_vat_total",
          placeholder: "Max Vat Total Ex: 2,000",
          model: "max_vat_total",
        },
        {
          col_header: "% Reserved",
          attribute: "reserved_percentage",
          placeholder: "Reserved %: 17.5",
          model: "reserved_percentage",
        },
        {
          col_header: "% Revenue Share",
          attribute: "revenue_share_percentage",
          placeholder: "25% Revenue Share",
          model: "revenue_share_percentage",
        },
      ];
    },
    sortedPayoutTiers() {
      return sortBy(this.payoutTiers, function (tier) {
        return tier.min_vat_total_subunit;
      });
    },
  },
  methods: {
    setTierAttribute(tier, attribute, value) {
      tier[attribute] = value;
      return tier;
    },
    updateNewPayoutTier(event) {
      this.setTierAttribute(
        this.newTier,
        event.target.name,
        event.target.value,
      );
    },
    updatePayoutTier(event) {
      const id = event.target.dataset.id;
      let tier = find(this.updatedTiers, { id: id });
      if (tier) {
        this.setTierAttribute(tier, event.target.name, event.target.value);
      } else {
        tier = { id: id };
        this.setTierAttribute(tier, event.target.name, event.target.value);
        this.updatedTiers.push(tier);
      }
    },
    addTier() {
      this.$store.dispatch("resourceAssignmentSingular", {
        id: this.propertyId,
        singularResource: "property",
        resource: "properties",
        endpoint: `properties/${this.propertyId}/add_payout_tier`,
        data: {
          payout_tier: this.newTier,
        },
      });
    },
    deleteTier(tier) {
      this.$store.dispatch("deletePayoutTier", {
        id: this.propertyId,
        payout_tier_id: tier.id,
        singularResource: "property",
        resource: "properties",
      });
    },
    updateTiers() {
      this.$store.dispatch("updateTiers", {
        id: this.propertyId,
        singularResource: "property",
        resource: "properties",
        data: { payout_tiers: this.updatedTiers },
      });
    },
  },
};
</script>
