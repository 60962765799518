/**
 * This module is used to initialize client side i18n support for Vue.
 * See line_items_app.vue and views/line_items/show.html.erb for an example
 * of how it's used.
 */
import Vue from "vue/dist/vue.esm.js";
import VueI18n from "vue-i18n";
import I18n from "i18n-js";

/**
 * @param {HTMLElement} element An element with the data-current-locale
 *   attribute set.
 * @return {VueI18n}
 */
export const initVueI18n = (_element) => {
  Vue.use(VueI18n);
  return new VueI18n({
    locale: I18n.locale,
    messages: I18n.translations,
  });
};
