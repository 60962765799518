import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { Controller } from "@hotwired/stimulus";
import { checkResponseOk } from "shared/fetch_decorators";

export default class extends Controller {
  static targets = ["credentialField", "errorContainer"];

  initialize() {
    // For test mocking
    this.webauthnCreate = create;
  }

  async submit() {
    const form = this.element;
    if (!form.reportValidity()) {
      return;
    }
    const data = new FormData(form);
    // Remove the fields from the form not used by the new_challenge endpoint to
    // avoid spurious un-permitted parameters errors.
    data.delete("_method");
    data.delete("user[passkey_credential]");
    data.delete("user[country_id]");

    try {
      const challengeResponse = await fetch("/users/sign_up/new_challenge", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      });

      await checkResponseOk(
        challengeResponse,
        "Please try again or try a different email address",
      );

      const challengeJSON = await challengeResponse.json();
      const credentialCreationOptions = parseCreationOptionsFromJSON({
        publicKey: challengeJSON,
      });
      const credentialCreationResponse = await this.webauthnCreate(
        credentialCreationOptions,
      );

      this.credentialFieldTarget.value = JSON.stringify(
        credentialCreationResponse,
      );
      form.requestSubmit();
    } catch (error) {
      // This happens if the user cancels the operation or it times out. There's
      // nothing to do except let them click the button again unless we want to
      // show some kind of message in the UI.
      console.log(error);
      if (error.name === "NotAllowedError") {
        this.errorContainerTarget.innerHTML =
          "Passkey creation timed out or was canceled. Try again.";
        this.errorContainerTarget.classList.remove("d-none");
      } else {
        this.errorContainerTarget.innerText = error;
        this.errorContainerTarget.classList.remove("d-none");
      }
    }
  }
}
