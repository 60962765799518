import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm.js";

import OrdersApp from "admin/orders/app.vue";
import store from "admin/orders/store/store.js";

export default class extends Controller {
  static values = {
    filters: Object,
  };

  connect() {
    new Vue({
      el: this.element,
      store,
      components: { OrdersApp },
      data: {
        filters: this.filtersValue,
      },
      template: '<OrdersApp :filters="filters" />',
    });
  }
}
