import Vue from "vue/dist/vue.esm.js";
import VueRouter from "vue-router";
import Vuex from "vuex";
import { Controller } from "@hotwired/stimulus";

import createMerchantStore from "admin/merchants/store/store.js";
import App from "admin/merchant_app.vue";
import PieCountersShow from "admin/pie_counters/show.vue";
import MerchantsIndex from "admin/merchants/index.vue";
import MerchantsShow from "admin/merchants/show.vue";
import PropertiesShow from "admin/properties/show.vue";
import { initVueI18n } from "shared/vue_i18n.js";

export default class extends Controller {
  // TODO(alan): This is a hack to use Stimulus instead of extra pack files
  connect() {
    Vue.use(Vuex);
    Vue.use(VueRouter);

    const element = document.getElementById("merchants_app");
    const pageData = {
      posSystems: JSON.parse(element.getAttribute("data-pos_systems")),
      issueCounts: JSON.parse(element.getAttribute("data-issue_counts")),
    };

    const fourOhFour = {
      template:
        "<div>Could not find the page you are looking for. Check the URL</div>",
    };
    const routes = [
      { path: "/", component: MerchantsIndex },
      {
        name: "merchants_show",
        path: "/merchants/:id/show",
        component: MerchantsShow,
      },
      {
        name: "merchants_new",
        path: "/merchants/new",
        component: MerchantsShow,
      },
      {
        name: "properties_show",
        path: "/properties/:id/show",
        component: PropertiesShow,
      },
      {
        name: "properties_new",
        path: "/properties/new",
        component: PropertiesShow,
      },
      {
        name: "pie_counters_new",
        path: "/pie_counters/new",
        component: PieCountersShow,
      },
      {
        name: "pie_counters_show",
        path: "/pie_counters/:id/show",
        component: PieCountersShow,
      },
      { path: "*", component: fourOhFour },
    ];

    const router = new VueRouter({
      routes,
    });

    router.beforeEach((to, from, next) => {
      const re = /^\/admin\/merchants/;
      if (re.test(window.location.pathname)) next();
      else return;
    });

    const store = createMerchantStore(Vue, router, pageData);

    new Vue({
      el: "#merchants_app",
      store,
      router,
      components: { App },
      template: "<App/>",
      i18n: initVueI18n(element),
    });
  }
}
